import type { ReactNode } from "react";
import { FormGroup } from "@mui/material";
import FilterIcon from "@mui/icons-material/FilterList";
import { useAppSelector } from "../../../../redux/hooks";
import type { UiFilter, UiFilterGroup } from "../../../../redux/uiSlice";
import {
	initialUiFilterState,
	subSelectFilter,
	selectFilter,
} from "../../../../redux/uiSlice";
import { objectEntries } from "../../../../util/object";
import ToolbarMenu from "../../../LiteModelView/Toolbar/ToolbarMenu";
import "./FilterMenu.scss";

function FilterMenu<Group extends UiFilterGroup>({
	children,
	filterGroup,
	ignoreFilterKey = ["query"],
}: {
	children: ReactNode;
	filterGroup: Group;
	ignoreFilterKey?: (keyof UiFilter<Group>)[];
}): JSX.Element {
	const filter = useAppSelector(selectFilter(filterGroup));
	const initialFilter = subSelectFilter(filterGroup)(initialUiFilterState);
	const isActive = objectEntries(filter).some(
		([key, value]) =>
			!ignoreFilterKey.includes(key) && initialFilter[key] !== value,
	);

	return (
		<ToolbarMenu
			label="Filter"
			icon={FilterIcon}
			isActive={isActive}
			buttonProps={{ [String("data-testid")]: "filter-menu-button" }}
		>
			<FormGroup className="filter-menu__menu-content">{children}</FormGroup>
		</ToolbarMenu>
	);
}

export default FilterMenu;
