import { TableBody } from "@mui/material";
import { Link } from "react-router-dom";
import { encodeXPath } from "../../../../util/url";
import DatatypeDetailsTable from "./DatatypeDetailsTable";
import DatatypeNoInfo from "./DatatypeNoInfo";
import DatatypeTableCell from "./DatatypeTableCell";
import DatatypeTableHeader from "./DatatypeTableHeader";
import DatatypeTableRow from "./DatatypeTableRow";
import useCheckedActiveNode from "./useCheckedActiveNode";
import DynamicInfo from "../../../ui/DynamicInfo";
import { useAppSelector } from "../../../../redux/hooks";
import { selectQName } from "../../../../redux/treeSlice";
import { useStateSelector } from "../../../EditorState";
import { selectStandard } from "../../../EditorState/selectors";

export default function DatatypeStandardDetails(): JSX.Element {
	const activeNode = useCheckedActiveNode(DatatypeStandardDetails.name);
	const standard = useStateSelector(selectStandard());
	const qname = useAppSelector(selectQName(standard, activeNode));
	const linkQName = encodeXPath(qname || "");
	const standardLink = `/profilierung/datentypen/${linkQName}/standard`;

	return (
		<DatatypeDetailsTable
			title="Standarddatentyp"
			aria-label="Informationen zum Datentyp im Standard"
		>
			<DatatypeTableHeader />
			<TableBody>
				<DatatypeTableRow>
					<DatatypeTableCell variant="name">
						<Link to={standardLink} data-testid="standard-type-link">
							{activeNode.name}
						</Link>
						<DynamicInfo infoKey="datatypeStandard" inline="end" />
					</DatatypeTableCell>
					<DatatypeTableCell variant="description">
						{activeNode.beschreibung || (
							<DatatypeNoInfo>Keine Beschreibung vorhanden</DatatypeNoInfo>
						)}
					</DatatypeTableCell>
				</DatatypeTableRow>
			</TableBody>
		</DatatypeDetailsTable>
	);
}
