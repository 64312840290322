import { useMemo } from "react";
import { selectNode } from "../../redux/treeSlice";
import { useAppSelector } from "../../redux/hooks";
import type { RequestError, RequestStatus } from "../Api";
import { useTreeStructure } from "../TreeStructureProvider";
import {
	LiteNodeType,
	LiteIdSchema,
} from "../AppActor/actors/modellierungModel/schemas";
import type {
	LiteNode,
	LiteId,
} from "../AppActor/actors/modellierungModel/schemas";
import type { Nullish } from "../../util/types";

export interface TreeInfo {
	tree: LiteNode;
	isFallback: boolean;
	error: RequestError | null;
	status: RequestStatus;
}

const EMPTY_ROOT_NODE: LiteNode = {
	id: LiteIdSchema.parse(""),
	name: "__rootName__",
	liteType: LiteNodeType.Model,
	children: [],
	kennung: "",
	konfiguration: {},
	metadatenStandard: {
		kennung: "",
		nameKurz: "",
		nameLang: "",
		nameTechnisch: "",
	},
	metadatenVersion: {
		version: "",
	},
	parent: null,
};

function useTree(standard: Nullish<string>, rootId: Nullish<LiteId>): TreeInfo {
	const { treeError: error, treeStatus: status } = useTreeStructure();

	const selectedNode = useAppSelector(selectNode(standard, rootId));

	return useMemo(() => {
		const tree = selectedNode || EMPTY_ROOT_NODE;
		return {
			tree,
			isFallback: tree === EMPTY_ROOT_NODE,
			error,
			status,
		};
	}, [error, selectedNode, status]);
}

export default useTree;
