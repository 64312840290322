import type { LiteId } from "../../AppActor/actors/modellierungModel/schemas";
import type { RestrictionId } from "../../EditorState/types";
import type {
	AutoFixValue,
	FixerFn,
	ValidationTargetField,
} from "../../Validation/types";
import ValidationFormatter from "../../Validation/ValidationFormatter";
import useProfilingValidationResult from "./useProfilingValidationResult";

function ProfilingValidationDisplay<
	TargetField extends ValidationTargetField = ValidationTargetField,
>({
	nodeId,
	restrictionId,
	targetField,
	value,
	onFix,
}: {
	nodeId?: LiteId;
	restrictionId?: RestrictionId;
	targetField?: TargetField;
	value?: AutoFixValue<TargetField>;
	onFix?: (fixer: FixerFn<TargetField>) => void;
}): JSX.Element | null {
	const results = useProfilingValidationResult({
		nodeId,
		restrictionId,
		targetField,
	});
	return results.length > 0 ? (
		<ValidationFormatter<TargetField>
			results={results}
			value={value}
			onFix={onFix}
		/>
	) : null;
}

export default ProfilingValidationDisplay;
