import type {
	ProjektType,
	QName,
} from "../components/AppActor/actors/modellierungModel/schemas";
import type { PropertyValues } from "../components/EditorState/types";
import type { CodeListIdentifier } from "./InfoNodeValues";
import type StatusFassung from "./StatusFassung";

export interface SavedProfileMetadata {
	beschreibung: string;
	beschreibungVersion?: string;
	datumErstellung?: string;
	datumLetzteBearbeitung?: string;
	herausgeber: string;
	kennung: string;
	nameKurz: string;
	nameLang: string;
	nameTechnisch?: string;
	standard: string;
	statusFassung: StatusFassung;
	version: string;
}

export interface SavedMessageProfileRecursion {
	limit?: number | string;
	beschreibung?: string;
}

export enum CodeListType {
	BlackList = "BLACKLIST",
	WhiteList = "WHITELIST",
}
export interface SavedCodeList extends CodeListIdentifier {
	typ?: CodeListType;
	werte?: string[];
}

export interface SavedPropertyProfile {
	definition: PropertyValues;
	value?: string;
}

export interface SavedMessageProfileConfiguration {
	fixedWert?: string;
	defaultWert?: string;
	beispielWert?: string;
	beispielWertNachricht?: string[];
	codeliste?: SavedCodeList;
}

export type SavedProfileType = {
	beschreibung?: string;
	umsetzungshinweis?: string;
	lowerBound?: string;
	upperBound?: string;
	errorMessageRecursion?: string;
	istRekursionsStart?: boolean;
	datentyp?: string;
	konfiguration?: SavedMessageProfileConfiguration;
	datentypReferenz?: { name: string; datentyp: QName };
	eigenschaften?: SavedPropertyProfile[];
	rekursion?: SavedMessageProfileRecursion;
};

export interface SavedConfiguration {
	dokumentation?: {
		nurProfilierteElemente?: boolean;
		nurVorhandeneElemente?: boolean;
		nachrichten?: QName[];
	};
	generierung?: {
		optionaleElementeGenerieren: boolean;
		rekursionsTiefe: number;
	};
	profilierung?: {
		eigenschaften: PropertyValues[];
	};
}

export type SavedProfile = {
	syntaxPfad: string;
} & SavedProfileType;

export interface SavedProfileDocumentation {
	name: string;
	inhalt: string;
	generiert: boolean;
	index?: number;
	versteckt?: boolean;
}

export interface SavedDatatypeProfile {
	id: string;
	name: string;
	datentyp: string;
	beschreibung?: string;
	umsetzungshinweis?: string;
	profile: SavedProfile[];
}

interface SavedProfileData {
	typ: ProjektType.Profilierung;
	dokumentation: SavedProfileDocumentation[];
	metadaten: SavedProfileMetadata;
	profile: SavedProfile[];
	datentypen: SavedDatatypeProfile[];
	konfiguration: SavedConfiguration;
	// TODO: Feedback, version needs to be defined
	profilierteVersionStandard: { kennung: string; version?: string };
}

export default SavedProfileData;
