import { useNavigate } from "react-router-dom";
import { useEventHandler } from "../../../hooks";
import { createIdCounter } from "../../../util/misc";

const createReloadId = createIdCounter();

export default function useReloadProfiling(): () => void {
	const navigate = useNavigate();
	const reload = useEventHandler(() => {
		// Add a query parameter that changes whenever a new profile is created.
		// In `AppView`, the `Profiling` component is keyed to this query param
		// so it re-renders when the param changes. This way we can make sure,
		// that `Profiling` always uses the new data
		const reloadId = createReloadId();
		navigate(`/profilierung/projekt/?reload=${reloadId}`);
	});
	return reload;
}
