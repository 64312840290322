import { useSendStoreEvent } from "../../../../../AppActor/EventStore/hooks";
import { EditableFieldNameSchema } from "../../../../../AppActor/actors/modellierungModel/types";
import EditFormFieldLite from "../../../../../EditFormFieldLite";
import type { EditRendererProps } from "../../types";

export default function CommonMetadataEdit({
	activeNode,
	projektId,
	isReadOnly,
}: EditRendererProps): JSX.Element {
	const sendStoreEvent = useSendStoreEvent();

	const handleWrite = (name: string, value: string) => {
		const nodeId = activeNode.id;
		sendStoreEvent({
			type: "MODELLIERUNG.MODELL.APPLY",
			payload: {
				projektId,
				patch: {
					type: "changeDetailsValue",
					payload: { name: EditableFieldNameSchema.parse(name), value, nodeId },
				},
			},
		});
	};

	return (
		<>
			<EditFormFieldLite
				definition={{
					readOnly: isReadOnly,
					name: "name",
					label: "Name",
				}}
				value={activeNode.name || ""}
				onBlur={handleWrite}
				inputProps={{
					"data-testid": "details-field",
					"data-field-name": "name",
				}}
			/>
			<EditFormFieldLite
				definition={{
					readOnly: isReadOnly,
					name: "beschreibung",
					label: "Beschreibung",
					type: "textarea",
				}}
				value={activeNode.beschreibung || ""}
				onBlur={handleWrite}
				inputProps={{
					"data-testid": "details-field",
					"data-field-name": "beschreibung",
				}}
			/>
			<EditFormFieldLite
				definition={{
					readOnly: isReadOnly,
					name: "umsetzungshinweis",
					label: "Umsetzungshinweis",
					type: "textarea",
				}}
				value={activeNode.umsetzungshinweis || ""}
				onBlur={handleWrite}
				inputProps={{
					"data-testid": "details-field",
					"data-field-name": "umsetzungshinweis",
				}}
			/>
		</>
	);
}
