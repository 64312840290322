import { /* useCallback, */ useEffect, /* useRef, */ useState } from "react";
import type { GridColDef /* , GridRowModel */ } from "@mui/x-data-grid";
import { DataGrid, deDE } from "@mui/x-data-grid";
import { /* IconButton, Menu, MenuItem, */ Tooltip } from "@mui/material";
import AccountTreeRoundedIcon from "@mui/icons-material/AccountTreeRounded";
import DeviceHubIcon from "@mui/icons-material/DeviceHub";
import BorderTopIcon from "@mui/icons-material/BorderTop";
// import MoreVertIcon from "@mui/icons-material/MoreVert";
import { TableContainer } from "../ui";
import QuickFilterToolbar from "../ui/mui/QuickFilterToolbar";
import RequestErrorNotification from "../RequestErrorNotification";
import useStartView from "./useStartView";
import { RequestStatus } from "../Api";
import AdocRenderer from "../AdocRenderer";
// import UploadProject from "./UploadProject";
import HomeSubMenu from "../Menu/HomeSubMenu";
import type { ApiProjekt } from "../AppActor/actors/modellierungModel/schemas";
import { ProjektType } from "../AppActor/actors/modellierungModel/schemas";
import {
	useEventStoreRef,
	// useSendStoreEvent,
} from "../AppActor/EventStore/hooks";
// import { useProjectList } from "../AppActor/actors/projects/hooks";
// import {
// 	useDeleteProjectMutation,
// 	useDuplicateProjectMutation,
// 	useGetProjekteQuery,
// } from "../../redux/apiSlice";
// import ConfirmDialog from "../ConfirmDialog";
import "./StartView.scss";

const data = "= Suite Startseite \n\n "; /*+*/
// " Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non risus. Suspendisse lectus tortor," +
// " dignissim sit amet, adipiscing nec, ultricies sed, dolor. Cras elementum ultrices diam. Maecenas ligula massa, varius a, semper congue, euismod non, mi.";

const renderTypIcon = (typ: ProjektType) => {
	switch (typ) {
		case ProjektType.Modellierung:
			return <AccountTreeRoundedIcon />;
		case ProjektType.Genericode:
			return <BorderTopIcon />;
		case ProjektType.Profilierung:
			return <DeviceHubIcon />;
		case ProjektType.Standard:
			return <DeviceHubIcon />;
		default:
			return typ;
	}
};

// const RowMenu = ({ row }: { row: GridRowModel<ApiProjekt> }) => {
// 	const iconButton = useRef<HTMLButtonElement>(null);
// 	const [menuOpen, setMenuOpen] = useState(false);
// 	const [duplicateProject, { isLoading: isDuplicating }] =
// 		useDuplicateProjectMutation();
// 	const [deleteProject, { isLoading: isDeleting }] = useDeleteProjectMutation();
// 	const { refetch } = useGetProjekteQuery();
// 	const isStandard = row.typ === ProjektType.Standard;
// 	const send = useSendStoreEvent();
// 	const projects = useProjectList();
// 	const [confirmOpen, setConfirmOpen] = useState(false);

// 	const handleDuplicate = useCallback(async () => {
// 		await duplicateProject({
// 			id: row.id,
// 			nameKurz: row.nameKurz,
// 			version: row.version,
// 			kennung: row.kennung,
// 			typ: ProjektType.Modellierung,
// 		});
// 		await refetch();
// 	}, [duplicateProject, row, refetch]);

// 	const promptDeletion = useCallback(() => {
// 		setConfirmOpen(true);
// 		setMenuOpen(false);
// 	}, [setConfirmOpen]);

// 	const handleDelete = useCallback(async () => {
// 		const projectTab = projects.find((project) => project.data.dbId === row.id);

// 		if (projectTab) {
// 			send({
// 				type: "PROJECT.CLOSE",
// 				payload: { projektId: projectTab.projektId },
// 			});
// 		}

// 		await deleteProject(row.id);
// 		await refetch();
// 	}, [deleteProject, row, refetch, projects, send]);

// 	const closeMenu = useCallback(() => {
// 		setMenuOpen(false);
// 	}, [setMenuOpen]);

// 	return (
// 		<div data-role="row-context-menu-button">
// 			<IconButton ref={iconButton} onClick={() => setMenuOpen(!menuOpen)}>
// 				<MoreVertIcon />
// 			</IconButton>
// 			<Menu anchorEl={iconButton.current} open={menuOpen} onClose={closeMenu}>
// 				<MenuItem onClick={handleDuplicate} disabled={isDuplicating}>
// 					Als neues Projekt kopieren
// 				</MenuItem>
// 				<MenuItem
// 					style={{ backgroundColor: "var(--red-0)", color: "var(--red-4)" }}
// 					onClick={promptDeletion}
// 					disabled={isDeleting || isStandard}
// 				>
// 					Projekt löschen
// 				</MenuItem>
// 			</Menu>
// 			<ConfirmDialog
// 				isOpen={confirmOpen}
// 				title={`Sind Sie sicher, dass Sie das Projekt "${row.nameKurz}" löschen möchten?`}
// 				description="Dieser Vorgang kann nicht rückgängig gemacht werden."
// 				onAccept={handleDelete}
// 				onDismiss={() => setConfirmOpen(false)}
// 			/>
// 		</div>
// 	);
// };

const columns: GridColDef<ApiProjekt>[] = [
	{
		field: "typ",
		headerName: "Typ",
		renderCell: (field) => {
			return (
				<Tooltip title={field.value}>{renderTypIcon(field.value)}</Tooltip>
			);
		},
	},
	{
		field: "nameKurz",
		headerName: "Standard",
		flex: 1,
	},
	{
		field: "version",
		headerName: "Version",
		width: 500,
	},
	// {
	// 	field: "menu",
	// 	headerName: "Menü",
	// 	width: 150,
	// 	editable: true,
	// 	renderCell: ({ row }) => {
	// 		return <RowMenu row={row} />;
	// 	},
	// },
];

export default function StartView(): JSX.Element {
	const eventStore = useEventStoreRef();
	const {
		rows,
		requestError,
		status,
		pageSize,
		handlePageSizeChange,
		handleSelectProject,
	} = useStartView();

	const [isLoading, setLoading] = useState(false);

	useEffect(() => {
		const sub = eventStore.subscribe((snapshot) => {
			const event = snapshot.context.eventLog.last(null)?.event;
			if (
				event &&
				(event.type === "PROJECT.OPEN_EXISTING.SUCCESS" ||
					event.type === "PROJECT.OPEN_EXISTING.FAILURE")
			) {
				setLoading(false);
			}
		});
		return sub.unsubscribe;
	}, [eventStore]);

	return (
		<>
			<HomeSubMenu />
			<main id="main" className="start-view">
				<RequestErrorNotification
					id="request-all-standards-error"
					error={requestError}
					status={status}
				>
					Beim Abrufen der Liste von Standards ist ein Fehler aufgetreten. Bitte
					versuchen Sie es erneut.
					<br />
					<br />
					Sollte der Fehler weiterhin auftreten wenden Sie sich an die
					Administrator:innen.
				</RequestErrorNotification>
				<div style={{ margin: "0  auto 100px" }}>
					<AdocRenderer className="start-view__adoc" markup={data} />
				</div>
				{/* <UploadProject /> */}
				<TableContainer className="start-view__table">
					<DataGrid
						loading={status === RequestStatus.Loading || isLoading}
						columns={columns}
						rows={rows}
						rowsPerPageOptions={[10, 25, 50]}
						pageSize={pageSize}
						onPageSizeChange={handlePageSizeChange}
						pagination
						localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
						components={{ Toolbar: QuickFilterToolbar }}
						onRowClick={(row, event) => {
							const menuWrappers = document.querySelectorAll(
								"[data-role=row-context-menu-button]",
							);
							const isMenuButton = Array.from(menuWrappers).some(
								(menuWrapper) =>
									menuWrapper.contains(event.nativeEvent.target as Node),
							);

							if (!isMenuButton) {
								const project = rows.find((r) => r.id === row.id);

								if (project) {
									setLoading(true);
									handleSelectProject(project);
								}
							}
						}}
						disableColumnFilter
						disableColumnSelector
						disableDensitySelector
						sx={{
							// disable cell selection style
							".MuiDataGrid-cell:focus": {
								outline: "none",
							},
							// pointer cursor on ALL rows
							"& .MuiDataGrid-row:hover": {
								cursor: "pointer",
							},
						}}
					/>
				</TableContainer>
			</main>
		</>
	);
}
