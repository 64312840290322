import type { MenuItemProps } from "@mui/material";
import { ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import type { ExtendProps } from "../../../util/types";

type AComponentMenutItemProps = ExtendProps<
	MenuItemProps<"a">,
	{
		uri: string;
		messageName?: string;
		title: string;
		children: React.ReactNode;
	}
>;

const AComponentMenutItem = ({
	uri,
	messageName,
	title,
	children,
	...props
}: AComponentMenutItemProps): JSX.Element => (
	<MenuItem component="a" href={uri} download={messageName} {...props}>
		<ListItemIcon>{children}</ListItemIcon>
		<ListItemText>{title}</ListItemText>
	</MenuItem>
);

export default AComponentMenutItem;
