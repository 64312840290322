import EditorNavBar from "../../../EditorNavBar";
import { useProjectBreadcrumb } from "../../../EditorNavBar/NavBarBreadcrumbs/breadCrumbs";
import NavBarBreadcrumbs from "../../../EditorNavBar/NavBarBreadcrumbs/NavBarBreadcrumbs";

const ProfilingHomeNavBar = (): JSX.Element => {
	const projectBreadcrumb = useProjectBreadcrumb();
	const breadcrumbs = [projectBreadcrumb];
	return (
		<EditorNavBar data-testid="profiling-home-navbar">
			<NavBarBreadcrumbs items={breadcrumbs} />
		</EditorNavBar>
	);
};

export default ProfilingHomeNavBar;
