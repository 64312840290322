import classNames from "classnames";
import type { DetailedHTMLProps, HTMLAttributes, ReactNode } from "react";
import { forwardRef } from "react";
import type { ExtendProps } from "../../util/types";
import "./InteractiveNode.scss";

type DivProps = DetailedHTMLProps<
	HTMLAttributes<HTMLDivElement>,
	HTMLDivElement
>;

export type InteractiveNodeProps = ExtendProps<
	DivProps,
	{
		as?: string;
		children?: ReactNode;
		className?: string;
		isActive?: boolean;
		isEmphasized?: boolean;
		isDisabled?: boolean;
	}
>;

const InteractiveNode = forwardRef<HTMLDivElement, InteractiveNodeProps>(
	function InteractiveNode(
		{
			as: is = "div",
			children,
			className,
			isActive,
			isEmphasized,
			isDisabled,
			...props
		},
		ref,
	) {
		const As = is as "div";
		return (
			<As
				ref={ref}
				data-is-disabled={isDisabled}
				data-is-active={isActive}
				data-is-emphasized={isEmphasized}
				// eslint-disable-next-line react/jsx-props-no-spreading
				{...props}
				className={classNames(
					"interactive-node",
					isActive && "interactive-node--is-active",
					isEmphasized && "interactive-node--is-emphasized",
					isDisabled && "interactive-node--is-disabled",
					className,
				)}
			>
				{children}
			</As>
		);
	},
);

export default InteractiveNode;
