import { useContext, useEffect, useMemo } from "react";
import classNames from "classnames";
import { useDescendant } from "../util/descendants";
import { TabContext, TabPanelDescendantsContext } from "./TabContexts";
import { callOrReturn } from "../../../util/func";
import useSyncedRef from "../../../hooks/useSyncedRef";
import type { TabPanelProps } from "./types";
import "./TabPanel.scss";

const TabPanel = ({
	className = "",
	activeClassName = "",
	children,
	onActivate,
	...props
}: TabPanelProps) => {
	const { tabs, panels, activeTab, orientation } = useContext(TabContext);
	const { id, setRef } = useDescendant(TabPanelDescendantsContext);
	const index = useMemo(
		() => panels.findIndex((panel) => panel.id === id),
		[id, panels],
	);
	const onActivateRef = useSyncedRef(onActivate);
	const tabId = tabs[index]?.id;
	const isActive = activeTab?.id === tabId;
	const tabIndex = isActive ? 0 : -1;

	useEffect(() => {
		if (isActive && onActivateRef.current) {
			onActivateRef.current();
		}
	}, [isActive, onActivateRef]);

	const renderProps = useMemo(() => ({ isActive }), [isActive]);
	return (
		<div
			className={classNames(
				"xui-tab-panel",
				`xui-tab-panel--orientation-${orientation}`,
				isActive && "xui-tab-panel--active",
				isActive && activeClassName,
				className,
			)}
			ref={setRef}
			id={id}
			role="tabpanel"
			aria-labelledby={tabId}
			tabIndex={tabIndex}
			data-xui-component="tabs"
			data-xui-element="tab-panel"
			{...props}
		>
			{callOrReturn(children, renderProps)}
		</div>
	);
};

export default TabPanel;
