import type { ActorRefFrom } from "xstate";
import { assertEvent, assign, setup } from "xstate";
import type { NavigateFunction } from "react-router-dom";
import type {
	ApiProjektList,
	ProjektDbId,
	ProjektId,
	RawLiteModel,
} from "../modellierungModel/schemas";
import { createUnimplementedCallbackHandler } from "../../../../util/machines";
import type ValueOf from "../../../../types/ValueOf";
import type {
	ValidationResult,
	ValidationTargetField,
} from "../modellierungModel/validation/validators/types";
import type { StoreEvent } from "../../EventStore/StoreEvent";

// eslint-disable-next-line no-use-before-define
export type EffectApiActorRef = ActorRefFrom<typeof effectApiMachine>;

export type EffectApiContext = {
	navigate: NavigateFunction;
	requestProjektList: () => Promise<ApiProjektList>;
	requestProjectById: (dbId: ProjektDbId) => Promise<RawLiteModel>;
	sendEventToWorker: (
		event: StoreEvent,
	) => Promise<ValidationResult<ValidationTargetField>[]>;
	initializeWorkerState: (
		projektId: ProjektId,
		projekt: RawLiteModel,
	) => Promise<void>;
};
type SingleEffectApiEvent<FnKey extends keyof EffectApiContext> = {
	type: "SET_FN";
	key: FnKey;
	fn: EffectApiContext[FnKey];
};
type EffectApiEvent = ValueOf<{
	[K in keyof EffectApiContext]: SingleEffectApiEvent<K>;
}>;

const unimplemented = (methodName: string) =>
	createUnimplementedCallbackHandler("effectApi", methodName);

const effectApiMachine = setup({
	types: {
		context: {} as EffectApiContext,
		events: {} as
			| EffectApiEvent
			| { type: "SET_API"; api: EffectApiContext }
			| { type: "SET_PARTIAL_API"; api: Partial<EffectApiContext> },
	},
	actions: {
		setFn: assign(({ event }) => {
			assertEvent(event, "SET_FN");
			return { [event.key]: event.fn };
		}),
		setApi: assign(({ event }) => {
			assertEvent(event, "SET_API");
			return event.api;
		}),
		setPartialApi: assign(({ event }) => {
			assertEvent(event, "SET_PARTIAL_API");
			return event.api;
		}),
	},
}).createMachine({
	id: "effectApi",
	context: {
		navigate: unimplemented("navigate"),
		requestProjektList: unimplemented("requestProjektList"),
		requestProjectById: unimplemented("requestProjectById"),
		sendEventToWorker: unimplemented("sendEventToWorker"),
		initializeWorkerState: unimplemented("initializeWorkerState"),
	},
	on: {
		SET_FN: { actions: "setFn" },
		SET_API: { actions: "setApi" },
		SET_PARTIAL_API: { actions: "setPartialApi" },
	},
});

export default effectApiMachine;
