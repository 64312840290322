import {
	TableContainer,
	Tooltip,
	IconButton,
	TableBody,
	Table,
	TableCell,
	TableHead,
	TableRow,
} from "@mui/material";
import { useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import type { ImmutableMap } from "@xoev/immutable-map";
import createImmutableMap from "@xoev/immutable-map";
import type {
	PropertyProfile,
	MessageProfileValues,
} from "../../../../../../EditorState/types";
import {
	subSelectPropertyById,
	subSelectSelectableProperties,
	subSelectUsedPropertySeq,
} from "../../../../../../EditorState/subSelectors";
import useSectionDependentProfile from "../../../../../../Profiling/useSectionDependentProfile";
import { useStateSelector } from "../../../../../../EditorState";
import { selectProperties } from "../../../../../../EditorState/selectors";
import PropertySelectEditRow, {
	PropertySelectMode,
} from "./PropertySelectEditRow";
import { Info } from "../../../../../../ui";
import DynamicInfo from "../../../../../../ui/DynamicInfo";
import type { LiteId } from "../../../../../../AppActor/actors/modellierungModel/schemas";
import "./PropertiesSelect.scss";

function deleteFromProfile(
	profile: ImmutableMap<Partial<MessageProfileValues>> | null | undefined,
	nameTechnisch: string,
) {
	return (profile || createImmutableMap()).deleteIn([
		"eigenschaften",
		nameTechnisch,
	]);
}

function addToProfile(
	profile: ImmutableMap<Partial<MessageProfileValues>> | null | undefined,
	propertyProfile: PropertyProfile,
) {
	const nextPropProfile = createImmutableMap(propertyProfile);
	return (profile || createImmutableMap()).setIn(
		["eigenschaften", propertyProfile.name],
		nextPropProfile,
	);
}

const PropertiesSelect = ({
	activePath,
	errorId,
}: {
	activePath: LiteId[];
	errorId: string;
}): JSX.Element => {
	const {
		profile,
		setProfile,
		isParentProfiledDatatype,
		hasParentZeroCardinality,
	} = useSectionDependentProfile(activePath);

	const properties = useStateSelector(selectProperties());

	const usedProperties = subSelectUsedPropertySeq(profile);
	const selectableProperties = subSelectSelectableProperties(
		profile,
		properties || [],
	);

	const [editingRowName, setEditingRowName] = useState<string | null>(null);

	const handleAddConfirm = (propProfile: PropertyProfile) => {
		const hasSelectedProperty = properties?.some(
			(prop) => prop.nameTechnisch === propProfile.name,
		);
		if (!hasSelectedProperty) return;
		const newProfile = addToProfile(profile, propProfile);
		setProfile(newProfile);
	};

	const createEditConfirmHandler =
		(nameTechnisch: string) => (propProfile: PropertyProfile) => {
			const newProfile = addToProfile(
				deleteFromProfile(profile, nameTechnisch),
				propProfile,
			);
			setProfile(newProfile);
			setEditingRowName(null);
		};

	const createEditHandler = (nameTechnisch: string) => () => {
		setEditingRowName(nameTechnisch);
	};

	const handleCancel = () => {
		setEditingRowName(null);
	};

	const createDeleteHandler = (nameTechnisch: string) => () => {
		const newProfile = deleteFromProfile(profile, nameTechnisch);
		setProfile(newProfile);
	};

	const isEditingDisabled =
		isParentProfiledDatatype || hasParentZeroCardinality;

	return (
		<>
			<h3 className="attributes-select__heading">Eigene Eigenschaften</h3>
			<TableContainer className="attributes-select">
				<Table aria-describedby={errorId}>
					<TableHead>
						<TableRow>
							<TableCell>Name</TableCell>
							<TableCell>Wert</TableCell>
							<TableCell>Aktion</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{usedProperties.count() === 0 && !selectableProperties?.length && (
							<TableRow data-testid="no-attributes-info">
								<TableCell colSpan={3} align="center">
									<span className="attributes-select__info-cell">
										<em className="attributes-select__no-attrs">
											Keine zusätzlichen Eigenschaften vorhanden
										</em>
										<DynamicInfo infoKey="propertiesSelect" />
									</span>
								</TableCell>
							</TableRow>
						)}
						{usedProperties.map((prop) => {
							const key = prop.get("name");
							const value = prop.get("value");
							const propertyValues = subSelectPropertyById(properties, key);
							const label = propertyValues?.name || key;
							const description = propertyValues?.beschreibung;
							if (propertyValues && editingRowName === key) {
								return (
									<PropertySelectEditRow
										key={key}
										initialState={prop.toJS()}
										propertyOptions={[propertyValues, ...selectableProperties]}
										mode={PropertySelectMode.Edit}
										onConfirm={createEditConfirmHandler(key)}
										onCancel={handleCancel}
										isEditingDisabled={isEditingDisabled}
										autoFocus
									/>
								);
							}
							return (
								<TableRow
									key={key}
									data-testid="attribute-row"
									data-attribute-id={key}
								>
									<TableCell data-testid="attribute-name">
										<span className="attributes-select__info-cell">
											{label}
											{description && <Info>{description}</Info>}
										</span>
									</TableCell>
									<TableCell data-testid="attribute-name">
										{value ?? ""}
									</TableCell>
									<TableCell>
										<Tooltip title="Eigenschaft bearbeiten">
											<IconButton
												data-testid="edit-attribute"
												onClick={createEditHandler(key)}
												disabled={isEditingDisabled}
											>
												<EditIcon />
											</IconButton>
										</Tooltip>
										<Tooltip title="Eigenschaft löschen">
											<IconButton
												data-testid="delete-attribute"
												onClick={createDeleteHandler(key)}
												disabled={isEditingDisabled}
											>
												<DeleteIcon />
											</IconButton>
										</Tooltip>
									</TableCell>
								</TableRow>
							);
						})}
						{!!selectableProperties?.length &&
							!editingRowName &&
							!isEditingDisabled && (
								<PropertySelectEditRow
									propertyOptions={selectableProperties}
									mode={PropertySelectMode.Create}
									onConfirm={handleAddConfirm}
									isEditingDisabled={isEditingDisabled}
								/>
							)}
					</TableBody>
				</Table>
			</TableContainer>
		</>
	);
};

export default PropertiesSelect;
