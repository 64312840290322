import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { getNextDescendantIndex, useDescendantList } from "../util/descendants";
import { SelectContext, SelectDescendentsContext } from "./SelectContext";
import { useConst } from "../../../hooks";
import { createHtmlId } from "../../../util/misc";
import PopoverMenuContext from "../PopoverMenu/PopoverMenuContext";
import type { SelectImplProps, SelectInternalChangeHandler } from "./types";
import "./SelectImpl.scss";

// We need `any` here because `unknown` would break type inference. `any`
// is safe here though, because we're only using it as a base type here, that
// is extended and not as an explicit type
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AnyVoidFn = (...args: any[]) => void;

function useMutatingCallback<Fn extends AnyVoidFn>(
	directCb: Fn,
	disabled: boolean,
): Fn {
	const cb = useCallback(
		(...args) => {
			if (!disabled) {
				directCb(...args);
			}
		},
		[directCb, disabled],
	);
	return cb as Fn;
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = () => {};

const SelectImpl = ({
	children,
	label,
	name,
	value: valueProp,
	onChange: directOnChange = noop,
	closeOnChange = true,
	disabled = false,
	isRequired,
}: SelectImplProps): JSX.Element => {
	const { Provider, items } = useDescendantList(SelectDescendentsContext);
	const { isOpen, setIsOpen, containerRef, isElementInMenu, buttonRef } =
		useContext(PopoverMenuContext);

	const idPrefix = useConst(createHtmlId);
	const labelId = useConst(createHtmlId);

	const [activeId, directSetActiveId] = useState<string | null>(null);
	const [valueState, directSetValueState] = useState<string>(valueProp || "");

	const setActiveId = useMutatingCallback(directSetActiveId, disabled);
	const setValueState = useMutatingCallback(directSetValueState, disabled);
	const onChange = useMutatingCallback(directOnChange, disabled);
	const isOnChangeProvided = directOnChange !== noop;

	const handleChange: SelectInternalChangeHandler = useCallback(
		(e, nextValue, reason) => {
			if (typeof onChange === "function" && isOnChangeProvided) {
				onChange(
					{
						value: nextValue,
						reason,
						name,
						// Fake a dom event here, so libraries like Formik can work with the
						// component out of the box
						target: { value: nextValue, name },
					},
					e,
				);
			} else {
				setValueState(nextValue);
			}
			if (closeOnChange) {
				setIsOpen(false);
			}
		},
		[
			onChange,
			isOnChangeProvided,
			closeOnChange,
			name,
			setValueState,
			setIsOpen,
		],
	);
	const value = valueProp !== undefined ? valueProp : valueState;

	const activeItem = useMemo(() => {
		const activeOption = items.find((item) => item.id === activeId);
		return activeOption;
	}, [activeId, items]);
	useEffect(() => {
		if (!isOpen) {
			setActiveId(null);
		}
	}, [isOpen, setActiveId]);

	const defaultItem = useMemo(() => {
		const defaultOption = items.find((item) => item.data?.default);
		return (
			defaultOption || {
				id: `${idPrefix}--__default__`,
				ref: null,
			}
		);
	}, [idPrefix, items]);

	const selectedItem = useMemo(() => {
		const selectedOption = items.find((item) => item.data?.value === value);
		return selectedOption || defaultItem;
	}, [value, items, defaultItem]);

	// Reduce cognitive complexity, when the function needs to be modified next
	// eslint-disable-next-line sonarjs/cognitive-complexity
	useEffect(() => {
		const moveSelection = (dir: number) => {
			const nextIndex = getNextDescendantIndex(
				items,
				activeItem?.id || null,
				dir,
			);
			const nextItem = items[nextIndex];
			const nextId = nextItem?.id || null;
			setIsOpen(true);
			setActiveId(nextId);
		};

		const dirs: { [k: string]: number | undefined } = {
			ArrowDown: 1,
			ArrowUp: -1,
		};

		const keyHandler = (e: KeyboardEvent) => {
			if (!isElementInMenu(e.target as Node)) return;

			const indexMap: { [k: string]: number | undefined } = {
				Home: 0,
				End: items.length - 1,
			};
			if (isOpen && e.key in indexMap) {
				e.preventDefault();
				const nextIndex = indexMap[e.key] as number;
				const nextId = items[nextIndex]?.id || null;
				setActiveId(nextId);
			}

			const dir = dirs[e.key];
			if (dir) {
				e.preventDefault();
				moveSelection(dir);
			}

			if (
				isOpen &&
				["Enter", " "].includes(e?.key) &&
				activeItem?.data?.value !== undefined
			) {
				e.preventDefault();
				handleChange(e, activeItem.data.value, "keyPress");
				if (closeOnChange) {
					buttonRef.current?.focus();
				}
			}
		};

		document.addEventListener("keydown", keyHandler);

		return () => {
			document.removeEventListener("keydown", keyHandler);
		};
	}, [
		activeItem,
		closeOnChange,
		containerRef,
		isOpen,
		items,
		setActiveId,
		setIsOpen,
		isElementInMenu,
		buttonRef,
		handleChange,
	]);

	const ctx = useMemo(
		() => ({
			labelId,
			items,
			isOpen,
			setIsOpen,
			activeItem,
			setActiveId,
			selectedItem,
			defaultItem,
			handleChange,
			value,
			idPrefix,
			disabled,
			isRequired: !!isRequired,
		}),
		[
			activeItem,
			defaultItem,
			disabled,
			handleChange,
			idPrefix,
			isOpen,
			isRequired,
			items,
			labelId,
			selectedItem,
			setActiveId,
			setIsOpen,
			value,
		],
	);

	return (
		<Provider>
			<SelectContext.Provider value={ctx}>
				<div id={labelId} className="xui-select-impl__label">
					{label}
				</div>
				{children}
			</SelectContext.Provider>
		</Provider>
	);
};

export default SelectImpl;
