import { Autocomplete, TextField } from "@mui/material";
import EditFormFieldLabel from "../../../../../EditFormFieldLite/EditFormFieldLabel";
import type { LiteDatatypeEntry } from "../../../../../AppActor/actors/modellierungModel/selectors";
import { useHtmlId } from "../../../../../../hooks";
import { useModellierungDatatypes } from "../../../../../AppActor/actors/modellierungModel/hooks";
import type { LiteEigenschaft } from "../../../../../AppActor/actors/modellierungModel/schemas";
import { useSendStoreEvent } from "../../../../../AppActor/EventStore/hooks";
import type { EditRendererProps } from "../../types";
import { getOptionLabel, useDatatypeRefEntry } from "./helpers";
import DatatypeName from "./DatatypeName";
import "./DatatypeEdit.scss";

export default function DatatypeEdit({
	activeNode,
	isReadOnly,
	projektId,
}: EditRendererProps<LiteEigenschaft>): JSX.Element {
	const sendStoreEvent = useSendStoreEvent();
	const inputId = useHtmlId();
	const datatypes = useModellierungDatatypes();
	const refDatatypeEntry = useDatatypeRefEntry(activeNode);

	const handleDatatypeChange = (
		_e: unknown,
		value: LiteDatatypeEntry | null,
	) => {
		const nodeId = activeNode.id;
		sendStoreEvent({
			type: "MODELLIERUNG.MODELL.APPLY",
			payload: {
				projektId,
				patch: {
					type: "changeDetailsRef",
					payload: { ref: value?.id ?? null, nodeId },
				},
			},
		});
	};

	return (
		<EditFormFieldLabel
			text={<span>Datentypreferenz</span>}
			htmlFor={inputId}
			data-testid="datatype-select-label"
			data-is-anonymous={!activeNode.datentypReferenz}
		>
			<Autocomplete
				key={activeNode.id}
				disabled={isReadOnly}
				disablePortal
				options={datatypes}
				value={refDatatypeEntry}
				onChange={handleDatatypeChange}
				fullWidth
				clearText="Datentypreferenz entfernen"
				componentsProps={{
					clearIndicator: {
						[String("data-testid")]: "clear-datatype-reference",
					},
				}}
				getOptionKey={(option) => option.id}
				getOptionLabel={(option) => getOptionLabel(datatypes, option)}
				renderOption={(params, option) => (
					<span
						{...params}
						data-testid="datatype-option"
						data-dt-name={option.name}
						data-dt-id={option.id}
					>
						<DatatypeName
							className="lite-datatype-select__option"
							title={getOptionLabel(datatypes, option)}
							refEntry={option}
						/>
					</span>
				)}
				renderInput={(params) => (
					<TextField
						{...params}
						placeholder="Anonymer Datentyp"
						data-testid="datatype-input"
					/>
				)}
			/>
		</EditFormFieldLabel>
	);
}
