import { TableCell } from "@mui/material";
import classNames from "classnames";
import type { ReactNode } from "react";
import type { ExtendProps, PropsOf } from "../../../../util/types";
import "./DatatypeTableCell.scss";

type DatatypeTableCellProps = ExtendProps<
	PropsOf<typeof TableCell>,
	{
		children: ReactNode;
		className?: string;
		variant: "name" | "description" | "actions";
	}
>;

export default function DatatypeTableCell({
	children,
	className,
	variant,
	...props
}: DatatypeTableCellProps): JSX.Element {
	return (
		<TableCell
			className={classNames(
				"datatype-table-cell",
				`datatype-table-cell--${variant}`,
				className,
			)}
			align={variant === "actions" ? "center" : undefined}
			{...props}
		>
			{children}
		</TableCell>
	);
}
