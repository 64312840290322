import type { ModelKonfiguration } from "../../AppActor/actors/modellierungModel/schemas";
import {
	createFormHelpers,
	type FieldDefinition,
	type FormHelpers,
} from "../../EditFormFieldLite";

export function createConfigurationFields({
	readOnly,
}: {
	readOnly: boolean;
}): FieldDefinition<ModelKonfiguration>[] {
	return [
		{
			name: "fassungVom",
			label: "Fassung vom",
			readOnly,
		},
		{
			name: "namespace",
			label: "Namespace",
			readOnly,
			isRequired: true,
		},
		{
			name: "prefix",
			label: "Präfix",
			readOnly,
			isRequired: true,
		},
		{
			name: "schemaLocationBase",
			label: "Schema Location Base",
			readOnly,
		},
		{
			name: "standardEigenschaften",
			label: "Standard Eigenschaften",
			readOnly,
		},
		{
			name: "xsdNamedTypeNameSuffix",
			label: "XSD Named Type Name Suffix",
			readOnly,
		},
	];
}

export function createConfigurationFormHelpers({
	readOnly,
}: {
	readOnly: boolean;
}): FormHelpers<ModelKonfiguration> {
	return createFormHelpers<ModelKonfiguration>(
		createConfigurationFields({ readOnly }),
	);
}
