import type { SyntheticEvent } from "react";
import { useState, useMemo, useEffect } from "react";
import { selectSuggestedCodelist } from "../../../../../../../redux/codelistSlice";
import { useAppSelector } from "../../../../../../../redux/hooks";
import useCodelistRequest from "./useCodelistRequest";
import type { CodelistComboBoxProps } from "./types";
import { useHtmlId } from "../../../../../../../hooks";
import InputFieldWithInlineChips from "../../../../../../ui/Input/InputFieldWithInlineChips";
import {
	subSelectCodelistIdentifier,
	subSelectCodelistVersion,
} from "../../../../../../EditorState/subSelectors";
import useSectionDependentProfile from "../../../../../../Profiling/useSectionDependentProfile";
import {
	useCodeliste,
	useReferenceProfile,
} from "../../../fieldRendererHelpers";

const CodelistComboBox = ({
	activeNode,
	activePath,
	isReadOnly,
	label,
	ariaDescribedBy,
	restrictions,
	updatePartialCodelist,
}: CodelistComboBoxProps): JSX.Element => {
	const { profile } = useSectionDependentProfile(activePath);
	const referenceProfile = useReferenceProfile(profile, activeNode);

	// TODO: Re-enable codelist metadata
	// const { version: versionStandard, kennung: kennungStandard } =
	// activeNode.codeliste || {};
	const { version: versionStandard, kennung: kennungStandard } =
		useCodeliste(activeNode);
	const kennungReference = subSelectCodelistIdentifier(referenceProfile);
	const kennungProfile = subSelectCodelistIdentifier(profile);
	const kennung = kennungStandard ?? kennungReference ?? kennungProfile;

	const versionReference = subSelectCodelistVersion(referenceProfile);
	const versionProfile = subSelectCodelistVersion(profile);
	const version = versionStandard ?? versionReference ?? versionProfile;

	const suggestions = useAppSelector(selectSuggestedCodelist(kennung, version));

	const { request } = useCodelistRequest();
	useEffect(() => {
		if (!suggestions) {
			request({ kennung, version });
		}
	}, [kennung, request, suggestions, version]);

	const [inputValue, setInputValue] = useState<string>("");

	const isAddDisabled = restrictions.includes(inputValue);

	const createHandleDelete = (value: string) => () => {
		if (!isReadOnly)
			updatePartialCodelist({
				values: restrictions.filter((item) => item !== value),
			});
	};

	const handleAdd = (event: SyntheticEvent, val: string[] | undefined) => {
		if (isAddDisabled || isReadOnly) return;
		if (val) {
			updatePartialCodelist({ values: [...val] });
		}
		setInputValue("");
	};

	const handleInputChange = (
		event: SyntheticEvent<Element, Event>,
		newValue: string,
	) => {
		setInputValue(newValue);
	};

	const filteredSuggestions = useMemo(
		() =>
			suggestions?.filter((suggestion) => !restrictions?.includes(suggestion)),
		[restrictions, suggestions],
	);

	const inputId = useHtmlId();

	return (
		<InputFieldWithInlineChips
			values={restrictions}
			inputId={inputId}
			inputValue={inputValue}
			isAddDisabled={isAddDisabled}
			inputDataTestId="cl-restriction-input"
			addButtonDataTestId="codelist-restriction__add"
			chipTestField="codelist-restriction__chip"
			isReadOnly={isReadOnly}
			options={filteredSuggestions}
			handleChange={handleAdd}
			handleInputChange={handleInputChange}
			createHandleDelete={createHandleDelete}
			label={label}
			ariaDescribedBy={ariaDescribedBy}
		/>
	);
};

export default CodelistComboBox;
