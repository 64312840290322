import Info from "../../../../../ui/Info/Info";

const ValueInfo = ({ label }: { label: string }) => (
	<Info>
		Wenn ein {`"Fixed-Wert"`} eingegeben ist, ist {`"${label}"`} nicht
		bearbeitbar.
	</Info>
);

export default ValueInfo;
