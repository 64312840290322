import RestrictionEditForm from "./RestrictionEditForm";
import useActiveDataType from "../../../useActiveDataType";
import useActiveRestriction from "../../../useActiveRestriction";
import useActiveRestrictionProfile from "../../../useActiveRestrictionProfile";
import ProfilingEditForm from "../../../../Profiling/ProfilingEditForm/ProfilingEditForm";
import EditorContent from "../../../../EditorContent";
import { selectRestrictionSelectionModel } from "../../../../EditorState/selectors";
import SelectionTable from "./RestrictionEditForm/SelectionTable";
import "./RestrictionEditView.scss";

const RestrictionEditView = (): JSX.Element => {
	const { activeDataType } = useActiveDataType();
	const activeRestriction = useActiveRestriction();
	const { activeNode: activeRestrictionProfile } =
		useActiveRestrictionProfile(activeDataType?.id) || {};

	const modelSelector = selectRestrictionSelectionModel(
		activeRestriction?.get("id"),
	);

	if (!activeDataType) return <></>;

	return (
		<EditorContent padTop={false}>
			{activeRestrictionProfile &&
			activeRestrictionProfile.id &&
			activeDataType.id !== activeRestrictionProfile.id ? (
				<ProfilingEditForm />
			) : (
				<div className="restriction-edit-view__form">
					<RestrictionEditForm />
					<div
						className="restriction-edit-view__table"
						data-testid="restriction-ref-table"
					>
						{/*
							Key the selection table to the active node, so that it re-loads, when
							we change to another restriction
						*/}
						<SelectionTable
							key={activeRestriction?.get("id")}
							modelSelector={modelSelector}
							isRestrictionSelection
						/>
					</div>
				</div>
			)}
		</EditorContent>
	);
};

export default RestrictionEditView;
