import classNames from "classnames";
import type { DetailedHTMLProps, HTMLAttributes, ReactNode } from "react";
import type { ExtendProps } from "../../util/types";
import "./EditorContent.scss";

type SectionProps = DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>;

function EditorContent({
	children,
	className,
	padTop = true,
	...props
}: ExtendProps<
	SectionProps,
	{
		children?: ReactNode;
		className?: string;
		padTop?: boolean;
	}
>): JSX.Element {
	return (
		<section
			{...props}
			className={classNames(
				"editor-content",
				padTop && "editor-content--pad-top",
				className,
			)}
		>
			{children}
		</section>
	);
}

export default EditorContent;
