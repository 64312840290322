import type { Location } from "react-router-dom";
import { NavLink, useMatch, useLocation } from "react-router-dom";
import HomeOutelinedIcon from "@mui/icons-material/HomeOutlined";
import HomeIcon from "@mui/icons-material/Home";
import type { ReactNode } from "react";
import type { MenuItemLinkProps } from "@xoev/ui";
import { MenuItem } from "@xoev/ui";
import classNames from "classnames";
import type { ExtendProps } from "../../util/types";
import { VisuallyHidden } from "../ui";
import "./SubMenuHome.scss";

type SubmenuHomeProps = Omit<
	ExtendProps<
		MenuItemLinkProps<typeof NavLink>,
		{ label: ReactNode; to: string; isActive?: (location: Location) => boolean }
	>,
	"children" | "href"
>;

export default function SubMenuHome({
	label,
	to,
	isActive,
	...props
}: SubmenuHomeProps): JSX.Element {
	const match = useMatch(to);
	const location = useLocation();
	const isLinkActive = isActive ? isActive(location) : !!match;

	return (
		<MenuItem>
			<MenuItem.Link
				className="submenu-home"
				as={NavLink}
				to={to}
				isActive={isLinkActive}
				{...props}
			>
				<VisuallyHidden>{label}</VisuallyHidden>
				<div className="submenu-home__icon-wrapper">
					<HomeIcon
						className={classNames(
							"submenu-home__icon",
							isLinkActive && "submenu-home__icon--active",
						)}
					/>
				</div>
				<div className="submenu-home__icon-wrapper">
					<HomeOutelinedIcon
						className={classNames(
							"submenu-home__icon",
							!isLinkActive && "submenu-home__icon--active",
						)}
					/>
				</div>
			</MenuItem.Link>
		</MenuItem>
	);
}
