import MenuIcon from "@mui/icons-material/Menu";
import FilterIcon from "@mui/icons-material/FilterList";
import type { LiteBausteinType } from "../../../AppActor/actors/modellierungModel/schemas";
import ToolbarMenu from "../../Toolbar/ToolbarMenu";
import BausteinNodeMenuItems from "./BausteinNodeMenuItems";
import FilterMenuItems from "./FilterMenuItems";

export default function BausteinToolbar({
	activeFilters,
	isDisplayOnly,
	toggleFilter,
}: {
	activeFilters: LiteBausteinType[];
	isDisplayOnly: boolean;
	toggleFilter: (...filters: LiteBausteinType[]) => void;
}): JSX.Element {
	return (
		<>
			{!isDisplayOnly && (
				<ToolbarMenu icon={MenuIcon} label="Menü">
					{({ closeMenu, isOpen }) => (
						<BausteinNodeMenuItems closeMenu={closeMenu} isOpen={isOpen} />
					)}
				</ToolbarMenu>
			)}
			<ToolbarMenu icon={FilterIcon} label="Filter">
				{() => (
					<FilterMenuItems
						activeFilters={activeFilters}
						toggleFilter={toggleFilter}
					/>
				)}
			</ToolbarMenu>
		</>
	);
}
