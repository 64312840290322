import PlusIcon from "@mui/icons-material/Add";
import MinusIcon from "@mui/icons-material/Remove";
import classNames from "classnames";
import { useTreeContext } from "../TreeContext";
import { useTreeNodeContext } from "./TreeNodeContext";
import type { PathFragment } from "../types";
import "./TreeNodeToggle.scss";

function TreeNodeToggle(): JSX.Element {
	const { isNodeExpandable } = useTreeContext<unknown, PathFragment>();
	const { node, path, isOpen } = useTreeNodeContext<unknown, PathFragment>();
	const isExpandable = isNodeExpandable(node, path);
	return (
		<div
			className={classNames(
				"treenode-toggle",
				isOpen && "treenode-toggle--expanded",
			)}
		>
			{isExpandable && (
				<>
					<div
						className={classNames(
							"treenode-toggle__icon",
							"treenode-toggle__icon--minus",
							isOpen && "treenode-toggle__icon--active",
						)}
					>
						<MinusIcon className="treenode-toggle__svg" />
					</div>
					<div
						className={classNames(
							"treenode-toggle__icon",
							"treenode-toggle__icon--plus",
							!isOpen && "treenode-toggle__icon--active",
						)}
					>
						<PlusIcon className="treenode-toggle__svg" />
					</div>
				</>
			)}
		</div>
	);
}

export default TreeNodeToggle;
