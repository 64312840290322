import type { Falsy } from "./types";

export enum Direction {
	Asc = 1,
	Desc = -1,
}

export const compareNumbers = (a: number, b: number, dir: number): number =>
	(a - b) * dir;

type SorterFactory<Type> = <Field extends string>(
	sortField: Field,
	dir: Direction,
) => <ComparedObject extends { [k in Field]: Type }>(
	a: ComparedObject,
	b: ComparedObject,
) => number;

export const createNumberSorter: SorterFactory<number> =
	(sortField, dir) => (a, b) =>
		compareNumbers(a[sortField], b[sortField], dir);

const collator = new Intl.Collator("de", { sensitivity: "base" });

export const compareStrings = (a: string, b: string, dir: Direction): number =>
	collator.compare(a, b) * dir;

export const createStringSorter: SorterFactory<string> =
	(sortField, dir) => (a, b) => {
		const strA = a[sortField] || "";
		const strB = b[sortField] || "";
		return compareStrings(strA, strB, dir);
	};

export function extractFromList<T>(
	list: T[],
	shouldExtract: (value: T) => boolean,
): [extracted: T[], rest: T[]] {
	const extracted: T[] = [];
	const rest: T[] = [];

	for (const elem of list) {
		if (shouldExtract(elem)) {
			extracted.push(elem);
		} else {
			rest.push(elem);
		}
	}

	return [extracted, rest];
}

export function filterFalsy<T>(list: (T | Falsy)[]): T[] {
	return list.filter(Boolean) as T[];
}
