import { Link } from "react-router-dom";
import type { ReactNode } from "react";
import {
	useDefinitionPath,
	useIsDefinedInMainModell,
	useNodeTargetHref,
} from "../../../../../AppActor/actors/modellierungModel/hooks";
import type { LiteEigenschaft } from "../../../../../AppActor/actors/modellierungModel/schemas";
import InfoRendererDisplay from "../../../../../InfoNodeEditView/InfoNodeEditForm/FormFieldRenderer/renderers/InfoRenderer/InfoRendererDisplay";
import type { DisplayRendererProps } from "../../types";
import DatatypeName from "./DatatypeName";
import { useDatatypeRefEntry } from "./helpers";
import DynamicInfo from "../../../../../ui/DynamicInfo";

export default function DatatypeDisplay({
	activeNode,
}: DisplayRendererProps<LiteEigenschaft>): JSX.Element {
	const refDatatypeEntry = useDatatypeRefEntry(activeNode);
	const definitionPath = useDefinitionPath(refDatatypeEntry?.id);
	const isDefinedInMainModell = useIsDefinedInMainModell(refDatatypeEntry?.id);
	const href = useNodeTargetHref(definitionPath);
	const name = refDatatypeEntry && <DatatypeName refEntry={refDatatypeEntry} />;

	let value: ReactNode = (
		<>
			{name}
			<DynamicInfo
				infoKey="datatypeNotInStandard"
				inline="end"
				data-testid="override-info"
			/>
		</>
	);
	if (!refDatatypeEntry) {
		value = null;
	}
	if (isDefinedInMainModell && href) {
		value = <Link to={href}>{name}</Link>;
	}

	return (
		<InfoRendererDisplay
			label="Datentypreferenz"
			value={value}
			fallback="Anonymer Datentyp"
		/>
	);
}
