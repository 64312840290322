import { useMemo } from "react";
import { useEventHandler } from "../../../hooks";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import type { UiFilterGroup } from "../../../redux/uiSlice";
import { setFilter, selectFilterValue } from "../../../redux/uiSlice";
import type { TreeNodeType } from "../../Profiling/types";
import type { DatatypeFilterPredicate } from "./types";

/**
 * Hook up the `EditorSidebar.Search` input to the filter state.
 *
 * Note, that no filtering is happening here; only the input state is connected
 * to the global state
 *
 * @example
 * ```tsx
 * const { getSearchInputProps } = useListSearch(UiFilterGroups.DatatypeList);
 * // ...
 * return (
 *   <EditorSideBar.Search
 *     {...getSearchInputProps()}
 *     label="Datentypen durchsuchen"
 *   />
 * )
 * ```
 */
export function useListSearch(filterGroup: UiFilterGroup) {
	const dispatch = useAppDispatch();
	const searchTerm = useAppSelector(selectFilterValue(filterGroup, "query"));
	const setSearchTerm = useEventHandler((term: string) => {
		dispatch(setFilter(filterGroup, { query: term }));
	});
	const clearInput = useEventHandler(() => setSearchTerm(""));
	return useMemo(() => {
		const getSearchInputProps = () => ({
			onChange: setSearchTerm,
			onClear: clearInput,
			value: searchTerm,
		});
		return { getSearchInputProps, searchTerm };
	}, [clearInput, searchTerm, setSearchTerm]);
}

/**
 * Filter a list of datatypes by a variable number of filters.
 * Note, that the list of filters should be wrapped in a `useMemo`, so that the
 * filtered list must not be re-created on every render
 */
export function useListFilters(
	datatypes: TreeNodeType[] | null,
	filters: DatatypeFilterPredicate[],
) {
	const filteredList = useMemo(() => {
		if (!datatypes?.length) return [];
		const filtered = datatypes.filter((datatype) =>
			filters.every((predicate) => predicate(datatype)),
		);
		return filtered;
	}, [datatypes, filters]);
	return datatypes ? filteredList : null;
}
