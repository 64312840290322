import { useMemo } from "react";
import { useMatch } from "react-router-dom";
import { useAppSelector } from "../../redux/hooks";
import { selectActiveStandard } from "../../redux/standardsSlice";
import { decodeXPath } from "../../util/url";
import { useStateSelector } from "../EditorState";
import { selectStandard } from "../EditorState/selectors";
import useDataTypes from "./useDataTypes";
import { createStandardPath } from "../Standards/helpers";
import { NotADatatypeError } from "../Profiling/profilingHelpers";
import type { LiteBaustein } from "../AppActor/actors/modellierungModel/schemas";
import { selectQNameFromModell } from "../AppActor/actors/modellierungModel/selectors";
import { selectModellContainer } from "../../redux/treeSlice";

function useActiveDataTypeBase(route: string, standard?: string) {
	const { params } = useMatch(route) || {};
	const { nodeId: xpathParam = "" } = params || {};
	const qname = decodeXPath(xpathParam);
	const dataTypes = useDataTypes(standard);
	const modell = useAppSelector(selectModellContainer(standard));
	return useMemo(() => {
		const activeIndex = (dataTypes || []).findIndex(
			(type) => modell && selectQNameFromModell(modell, type) === qname,
		);
		const activeDataType = dataTypes?.[activeIndex] || null;
		if (activeDataType) {
			NotADatatypeError.assert(activeDataType);
		}
		return { activeIndex, activeDataType, didFindMatch: activeIndex !== -1 };
	}, [dataTypes, modell, qname]);
}

export function useActiveStandardDataType(): {
	activeIndex: number;
	activeDataType: LiteBaustein | null;
	didFindMatch: boolean;
} {
	const standard = useAppSelector(selectActiveStandard());
	const route = createStandardPath({
		kennung: standard?.kennung,
		path: "/datentypen/:nodeId/*",
		replacement: ":standardKennung",
	});
	return useActiveDataTypeBase(route, standard?.kennung);
}

export default function useActiveDataType(): {
	activeIndex: number;
	activeDataType: LiteBaustein | null;
	didFindMatch: boolean;
} {
	const standard = useStateSelector(selectStandard());
	return useActiveDataTypeBase("/profilierung/datentypen/:nodeId/*", standard);
}
