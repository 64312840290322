import { useMemo } from "react";
import { extraProps } from "../util/props";
import useDelayedValue from "./useDelayedValue";

const ANNOUNCE_LOAD_DELAY = 800;

/**
 * Create props to spread to a wrapper element that is involved in a loading
 * scenario, to turn it into an accessible loading indicator that triggers
 * informative screen reader announcements. Note that the used element should
 * not have any semantic meaning, as it will be assigned a `role` attribute, so
 * implicit element roles such as `<li>`, `<main>`, `<input>`, etc. will be
 * lost.
 *
 * @param options The current loading status and the loading description
 * @returns Props that can be spread to a wrapper element, to turn it into an
 * accessible loading indicator
 */
export default function useLoaderAttributes({
	isLoading,
	description,
}: {
	isLoading: boolean;
	description: string;
}) {
	const delayedIsLoading = useDelayedValue(isLoading, ANNOUNCE_LOAD_DELAY);
	const announceToScreenReader = delayedIsLoading && isLoading;

	return useMemo(
		() =>
			extraProps(
				announceToScreenReader &&
					({
						role: "progressbar",
						"aria-valuetext": description,
						"aria-busy": true,
						"aria-live": "assertive",
						"aria-valuemin": 0,
						"aria-valuemax": 100,
					} as const),
			),
		[announceToScreenReader, description],
	);
}
