import { Link, useMatch } from "react-router-dom";
import { MenuItem, Header } from "@xoev/ui";
import XoevLogo from "./XoevLogo";
import SkipLink from "../SkipLink";
import getHelpPath from "../../adocBase";
import { useAppInfo } from "../AppInfoProvider";
import MenuHome from "./MenuHome";
import ProjectTabs from "../Projects/ProjectTabs";

const Menu = (): JSX.Element => {
	const { info } = useAppInfo();
	const basePath = info?.config?.["docs-plattform"];
	const profilingMatch = useMatch("/profilierung/*");
	const messagesMatch = useMatch("/nachrichten/*");
	const isProfilingActive = !!profilingMatch;
	const isMessagesActive = !!messagesMatch;

	return (
		<>
			<SkipLink label="Navigation überspringen" target="#main" />
			<Header>
				<Header.Nav data-testid="main-navigation">
					<Header.NavList>
						<li>
							<Header.Logo label="XÖV-Suite Homepage" as={Link} to="/">
								<XoevLogo />
							</Header.Logo>
						</li>
						<MenuHome
							to="/"
							isActive={({ pathname }) =>
								[
									"/",
									"/index",
									"/impressum",
									"/datenschutzerklaerung",
									"/barrierefreiheit",
								].includes(pathname)
							}
							label="Startseite"
						/>
						<MenuItem>
							<MenuItem.Link
								as={Link}
								to="/profilierung"
								isActive={isProfilingActive}
								data-testid="profiling-home-link"
							>
								Profilierung
							</MenuItem.Link>
						</MenuItem>
						<MenuItem>
							<MenuItem.Link
								as={Link}
								to="/nachrichten"
								isActive={isMessagesActive}
								data-testid="messages-link"
							>
								Nachrichten
							</MenuItem.Link>
						</MenuItem>
						<MenuItem>
							<MenuItem.Link
								href={getHelpPath(basePath)}
								target="_blank"
								rel="noopener noreferrer"
								isActive={false}
								data-testid="info-area-link"
							>
								Hilfe
							</MenuItem.Link>
						</MenuItem>
						<ProjectTabs />
					</Header.NavList>
				</Header.Nav>
			</Header>
		</>
	);
};

export default Menu;
