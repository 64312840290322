import classNames from "classnames";
import { useModellierungDatatypes } from "../../../../../AppActor/actors/modellierungModel/hooks";
import { getDuplicateLabel } from "./helpers";
import type { ExtendProps, PropsOf } from "../../../../../../util/types";
import type { LiteDatatypeEntry } from "../../../../../AppActor/actors/modellierungModel/selectors";
import "./DatatypeName.scss";

export default function DatatypeName({
	refEntry,
	className,
	...props
}: ExtendProps<PropsOf<"span">, { refEntry: LiteDatatypeEntry }>): JSX.Element {
	const datatypes = useModellierungDatatypes();
	const duplicateLabel = getDuplicateLabel(datatypes, refEntry);
	return (
		<span className={classNames("lite-datatype-name", className)} {...props}>
			{refEntry.name}
			{duplicateLabel && (
				<span className="lite-datatype-name__path">{duplicateLabel}</span>
			)}
		</span>
	);
}
