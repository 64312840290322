import { Alert, CircularProgress, Snackbar } from "@mui/material";
import { createPath, parsePath, useLocation } from "react-router-dom";
import classNames from "classnames";
import type { MouseEventHandler } from "react";
import { useMemo, lazy, Suspense, useEffect, useState } from "react";
import ErrorBoundary from "@xoev/error-boundary";
import { VisuallyHidden } from "../ui";
import convert from "./convert";
import { RequestStatus } from "../Api";
import { useStableNavigate } from "../../hooks";
import { getPreferedMotion } from "../../util/a11y";
import type { AdocConfig } from "./types";
import getHelpPath from "../../adocBase";
import { useAppInfo } from "../AppInfoProvider";
import "./AdocRenderer.scss";

const ERROR_BOUNDARY_TITLE =
	"Bei der Verarbeitung des AsciiDoc Dokuments ist ein unerwarterter Fehler " +
	"aufgetreten.";

/**
 * The default configuration for asciidoctor. It will be merged with any custom
 * config that is passed to `AdocRenderer
 */
const DEFAULT_ADOC_CONFIG: AdocConfig = {
	doctype: "book",
	base_dir: "/api/dokumentation",
	safe: "server",
	attributes: {
		showtitle: true,
		// global eingestelltes Medien-Verzeichnis
		imagesdir: `${window.origin}/api/dokumentation/media`,
		// Always use the icon font when rendering in the browser, so we always
		// get the icons, without having to configure it in the documentation
		icons: "font",
		// Disable loading the icon font from a cdn. We'll include them manually
		// through `AdocDisplay` to ensure that they are only loaded when we
		// actually need them
		iconfont_remote: false,
		// Remove the `.adoc` extension from links
		relfilesuffix: "",
	},
};
/**
 * The empty adoc config, we use as the default value for the `config` prop in
 * `AdocRenderer`. We can't define it inline, since it would create a new
 * reference on every render and potentially cause an infinite update loop, so
 * we define it as a constant
 */
const EMPTY_ADOC_CONFIG: AdocConfig = {};

/**
 * Create a configuration object for asciidoctor, that extends our default
 * configuration
 *
 * @param config The configuration used to extend the default config
 * @returns The config to pass to the `convert` function
 */
function createAdocConfig(config: Partial<AdocConfig>): AdocConfig {
	return {
		...DEFAULT_ADOC_CONFIG,
		...config,
		attributes: {
			...(DEFAULT_ADOC_CONFIG.attributes || {}),
			...(config.attributes || {}),
		},
	};
}

type LinkLocation = Pick<
	Location,
	| "hash"
	| "host"
	| "hostname"
	| "href"
	| "origin"
	| "pathname"
	| "port"
	| "protocol"
	| "search"
>;

function linkToLocation(linkElem: HTMLAnchorElement): LinkLocation {
	return {
		hash: linkElem.hash,
		host: linkElem.host,
		hostname: linkElem.hostname,
		href: linkElem.href,
		origin: linkElem.origin,
		pathname: linkElem.pathname,
		port: linkElem.port,
		protocol: linkElem.protocol,
		search: linkElem.search,
	};
}

function shouldInterceptLink(linkLocation: LinkLocation) {
	const { origin } = linkLocation;
	return origin === window.location.origin;
}

function resolveHref(href: string, basePath?: string) {
	// If the link is absolute, don't re-write it
	if (href.startsWith("/")) return href;
	// Else, rebase it on top of the help path
	const { hash, pathname = "", search } = parsePath(href);
	if (href.startsWith("#") || href.startsWith("?")) {
		return (search || "") + (hash || "");
	}
	const normalizedPathname = pathname
		// Remove a potential leading slash
		.replace(/^\//, "")
		// Remove the .adoc extension
		.replace(/\.adoc$/, "");
	return createPath({
		hash,
		pathname: `${getHelpPath(basePath)}/${normalizedPathname}`,
		search,
	});
}

// Only load the display and with it the asciidoctor css, when it is actually
// needed
const LazyDisplay = lazy(() => import("./AdocDisplay"));

function AdocLoader({ isLoading }: { isLoading: boolean }) {
	return (
		<div
			className={classNames(
				"adoc-renderer__loader",
				isLoading && "adoc-renderer__loader--active",
			)}
		>
			<CircularProgress />
		</div>
	);
}

function useJumpToAnchor(shouldJumpToAnchor: boolean, trigger?: unknown) {
	useEffect(() => {
		let isCancelled = false;
		if (shouldJumpToAnchor) {
			setTimeout(() => {
				const currentHash = window.location.hash;
				if (isCancelled || currentHash === "#" || !currentHash) {
					return;
				}
				try {
					// `querySelector` might throw if the `currentHash` is not a valid
					// selector. In that case we try re-assigning the location hash,
					// which will only work in firefox. If that does not work, the jump
					// is ignored
					const jumpTarget = document.querySelector(
						decodeURIComponent(currentHash),
					);
					jumpTarget?.scrollIntoView({
						behavior: getPreferedMotion(),
						block: "start",
					});
				} catch {
					// Force the browser to jump to the anchor element
					// eslint-disable-next-line no-self-assign
					window.location.hash = window.location.hash;
				}
			}, 0);
		}
		return () => {
			isCancelled = true;
		};
	}, [shouldJumpToAnchor, trigger]);
}

function AdocConverter({
	markup,
	config: configProp = EMPTY_ADOC_CONFIG,
	className,
}: {
	markup?: string;
	config?: AdocConfig;
	className?: string;
}): JSX.Element {
	const { info } = useAppInfo();
	const basePath = info?.config?.["docs-plattform"];
	const config = useMemo(() => createAdocConfig(configProp), [configProp]);
	const [html, setHtml] = useState<string>("");
	const [status, setStatus] = useState(RequestStatus.Idle);
	const [error, setError] = useState<string | null>(null);

	const location = useLocation();
	const navigate = useStableNavigate();

	useJumpToAnchor(status === RequestStatus.Success);
	useJumpToAnchor(true, location.hash);

	useEffect(() => {
		let isCancelled = false;
		setStatus(RequestStatus.Loading);
		setError(null);
		convert(markup || "", config)
			.then((result) => {
				if (!isCancelled) {
					setError(null);
					setStatus(RequestStatus.Success);
					setHtml(result);
				}
			})
			.catch((e: unknown) => {
				setStatus(RequestStatus.Failure);
				setError(String(e));
			});
		return () => {
			isCancelled = true;
		};
	}, [config, markup]);

	const [showError, setShowError] = useState(false);

	useEffect(() => {
		if (status === RequestStatus.Failure) {
			setShowError(true);
		} else {
			setShowError(false);
		}
	}, [
		status,
		// When the message or the id change, we have a new error, so we might need
		// to open the alert again
		error,
	]);

	const handleErrorClose = () => {
		setShowError(false);
	};

	const handleClick: MouseEventHandler<HTMLDivElement> = (e) => {
		// Catch all clicks to `<a />` elements, so we can make in-page jumps and
		// linking between documents possible
		const elem = e.target instanceof Element ? e.target : null;
		if (elem?.tagName === "A") {
			const linkElem = elem as HTMLAnchorElement;
			const linkLocation = linkToLocation(linkElem);
			const href = elem.getAttribute("href");
			if (href && shouldInterceptLink(linkLocation)) {
				e.preventDefault();
				const resolved = resolveHref(href, basePath);
				navigate(resolved);
			}
		}
	};

	return (
		// We only use the click handler to intercept the clicks on `<a />`
		// elements inside the div, so we don't need key handlers
		// eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
		<div
			className={classNames(
				"adoc-renderer",
				status === RequestStatus.Loading && "adoc-renderer--loading",
			)}
			onClick={handleClick}
		>
			<VisuallyHidden>
				<span
					aria-hidden
					data-testid="adoc-rendering-loading-status"
					data-status={status}
				/>
			</VisuallyHidden>
			<Snackbar open={showError} key="adoc-renderer-conversion-error">
				<Alert severity="error" onClose={handleErrorClose}>
					Fehler beim Konvertieren des AsciiDoc Dokuments: {error}
				</Alert>
			</Snackbar>
			<AdocLoader isLoading={status === RequestStatus.Loading} />
			<Suspense fallback={<AdocLoader isLoading />}>
				<LazyDisplay className={className} html={html} />
			</Suspense>
		</div>
	);
}

// Wrap the converter in an ErrorBoundary, so when an error happens inside the
// converter only the asciidoc display is gone, but the rest of the app is
// still usable
const AdocRenderer: typeof AdocConverter = (props) => {
	return (
		<ErrorBoundary title={ERROR_BOUNDARY_TITLE}>
			<AdocConverter {...props} />
		</ErrorBoundary>
	);
};

export default AdocRenderer;
