import { useCallback, useMemo } from "react";
import type { ExpansionTree, NodeEventArg } from "../../Tree";
import Tree from "../../Tree";
import type { StandardProjekt } from "../../AppActor/actors/project/types";
import {
	createSelectChildren,
	selectNodeId,
} from "../../AppActor/actors/modellierungModel/selectors";
import {
	type LiteId,
	type LiteNode,
	joinLitePath,
	parseLitePath,
	type LiteBausteinType,
} from "../../AppActor/actors/modellierungModel/schemas";
import ModelTreeNode from "./ModelTreeNode";
import {
	useIsSearchActive,
	useIsSearchLoading,
	useSearchTrie,
} from "../../AppActor/actors/modellierungModel/search/hooks";
import {
	isExactSearchMatch,
	isSearchMatch,
} from "../../AppActor/actors/modellierungModel/search/helpers";
import { useSendStoreEvent } from "../../AppActor/EventStore/hooks";
import NoSearchResultsInfo from "../../StructureDisplay/StructureTree/NoSearchResultsInfo";

const defaultIsFilterMatch = () => true;

export default function ModelTree({
	activePath,
	project,
	rootNodes,
	rootPath,
	expansionState,
	isFilterMatch: isFilterMatchProp = defaultIsFilterMatch,
	NodeMenu,
	activeFilters,
}: {
	activePath: LiteId[];
	project: StandardProjekt;
	rootNodes: LiteNode[];
	rootPath: LiteId[];
	expansionState?: ExpansionTree<LiteId>;
	isFilterMatch?: (
		node: LiteNode,
		path: LiteId[],
		fullPath: LiteId[],
	) => boolean;
	NodeMenu: (props: { isOpen: boolean; closeMenu: () => void }) => JSX.Element;
	activeFilters?: LiteBausteinType[];
}): JSX.Element {
	const sendStoreEvent = useSendStoreEvent();
	const selectChildren = createSelectChildren(project);
	const rootPathStr = joinLitePath(rootPath);
	const stableRootPath = useMemo(
		() => parseLitePath(rootPathStr),
		[rootPathStr],
	);

	const isSearchLoading = useIsSearchLoading();
	const isSearchActive = useIsSearchActive();
	const searchTrie = useSearchTrie();

	const isFilterMatch = useCallback(
		(node: LiteNode, path: LiteId[], fullPath: LiteId[]) => {
			const isMatch = isFilterMatchProp(node, path, fullPath);
			if (!isSearchActive) {
				return isMatch;
			}
			return isMatch && isSearchMatch(searchTrie, fullPath);
		},
		[isFilterMatchProp, isSearchActive, searchTrie],
	);

	const isNodeExpandable = useCallback(
		(node: LiteNode, path: LiteId[]) => {
			return (
				selectChildren(node, path).filter((child) =>
					isFilterMatch(
						child,
						[...path, child.id],
						[...stableRootPath, ...path, child.id],
					),
				).length > 0
			);
		},
		[selectChildren, isFilterMatch, stableRootPath],
	);

	const createHandleAction =
		(
			type:
				| "MODELLIERUNG_TREE.NODE.ACTIVATE"
				| "MODELLIERUNG_TREE.NODE.OPEN"
				| "MODELLIERUNG_TREE.NODE.CLOSE",
		) =>
		({ path }: NodeEventArg<LiteNode, LiteId>) => {
			if (!path) return;
			const fullPath = [...rootPath, ...path];
			const payload = { projektId: project.id, fullPath };
			sendStoreEvent({ type, payload });
		};

	const handleActivate = createHandleAction("MODELLIERUNG_TREE.NODE.ACTIVATE");
	const handleOpen = createHandleAction("MODELLIERUNG_TREE.NODE.OPEN");
	const handleClose = createHandleAction("MODELLIERUNG_TREE.NODE.CLOSE");

	return (
		<>
			{isSearchActive && !isSearchLoading && searchTrie.size === 0 && (
				<NoSearchResultsInfo infoKey="noSearchResultsTree" />
			)}
			<Tree<LiteNode, LiteId>
				getChildren={selectChildren}
				getNodeId={selectNodeId}
				rootNodes={rootNodes}
				isNodeExpandable={isNodeExpandable}
				onActivate={handleActivate}
				onOpen={handleOpen}
				onClose={handleClose}
				expansionState={expansionState}
				activeFilters={activeFilters}
				renderNode={({ node, path, getProps, isExpanded }) => {
					const fullPath = [...rootPath, ...path];
					if (!isFilterMatch(node, path, fullPath)) return <></>;
					const isExactMatch =
						isSearchActive && isExactSearchMatch(searchTrie, fullPath);
					return (
						<ModelTreeNode
							getProps={getProps}
							node={node}
							path={path}
							rootPath={rootPath || []}
							activePath={activePath}
							isExpanded={isExpanded}
							NodeMenu={NodeMenu}
							onActivate={handleActivate}
							isMarked={isExactMatch}
						/>
					);
				}}
			/>
		</>
	);
}
