import type SavedProfileData from "../../../types/SavedProfileData";
import { ProjektType } from "../../AppActor/actors/modellierungModel/schemas";
import type { ProjectPostResponse } from "./types";

export default function createProfileData(
	data: ProjectPostResponse,
): SavedProfileData {
	return {
		typ: ProjektType.Profilierung,
		metadaten: { ...data.metadaten },
		dokumentation: data.dokumentation,
		profile: data.profile,
		datentypen: data.datentypen,
		profilierteVersionStandard: {
			kennung: data.metadaten.kennung,
			version: data.metadaten.version,
		},
		konfiguration: data.konfiguration,
	};
}
