import { useMemo } from "react";
import type {
	DatatypeRefItem,
	MessageElementRefItem,
	RefItem,
	SpecificRefItem,
} from "./types";
import { RefType } from "./types";
import {
	selectDatatypeRestrictionMap,
	selectRestrictionName,
	selectStandard,
	selectState,
} from "../../../../../EditorState/selectors";
import type {
	EditorSelectorReturn,
	EditorState,
	EditorStateSelector,
} from "../../../../../EditorState/types";
import { stringifyRefId } from "./refIds";
import { encodeXPath } from "../../../../../../util/url";
import type { LiteId } from "../../../../../AppActor/actors/modellierungModel/schemas";
import { useStateSelector } from "../../../../../EditorState";
import { selectModellContainer } from "../../../../../../redux/treeSlice";
import { useAppSelector } from "../../../../../../redux/hooks";
import {
	createBaseRefs,
	createGetType,
} from "../../../../../SelectionTableProvider/helpers";
import { extractFromList } from "../../../../../../util/lists";
import { AssertionError } from "../../../../../../util/error";

const emptyExtraction: [RefItem[], RefItem[]] = [[], []];

export function getBasePath(row: SpecificRefItem) {
	if (row.type === RefType.Datatype) {
		const { rootQName: rootId, restrictionId } = row;
		const rootPath = encodeXPath(rootId);
		return `/profilierung/datentypen/${rootPath}/profil/${restrictionId}`;
	}
	return "/profilierung/nachrichtenstrukturen";
}
function createDatatypeReferences(
	datatypes: RefItem[],
	dtRestrictionMap: EditorSelectorReturn<typeof selectDatatypeRestrictionMap>,
	state: EditorState,
): DatatypeRefItem[] {
	return datatypes.flatMap((dtRef) => {
		const restrictions = dtRestrictionMap.get(dtRef.rootQName);
		if (!restrictions) return [];
		return restrictions
			.map((restrictionId) => {
				// We know that the restriction's id exists, so its name must also exist
				const restrictionName = selectRestrictionName(restrictionId)(
					state,
				) as string;
				return {
					...dtRef,
					type: RefType.Datatype as const,
					restrictionId,
					restrictionName,
					id: stringifyRefId({
						type: RefType.Datatype,
						qnamePath: dtRef.qnamePath,
						idPath: dtRef.idPath,
						restrictionId,
					}),
				};
			})
			.toArray();
	});
}

// Create a set proxy, that does not initialize the set until it is actually
// used. This way, we only have to create the set when we actually use it
function createLazySet<T>(items: T[]): { has: (item: T) => boolean } {
	let set: Set<T> | null = null;
	return {
		has(item: T) {
			if (!set) {
				set = new Set(items);
			}
			return set.has(item);
		},
	};
}

export function useReferenceModel(
	usedBy: LiteId[][] | undefined,
	modelSelector: EditorStateSelector<string[]>,
	isRestrictionSelection?: boolean,
): {
	references: (MessageElementRefItem | DatatypeRefItem)[];
	selectionModel: string[];
} {
	const selectionModel = useStateSelector(modelSelector);
	const state = useStateSelector(selectState());
	const standard = useStateSelector(selectStandard());
	const modell = useAppSelector(selectModellContainer(standard));
	const references = useMemo(() => {
		AssertionError.notNullish(
			modell,
			"ModellContainer must be defined to find references",
		);
		const getType = createGetType(standard, modell);
		const refs = createBaseRefs(modell, usedBy, getType);
		const [datatypes, nonDatatypes] =
			standard && refs.length
				? extractFromList(refs, (ref) => ref.type === RefType.Datatype)
				: emptyExtraction;
		const dtRestrictionMap = selectDatatypeRestrictionMap()(state);
		const dtReferences = createDatatypeReferences(
			datatypes,
			dtRestrictionMap,
			state,
		);
		return [...(nonDatatypes as MessageElementRefItem[]), ...dtReferences];
	}, [modell, standard, state, usedBy]);
	const model = useMemo(() => {
		const refIds = references.map((ref) => stringifyRefId(ref));
		const refSet = createLazySet(refIds);
		const selectionSet = createLazySet(selectionModel);
		return isRestrictionSelection
			? selectionModel.filter((refId) => refSet.has(refId))
			: refIds.filter((refId) => !selectionSet.has(refId));
	}, [references, selectionModel, isRestrictionSelection]);
	return useMemo(
		() => ({ references, selectionModel: model }),
		[model, references],
	);
}

export const typeLables = {
	[RefType.Datatype]: "Datentypen",
	[RefType.MessageElement]: "Nachrichtenelemente",
};
