import { useEditorData } from "../../EditorData";
import StructureTree from "../../StructureDisplay/StructureTree";
import type { PropsOf } from "../../../util/types";

const ProfilingTree = (
	props: Omit<PropsOf<typeof StructureTree>, "parentStatus">,
): JSX.Element => {
	const { initStatus: projectStatus } = useEditorData();
	return <StructureTree parentStatus={projectStatus} {...props} />;
};

export default ProfilingTree;
