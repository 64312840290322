import { DEFAULT_CARDINALITY } from "../../../../../AppActor/actors/modellierungModel/helpers";
import type { LiteEigenschaft } from "../../../../../AppActor/actors/modellierungModel/schemas";
import InfoRendererDisplay from "../../../../../InfoNodeEditView/InfoNodeEditForm/FormFieldRenderer/renderers/InfoRenderer/InfoRendererDisplay";
import type { DisplayRendererProps } from "../../types";

export default function GruppierungsartDisplay({
	activeNode,
}: DisplayRendererProps<LiteEigenschaft>): JSX.Element {
	return (
		<InfoRendererDisplay
			label="Gruppierungsart"
			value={activeNode.gruppeArt}
			fallback={DEFAULT_CARDINALITY}
		/>
	);
}
