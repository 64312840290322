import type { ImmutableMap } from "@xoev/immutable-map";
import type { CodelistStateResult } from "../../redux/codelistSlice";
import type ProfilingMetadataValues from "../../types/ProfilingMetadataValues";
import type { SimpleAsyncGenerator } from "../../util/generator";
import type {
	DatatypeMap,
	MessageProfileValues,
	MetadataMap,
	RestrictionId,
	RestrictionProfileValues,
	StateProfileMap,
} from "../EditorState/types";
import type { TreeNodeType } from "../Profiling/types";
import type { LiteId } from "../AppActor/actors/modellierungModel/schemas";
import type { LiteModellContainer } from "../AppActor/actors/modellierungModel/types";

export enum Severity {
	Info = "info",
	Warning = "warning",
	Error = "error",
}

export enum ValidationResultGroup {
	MessageProfiles = "MessageProfiles",
	RestrictionProfiles = "RestrictionProfiles",
	Restrictions = "Restrictions",
	Metadata = "Metadata",
}

export enum ValidationTargetField {
	Cardinality = "cardinality",
	FixedValue = "fixedWert",
	DefaultValue = "defaultWert",
	ExampleValue = "beispielWert",
	ExampleValueMessage = "beispielWertNachricht",
	RestrictionName = "restrictionName",
	RecursionLimit = "rekursionLimit",
	Standard = "standard",
	NameShort = "nameKurz",
	NameLong = "nameLang",
	Description = "beschreibung",
	Version = "version",
	Kennung = "kennung",
	DraftStatus = "statusFassung",
	Publisher = "herausgeber",
	Property = "eigenschaft",
	CodelistValues = "werte",
	// eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
	CodelistIdentifier = "kennung",
}

export type DefaultAutoFixValue = string;
export type AlternateAutoFixValue = {
	[ValidationTargetField.ExampleValueMessage]: string[];
	[ValidationTargetField.CodelistValues]: string[];
};
export type DefaultAutoFixTargetFields = Exclude<
	ValidationTargetField,
	keyof AlternateAutoFixValue
>;

export enum ValidationStatus {
	Idle = "idle",
	Running = "running",
	Completed = "completed",
}

export const SkipAutoFixSymbol = Symbol("skipAutoFix");
export type AutoFixValue<TargetField extends ValidationTargetField> =
	TargetField extends keyof AlternateAutoFixValue
		? AlternateAutoFixValue[TargetField]
		: DefaultAutoFixValue;

export type FixerFn<TargetField extends ValidationTargetField> = (
	fieldName: string,
	context: { skip: typeof SkipAutoFixSymbol },
) => AutoFixValue<TargetField> | typeof SkipAutoFixSymbol;
export type AutoFixFn<TargetField extends ValidationTargetField> = (
	invalidValue: AutoFixValue<TargetField>,
) => FixerFn<TargetField>;

export type ValidationResultBase<TargetField extends ValidationTargetField> = {
	id: string;
	severity: Severity;
	message: string;
	type: ValidationResultGroup;
	targetField: TargetField;
	target?: string[];
	elementName: string;
	elementNamePath: string[];
	ruleId: string;
	autoFix?: AutoFixFn<TargetField>;
	autoFixDescription?: string;
};
export type RemoveValdationGroupType<Result> = Omit<Result, "type">;
type ExtendValidationResult<
	ResultGroup extends ValidationResultGroup,
	Result,
	TargetField extends ValidationTargetField,
	BaseResult = ValidationResultBase<TargetField>,
> = RemoveValdationGroupType<BaseResult> & Result & { type: ResultGroup };

// Validation result definitions
export type ProfileValidationResult<TargetField extends ValidationTargetField> =
	ExtendValidationResult<
		ValidationResultGroup.MessageProfiles,
		{
			nodeId: LiteId;
			path: LiteId[];
			nodeName: string;
		},
		TargetField
	>;

export type MetadataValidationResult<
	TargetField extends ValidationTargetField,
> = RemoveValdationGroupType<ValidationResultBase<TargetField>> & {
	type: ValidationResultGroup.Metadata;
};

export type RestrictionProfileValidationResult<
	TargetField extends ValidationTargetField,
> = ExtendValidationResult<
	ValidationResultGroup.RestrictionProfiles,
	{
		datatypeId: LiteId;
		restrictionId: RestrictionId;
		restrictionName: string;
	},
	TargetField,
	ProfileValidationResult<TargetField>
>;

export type RestrictionValidationResult<
	TargetField extends ValidationTargetField,
> = ExtendValidationResult<
	ValidationResultGroup.Restrictions,
	{
		datatypeId: LiteId;
		restrictionId: RestrictionId;
		restrictionName: string;
	},
	TargetField
>;

// Validation context definitions
export interface ProfileValidationContext {
	modell: LiteModellContainer;
	profiles: StateProfileMap;
	profile: ImmutableMap<Partial<MessageProfileValues>>;
	datatypes: DatatypeMap;
	standardNode: TreeNodeType;
	id: LiteId;
	path: LiteId[];
	codelist: CodelistStateResult;
}

export interface RestrictionProfileValidationContext {
	restrictionId: RestrictionId;
	restrictionName: string;
	profiles: StateProfileMap;
}

export interface RestrictionValidationContext {
	datatypeId: LiteId;
	restrictionId: RestrictionId;
	standardDatatype: TreeNodeType;
	restriction: ImmutableMap<RestrictionProfileValues>;
}

export interface MetadataValidationContext {
	metadata: MetadataMap;
}

// Validation rule definitions
export interface RuleBase<
	Context,
	TargetKeys,
	TargetField extends ValidationTargetField,
> {
	/**
	 * The target field is used to identify the input field in the gui, of which
	 * the value is checked by this rule. It is later used to display a helpful
	 * message in the validation dialog
	 */
	targetField: TargetField;
	/** A unique id that identifies the rule */
	id: string;
	/**
	 * The severity of the rule (`Error`, `Warning`, `Info`). When a rule with
	 * severity `Error` is present, the project cannot be saved.
	 * Validators should define a default severity, which is why the field is
	 * optional
	 */
	severity?: Severity;
	/**
	 * The function used to actually validate the input. Note that it is only
	 * responsible to check its specific rule. Should the validation not be
	 * possible because some other rule is violated, it should return `true`
	 * early, to skip this rule (for example a rule, that is supposed to check
	 * whether the input does not contain certain forbidden characters, it cannot
	 * run when the input is empty. In that case, the rule should be skiped,
	 * since, either empty values are allowed, or must be checked by another rule)
	 */
	isValid(context: Context): boolean;
	/**
	 * The error message, that is displayed when the rule fails. Can be a string
	 * or a function, returning the message
	 */
	message: string | ((context: Context) => string);
	/**
	 * Optionally supply a list of keys in the object type of the object that is
	 * being validated. This can be used by the validator to skip rules, that
	 * could not possibly match (for example a rule, checking whether a `name`
	 * value is valid cannot do anything if there is no `name` property on the
	 * target object)
	 */
	target?: TargetKeys[];
	/**
	 * Define a function to auto-fix a value that violates the validation rule
	 * If the rule applies to multiple fields, the fixer function will be called
	 * multiple times - once with each fieldname. The fixer function may return
	 * null to indicate that the value could not be fixed for the given fieldname
	 */
	autoFix?: AutoFixFn<TargetField>;
	autoFixDescription?: string;
}

export type ProfileRule<
	TargetField extends ValidationTargetField = ValidationTargetField,
> = RuleBase<ProfileValidationContext, keyof MessageProfileValues, TargetField>;
export type RestrictionRule<
	TargetField extends ValidationTargetField = ValidationTargetField,
> = RuleBase<
	RestrictionValidationContext,
	keyof RestrictionProfileValues,
	TargetField
>;
export type MetadataRule<
	TargetField extends ValidationTargetField = ValidationTargetField,
> = RuleBase<
	MetadataValidationContext,
	keyof ProfilingMetadataValues,
	TargetField
>;

/**
 * We use a genarator here, so validation execution can be paused and aborted
 * in case we need to run the next validation already or if we're taking too
 * long and we want to run the validation in multiple chunks.
 * After each validation the `Validator` is expected to yield a result if the
 * validation failed or `null` when the validation was successful. It's
 * important to yield after *every* validation, be it valid or invalid, so we
 * have more control over when to halt the validation process
 */
export type Validator<
	ValidationResultT extends ValidationResultBase<ValidationTargetField>,
> = SimpleAsyncGenerator<ValidationResultT | null>;

export type AnyResultMap = {
	[K in string]: ValidationResultBase<ValidationTargetField>;
};
export type ValidatorExecResult<ResultMap extends AnyResultMap> = {
	[K in keyof ResultMap]: ResultMap[K][];
};
export type ValidatorMap<ResultMap extends AnyResultMap> = {
	[K in keyof ResultMap]: Validator<ResultMap[K]>;
};
export interface UseValidatorResult<ResultMap extends AnyResultMap> {
	result: ValidatorExecResult<ResultMap>;
	status: ValidationStatus;
}

export type ResultMapping<
	TargetField extends ValidationTargetField = ValidationTargetField,
> = {
	[ValidationResultGroup.MessageProfiles]: ProfileValidationResult<TargetField>;
	[ValidationResultGroup.RestrictionProfiles]: RestrictionProfileValidationResult<TargetField>;
	[ValidationResultGroup.Restrictions]: RestrictionValidationResult<TargetField>;
	[ValidationResultGroup.Metadata]: MetadataValidationResult<TargetField>;
};
export type ResultType = ResultMapping[keyof ResultMapping];
export type ValidationRunResult = ValidatorExecResult<ResultMapping>;
export type MainValidators = ValidatorMap<ResultMapping>;
export type ValidationContextType = UseValidatorResult<ResultMapping>;

/** Used to extract specific validation results */
export type LocatorMapping<TargetField extends ValidationTargetField> = {
	[ValidationResultGroup.MessageProfiles]: Pick<
		ProfileValidationResult<TargetField>,
		"nodeId"
	>;
	[ValidationResultGroup.RestrictionProfiles]: Pick<
		RestrictionProfileValidationResult<TargetField>,
		"nodeId" | "datatypeId" | "restrictionId"
	>;
	[ValidationResultGroup.Restrictions]: Pick<
		RestrictionValidationResult<TargetField>,
		"datatypeId" | "restrictionId"
	>;
	[ValidationResultGroup.Metadata]: Pick<
		MetadataValidationResult<TargetField>,
		never
	>;
};

export type Locator<
	ResultGroup extends ValidationResultGroup,
	TargetField extends ValidationTargetField = ValidationTargetField,
> = Partial<
	LocatorMapping<TargetField>[ResultGroup] & { targetField: TargetField }
>;
