/* eslint-disable react/jsx-props-no-spreading */
import type { RefObject } from "react";
import { useContext, useMemo } from "react";
import classNames from "classnames";
import PopoverMenuContext from "./PopoverMenuContext";
import { callOrReturn } from "../../../util/func";
import type { PopoverMenuContentProps } from "./types";
import "./PopoverMenuContent.scss";

const PopoverMenuContent = ({
	as: asProp = "div",
	activeClassName,
	className,
	children,
	...props
}: PopoverMenuContentProps): JSX.Element => {
	const { buttonId, contentRef, contentId, isOpen, position } =
		useContext(PopoverMenuContext);
	const renderProps = useMemo(() => ({ isOpen }), [isOpen]);

	// We don't know what html tag this will be, but since we only ise standard
	// attributes and `ref` we don't need extra type safety. Unsupported
	// attributes will be filtered by react or warned about in the console.
	// There is unfortunately no good react type we could use here instead to
	// make this more general...
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const As = asProp as any;
	return (
		<As
			className={classNames(
				"xui-popover-menu-content",
				`xui-popover-menu-content--position-${position.replace(" ", "-")}`,
				className,
				isOpen && "xui-popover-menu-content--open",
				isOpen && activeClassName,
			)}
			id={contentId}
			tabIndex={-1}
			aria-labelledby={buttonId}
			data-testid="popover-menu-content"
			data-is-open={isOpen}
			{...props}
			ref={contentRef as RefObject<HTMLDivElement>}
		>
			{callOrReturn(children, renderProps)}
		</As>
	);
};

export default PopoverMenuContent;
