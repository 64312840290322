import type { AnyEventObject } from "xstate";
import { assign, fromCallback, setup } from "xstate";
import {
	assertEvent,
	createUnimplementedCallbackHandler,
} from "../../util/machines";
import { selectHasActiveProject } from "../EditorState/selectors";
import type { EditorStateContainer } from "../EditorState/types";

const MACHINE_ID = "savePoint";

const savePointMachine = setup({
	types: {
		// typegen currently not supported in v5
		// eslint-disable-next-line @typescript-eslint/consistent-type-imports
		// typegen: {}, // as import("./savePoint.machine.typegen").Typegen0,
		context: {} as {
			autoSavePoint: null | string;
			manualSavePoint: null | string;
			pathname: string;
			prevPathname: string;
			stateContainer: null | EditorStateContainer;
			callbacks: {
				requestAutoSave: () => void;
			};
		},
		events: {} as
			| { type: "SET_SAVE_HANDLER"; requestAutoSave: () => void }
			| { type: "SET_STATE_CONTAINER"; stateContainer: EditorStateContainer }
			| { type: "REPORT_AUTO_SAVE" }
			| { type: "REPORT_MANUAL_SAVE" }
			| { type: "REPORT_STATE_UPDATE" }
			| { type: "REPORT_PATH_CHANGE"; pathname: string },
		input: {} as {
			pathname: string;
			prevPathname: string;
		},
	},
	actions: {
		writeSaveHandler: assign({
			callbacks: ({ event }) => {
				assertEvent(event, "SET_SAVE_HANDLER");
				return {
					requestAutoSave: event.requestAutoSave,
				};
			},
		}),
		writeStateContainer: assign({
			stateContainer: ({ event }) => {
				assertEvent(event, "SET_STATE_CONTAINER");
				return event.stateContainer;
			},
		}),
		initSavePoints: assign({
			autoSavePoint: ({ event }) => {
				assertEvent(event, "SET_STATE_CONTAINER");
				return event.stateContainer.getStateId();
			},
			manualSavePoint: ({ event }) => {
				assertEvent(event, "SET_STATE_CONTAINER");
				return event.stateContainer.getStateId();
			},
		}),
		clearSavePoints: assign({ autoSavePoint: null, manualSavePoint: null }),
		writePathChange: assign({
			prevPathname: ({ context }) => context.pathname,
			pathname: ({ event }) => {
				assertEvent(event, "REPORT_PATH_CHANGE");
				return event.pathname;
			},
		}),
		autoSave: ({ context }) => context.callbacks.requestAutoSave(),
	},
	guards: {
		hasAutoStateIdChanged: ({ context }) =>
			context.autoSavePoint !== context.stateContainer?.getStateId(),
		hasManualStateIdChanged: ({ context }) =>
			context.manualSavePoint !== context.stateContainer?.getStateId(),
		shouldActivate: ({ context, event }) => {
			// Has the state container reference changed, aka. is there a new project
			assertEvent(event, "SET_STATE_CONTAINER");
			return (
				context.stateContainer !== event.stateContainer &&
				// Is the project active and not empty
				selectHasActiveProject()(event.stateContainer.getState().value)
			);
		},
		shouldDeactivate: ({ event }) => {
			assertEvent(event, "SET_STATE_CONTAINER");
			return !selectHasActiveProject()(event.stateContainer.getState().value);
		},
		hasPathnameChanged: ({ context, event }) => {
			assertEvent(event, "REPORT_PATH_CHANGE");
			return context.pathname !== event.pathname;
		},
	},
	actors: {
		watchStateChanges: fromCallback(
			({
				input,
				sendBack,
			}: {
				input: { stateContainer: EditorStateContainer };
				sendBack: (event: AnyEventObject) => void;
			}) => {
				const unsubscribe = input.stateContainer?.listen(() => {
					sendBack({ type: "REPORT_STATE_UPDATE" });
				});
				return () => unsubscribe();
			},
		),
	},
}).createMachine({
	id: MACHINE_ID,
	initial: "Inactive",
	context: ({ input }) => ({
		...input,
		autoSavePoint: null,
		manualSavePoint: null,
		stateContainer: null,
		callbacks: {
			requestAutoSave: createUnimplementedCallbackHandler(
				MACHINE_ID,
				"requestAutoSave",
			),
		},
	}),
	on: {
		SET_SAVE_HANDLER: { actions: "writeSaveHandler" },
		SET_STATE_CONTAINER: {
			target: ".Active",
			guard: "shouldActivate",
			actions: ["writeStateContainer", "initSavePoints"],
		},
		REPORT_PATH_CHANGE: {
			guard: "hasPathnameChanged",
			actions: "writePathChange",
		},
	},
	states: {
		Inactive: {
			on: {
				SET_STATE_CONTAINER: {
					target: "Active",
					guard: "shouldActivate",
					actions: ["writeStateContainer", "initSavePoints"],
				},
			},
		},
		Active: {
			invoke: [
				{
					src: "watchStateChanges",
					input: ({ event }) => {
						assertEvent(event, "SET_STATE_CONTAINER");
						return {
							stateContainer: event.stateContainer,
						};
					},
				},
			],
			type: "parallel",
			on: {
				SET_STATE_CONTAINER: {
					target: "Inactive",
					guard: "shouldDeactivate",
					actions: ["writeStateContainer", "clearSavePoints"],
				},
			},
			states: {
				Auto: {
					initial: "Saved",
					states: {
						Saved: {
							on: {
								REPORT_STATE_UPDATE: {
									guard: "hasAutoStateIdChanged",
									target: "Unsaved",
								},
							},
						},
						Unsaved: {
							on: {
								REPORT_AUTO_SAVE: { target: "Saved" },
								REPORT_PATH_CHANGE: {
									guard: "hasPathnameChanged",
									actions: "autoSave",
								},
							},
						},
					},
				},
				Manual: {
					initial: "Saved",
					states: {
						Saved: {
							on: {
								REPORT_STATE_UPDATE: {
									guard: "hasManualStateIdChanged",
									target: "Unsaved",
								},
							},
						},
						Unsaved: {
							on: {
								REPORT_MANUAL_SAVE: { target: "Saved" },
							},
						},
					},
				},
			},
		},
	},
});
export default savePointMachine;
