import type { ReactNode } from "react";
import { createContext, useContext, useMemo } from "react";
import type {
	LiteId,
	LiteNode,
	LiteNodeType,
	ProjektId,
} from "../../../../AppActor/actors/modellierungModel/schemas";
import { useIsProjektDisplayOnly } from "../../../../AppActor/actors/project/hooks";
import type { DisplayRendererProps, EditRendererProps } from "../types";
import {
	getLiteNodeKind,
	type LiteKind,
} from "../../../../AppActor/actors/modellierungModel/LiteKind";

type NodeByType<TNodeType extends LiteNodeType> = Extract<
	LiteNode,
	{ liteType: TNodeType }
>;

function useRendererMode() {
	const isDisplayOnly = useIsProjektDisplayOnly();
	return isDisplayOnly ? "display" : "edit";
}

const RendererContext = createContext<{
	activeNode: LiteNode;
	activePath: LiteId[];
	isReadOnly: boolean;
	projektId: ProjektId;
}>(null as never);

export function RendererProvider({
	projektId,
	activeNode,
	activePath,
	isReadOnly,
	children,
}: {
	projektId: ProjektId;
	activeNode: LiteNode;
	activePath: LiteId[];
	isReadOnly: boolean;
	children: ReactNode;
}): JSX.Element {
	const ctx = useMemo(
		() => ({ activeNode, activePath, isReadOnly, projektId }),
		[activeNode, activePath, isReadOnly, projektId],
	);

	return (
		<RendererContext.Provider value={ctx}>{children}</RendererContext.Provider>
	);
}

function toList<T>(listOrItem: T | T[]): T[] {
	return Array.isArray(listOrItem) ? listOrItem : [listOrItem];
}

export default function Renderer<
	TNodeType extends LiteNodeType = LiteNodeType,
>({
	type,
	kind,
	...props
}: {
	display: (props: DisplayRendererProps<NodeByType<TNodeType>>) => JSX.Element;
	edit: (props: EditRendererProps<NodeByType<TNodeType>>) => JSX.Element;
	type?: TNodeType | TNodeType[];
	kind?: LiteKind | LiteKind[];
}) {
	const { activeNode, activePath, isReadOnly, projektId } =
		useContext(RendererContext);
	const rendererMode = useRendererMode();
	const renderProps = useMemo(
		() => ({
			edit: { activeNode, activePath, isReadOnly, projektId },
			display: { activeNode, activePath },
		}),
		[activeNode, activePath, isReadOnly, projektId],
	);
	if (type && !toList<LiteNodeType>(type).includes(activeNode.liteType)) {
		return <></>;
	}
	if (kind && !toList(kind).includes(getLiteNodeKind(activeNode))) {
		return <></>;
	}
	const Component = props[rendererMode];
	return (
		<Component
			{...(renderProps[rendererMode] as EditRendererProps<
				NodeByType<TNodeType>
			>)}
		/>
	);
}
