import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import type ValueOf from "../types/ValueOf";
import { objectEntries } from "../util/object";
import type { RootState } from "./store";
import type { LiteDatatypeKind } from "../components/AppActor/actors/modellierungModel/types";

export type DatatypeFilter = "NONE" | LiteDatatypeKind;

export enum UiFilterGroup {
	MessageStructureTree = "MessageStructureTree",
	DatatypeList = "DatatypeList",
	RestrictionTree = "RestrictionTree",
	StandardTree = "StandardTree",
	DocumentationList = "DocumentationList",
	StandardDatatypeList = "StandardDatatypeList",
	StandardDatatypeSubTree = "StandardDatatypeSubTree",
}

export interface UiState {
	filter: {
		[UiFilterGroup.MessageStructureTree]: {
			query: string;
			showProfiledOnly: boolean;
		};
		[UiFilterGroup.DatatypeList]: {
			query: string;
			showProfiledOnly: boolean;
			nodeType: DatatypeFilter;
		};
		[UiFilterGroup.RestrictionTree]: {
			query: string;
			showProfiledOnly: boolean;
		};
		[UiFilterGroup.StandardTree]: { query: string };
		[UiFilterGroup.DocumentationList]: {
			query: string;
			showHiddenChapters: boolean;
		};
		[UiFilterGroup.StandardDatatypeList]: {
			query: string;
			nodeType: DatatypeFilter;
		};
		[UiFilterGroup.StandardDatatypeSubTree]: { query: string };
	};
}

export type UiFilter<Group extends UiFilterGroup> = UiState["filter"][Group];
export type UiFilterValue<
	Group extends UiFilterGroup,
	Key extends keyof UiFilter<Group>,
> = UiFilter<Group>[Key];

// Get all filter configuration objects, that extend the SubShape
type ExtractShapes<SubShape> = Extract<UiFilter<UiFilterGroup>, SubShape>;
// Map all `UiFilter`s that are asignable to the SubShape to their `Group`
type ExtractGroupsToShape<SubShape> = {
	[Group in UiFilterGroup]: UiFilter<Group> extends ExtractShapes<SubShape>
		? Group
		: never;
};
/**
 * Get the specific `UiFilterGroup`s that support certain filter configurations
 * @example
 * ```ts
 * type GroupsWithProfiles = ExtractFilterGroup<{ showProfiledOnly: boolean }>;
 * // -> MessageStructureTree | DatatypeList | RestrictionTree
 * type GroupsWithChapters = ExtractFilterGroup<{ showHiddenChapters: unknown }>;
 * // -> DocumentationList
 * type GroupsWithQuery = ExtractFilterGroup<{ query: string }>;
 * // -> All the groups
 * ```
 */
export type ExtractFilterGroup<SubShape> =
	// `ValueOf` automatically gets rid of all the `never`s in the extraction
	ValueOf<ExtractGroupsToShape<SubShape>>;

export const initialUiFilterState: UiState = {
	filter: {
		[UiFilterGroup.MessageStructureTree]: {
			query: "",
			showProfiledOnly: false,
		},
		[UiFilterGroup.DatatypeList]: {
			query: "",
			showProfiledOnly: false,
			nodeType: "NONE",
		},
		[UiFilterGroup.RestrictionTree]: { query: "", showProfiledOnly: false },
		[UiFilterGroup.StandardTree]: { query: "" },
		[UiFilterGroup.DocumentationList]: { query: "", showHiddenChapters: false },
		[UiFilterGroup.StandardDatatypeList]: { query: "", nodeType: "NONE" },
		[UiFilterGroup.StandardDatatypeSubTree]: { query: "" },
	},
};

const uiSlice = createSlice({
	name: "ui",
	initialState: initialUiFilterState,
	reducers: {
		setFilter<Group extends UiFilterGroup>(
			state: UiState,
			action: PayloadAction<{
				group: Group;
				value: Partial<UiFilter<Group>>;
			}>,
		) {
			const { group, value } = action.payload;
			for (const [key, filterValue] of objectEntries(value)) {
				// eslint-disable-next-line no-param-reassign
				state.filter[group][key] = filterValue as never;
			}
		},
	},
});

export const setFilter = <Group extends UiFilterGroup>(
	group: Group,
	value: Partial<UiFilter<Group>>,
) => uiSlice.actions.setFilter({ group, value });

export const subSelectFilter =
	<Group extends UiFilterGroup>(group: Group) =>
	(uiState: UiState): UiFilter<Group> =>
		uiState.filter[group];

export const selectFilter =
	<Group extends UiFilterGroup>(group: Group) =>
	(state: RootState): UiFilter<Group> =>
		subSelectFilter(group)(state.ui);
export const selectFilterValue =
	<Group extends UiFilterGroup, Key extends keyof UiFilter<Group>>(
		group: Group,
		key: Key,
	) =>
	(state: RootState): UiFilterValue<Group, Key> =>
		selectFilter(group)(state)[key];

export default uiSlice.reducer;
