import { CircularProgress, Button } from "@mui/material";
import { RequestStatus } from "../../../Api";
import useValidationResults from "../../../ValidationDisplayLite/useValidationResults";

const ExportButton = ({
	status,
	onClick,
}: {
	status: RequestStatus;
	onClick: () => void;
}): JSX.Element => {
	const validationResults = useValidationResults();
	const isExportDisabled = validationResults.length > 0;

	return (
		<>
			{status === RequestStatus.Loading ? (
				<CircularProgress size="2em" data-testid="export-spinner" />
			) : (
				<Button
					disabled={isExportDisabled}
					data-testid="export-project"
					onClick={onClick}
				>
					Export
				</Button>
			)}
		</>
	);
};

export default ExportButton;
