import { Checkbox } from "@mui/material";
import { useGridApiContext } from "@mui/x-data-grid";
import type { KeyboardEventHandler } from "react";
import { useState } from "react";
import type { PropsOf } from "../../../../../../util/types";
import ConfirmDialog from "../../../../../ConfirmDialog";
import {
	useApplyPatch,
	useEditorPatchCreators,
	useStateSelector,
} from "../../../../../EditorState";
import {
	selectRefItemSelectabilityStatus,
	selectState,
} from "../../../../../EditorState/selectors";
import { SelectablilityCode } from "../../../../../EditorState/types";
import useActiveRestriction from "../../../../useActiveRestriction";
import type { SpecificRefItem } from "./types";
import SkipLink from "../../../../../SkipLink";
import { generateCellLinks } from "../../../../../SelectionTableProvider/helpers";
import { getBasePath } from "./helpers";
import "./CheckAllCheckbox.scss";

interface DialogState {
	isOpen: boolean;
	codelistValueRows: SpecificRefItem[];
}

const INITIAL_STATE: DialogState = { isOpen: false, codelistValueRows: [] };
const ACTIONS_ID = "check-all-checkbox-dialog-actions";

function CheckAllCheckbox({
	checked,
	...props
}: PropsOf<typeof Checkbox>): JSX.Element {
	const [dialogState, setDialogState] = useState(INITIAL_STATE);
	const applyPatch = useApplyPatch();
	const { changeRestrictionSelection } = useEditorPatchCreators();
	const apiRef = useGridApiContext();
	const state = useStateSelector(selectState());
	const activeRestriction = useActiveRestriction();
	const restrictionId = activeRestriction?.get("id");

	const getCodelistValueRows = () => {
		const allRows = [
			...apiRef.current.getRowModels().values(),
		] as SpecificRefItem[];
		return allRows.filter((item) => {
			const status = selectRefItemSelectabilityStatus(
				item as SpecificRefItem,
				activeRestriction,
			)(state);
			return status.code === SelectablilityCode.CodelistValues;
		});
	};

	const handleAccept = () => {
		if (!restrictionId) return;
		const allIds = apiRef.current.getAllRowIds();
		const selectedRows = apiRef.current.getSelectedRows();
		const selection = checked
			? // Uncheck all...
			  [...selectedRows.keys()].filter(
					// Keep the checked rows, that are not selectable
					(id) => !apiRef.current.isRowSelectable(id),
			  )
			: // Check all...
			  allIds.filter((id) => apiRef.current.isRowSelectable(id));
		applyPatch(
			changeRestrictionSelection({
				restrictionId,
				selection: selection as string[],
			}),
		);
		setDialogState((prevState) => ({ ...prevState, isOpen: false }));
	};

	const handleDismiss = () => {
		setDialogState((prevState) => ({ ...prevState, isOpen: false }));
	};

	const handleChange = () => {
		if (!restrictionId) return;
		const codelistValueRows = getCodelistValueRows();
		if (codelistValueRows.length > 0) {
			setDialogState({ isOpen: true, codelistValueRows });
		} else {
			handleAccept();
		}
	};

	const handleKeyDown: KeyboardEventHandler<HTMLButtonElement> = (e) => {
		if (["Enter", " "].includes(e.key)) {
			handleChange();
		}
	};

	return (
		<>
			<Checkbox
				{...props}
				checked={checked}
				onChange={handleChange}
				onKeyDown={handleKeyDown}
			/>
			<ConfirmDialog
				isOpen={dialogState.isOpen}
				onAccept={handleAccept}
				onDismiss={handleDismiss}
				title="Warnung: Löschung der Profilierung"
				componentsProps={{
					actionsProps: { id: ACTIONS_ID, tabIndex: -1 },
				}}
				description={
					<>
						Elemente in der Tabelle enthalten Profilierungen. Wenn eine
						Einschränkung mit diesem Datentyp vorgenommen wird, wird die
						Profilieung gelöscht.
					</>
				}
			>
				<SkipLink label="Auflistung überspringen" target={`#${ACTIONS_ID}`} />
				<h3>Die Profilierungen folgender Elemente werden gelöscht</h3>
				<ul className="check-all-checkbox__list">
					{dialogState.codelistValueRows.map((item) => (
						<li key={item.id} className="check-all-checkbox__list-item">
							{generateCellLinks(item, {
								renderLeadingSlash: false,
								basePath: getBasePath,
							})}
						</li>
					))}
				</ul>
			</ConfirmDialog>
		</>
	);
}

export default CheckAllCheckbox;
