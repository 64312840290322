/* eslint-disable react/jsx-props-no-spreading */
import PropertiesSelect from "./PropertiesSelect";
import type { FieldRendererComponentProps } from "../../types";
import { useHtmlId } from "../../../../../../hooks";
import { useProfilingValidationResult } from "../../../../../Profiling/ProfilingValidationDisplay";
import { ValidationTargetField } from "../../../../../Validation/types";
import ValidationFormatter from "../../../../../Validation/ValidationFormatter";
import "./PropertiesRenderer.scss";

const PropertiesRenderer = (
	props: FieldRendererComponentProps,
): JSX.Element => {
	const validationResultId = useHtmlId();
	const { activeNode, activePath } = props;

	const validationResults = useProfilingValidationResult({
		nodeId: activeNode.id,
		targetField: ValidationTargetField.Property,
	});

	return (
		<div className="attributes-renderer">
			<PropertiesSelect activePath={activePath} errorId={validationResultId} />
			{!!validationResults.length && (
				<div
					className="attributes-renderer__error-message"
					id={validationResultId}
				>
					<ValidationFormatter results={validationResults} />
				</div>
			)}
		</div>
	);
};

export default PropertiesRenderer;
