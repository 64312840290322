import type {
	LiteId,
	LiteNode,
	ProjektId,
} from "../../../../AppActor/actors/modellierungModel/schemas";
import { LiteNodeType } from "../../../../AppActor/actors/modellierungModel/schemas";
import { DatatypeDisplay, DatatypeEdit } from "../eigenschaft/Datatype";
import {
	CommonMetadataDisplay,
	CommonMetadataEdit,
} from "../shared/CommonMetadata";
import { SchemaPaketDisplay, SchemaPaketEdit } from "../paket/SchemaPaket";
import {
	KardinalitaetDisplay,
	KardinalitaetEdit,
} from "../eigenschaft/Kardinalitaet";
import Renderer, { RendererProvider } from "../Renderer";
import { CodelisteDisplay, CodelisteEdit } from "../eigenschaft/Codeliste";
import { DatentypDisplay, DatentypEdit } from "../baustein/Datentyp";
import {
	PresetValuesDisplay,
	PresetValuesEdit,
} from "../eigenschaft/PresetValues";
import { LiteKind } from "../../../../AppActor/actors/modellierungModel/LiteKind";
import {
	GruppierungsartDisplay,
	GruppierungsartEdit,
} from "../eigenschaft/Gruppierungsart";

export default function DetailsRenderer({
	activeNode,
	activePath,
	isReadOnly,
	projektId,
}: {
	activeNode: LiteNode;
	activePath: LiteId[];
	isReadOnly: boolean;
	projektId: ProjektId;
}): JSX.Element {
	return (
		<RendererProvider
			activeNode={activeNode}
			activePath={activePath}
			isReadOnly={isReadOnly}
			projektId={projektId}
		>
			<div
				data-testid="lite-node-details"
				data-project-id={projektId}
				data-node-id={activeNode.id}
				data-lite-type={activeNode.liteType}
				data-node-name={activeNode.name}
				data-readonly={isReadOnly}
			>
				<Renderer
					type={LiteNodeType.Eigenschaft}
					display={DatatypeDisplay}
					edit={DatatypeEdit}
				/>
				<Renderer
					type={LiteNodeType.Eigenschaft}
					display={GruppierungsartDisplay}
					edit={GruppierungsartEdit}
				/>
				<Renderer display={CommonMetadataDisplay} edit={CommonMetadataEdit} />
				<Renderer
					type={LiteNodeType.Paket}
					kind={LiteKind.SchemaPaket}
					display={SchemaPaketDisplay}
					edit={SchemaPaketEdit}
				/>
				<Renderer
					type={LiteNodeType.Eigenschaft}
					display={KardinalitaetDisplay}
					edit={KardinalitaetEdit}
				/>
				<Renderer
					type={LiteNodeType.Eigenschaft}
					display={PresetValuesDisplay}
					edit={PresetValuesEdit}
				/>
				<Renderer
					type={LiteNodeType.Eigenschaft}
					display={CodelisteDisplay}
					edit={CodelisteEdit}
				/>
				<Renderer
					type={LiteNodeType.Baustein}
					kind={[LiteKind.Datentyp, LiteKind.CodeDatentyp]}
					display={DatentypDisplay}
					edit={DatentypEdit}
				/>
			</div>
		</RendererProvider>
	);
}
