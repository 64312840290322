import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import downloadFile from "js-file-download";
import type {
	Middleware,
	MiddlewareAPI,
	PayloadAction,
} from "@reduxjs/toolkit";
import { isRejectedWithValue } from "@reduxjs/toolkit";
import type {
	GenerierteNachrichtId,
	MessageApiResponse,
	MessageType,
} from "../components/MessageView/MessageOverview/types";
import type { GenerationConfiguration } from "./configuration/types";
import { toRequestError } from "../util/requestFormatters";
import { RequestMethods } from "../components/Api";
import { createIdCounter } from "../util/misc";
import type { ValidationBase } from "./messagesSlice";
import { setRejectedRequest } from "./messagesSlice";
import type { Markup } from "../components/MessageView/Viewer/types";

export enum CacheKeys {
	MessageUpdate = "message-update",
	MessageGeneration = "message-generation",
	MessageDeletion = "message-deletion",
	MultipleMessageDeletion = "multiple-message-deletion",
	Refresh = "refresh-viewer",
}

function getFileName(headers: Headers, fallbackFileName: string): string {
	const contentDisposition = headers.get("content-disposition");
	const matchedGroup = contentDisposition?.match(/filename="(.+)"/);
	return matchedGroup?.[1] || fallbackFileName;
}

const createRefreshId = createIdCounter();
const refreshId = createRefreshId();

export const messagesApiSlice = createApi({
	reducerPath: "messageApi",
	baseQuery: fetchBaseQuery({ baseUrl: "/api/v1/viewer" }),
	tagTypes: ["Message"],
	endpoints: (build) => ({
		getMessages: build.query<MessageType[], void>({
			query: () => ({
				url: "",
				method: RequestMethods.Get,
			}),
			transformResponse: (response: MessageApiResponse) => response.nachrichten,
			transformErrorResponse: (response) => toRequestError(response),
			providesTags: ["Message"],
		}),
		getMarkup: build.query<Markup, { messageId: GenerierteNachrichtId }>({
			query: ({ messageId }) => ({
				url: `nachrichten/${messageId}/markup`,
				method: RequestMethods.Get,
				headers: {
					Accept: "application/json",
				},
			}),
			transformResponse: (response: Markup) => response,
		}),
		generateMessages: build.mutation<
			MessageType[],
			{ config: GenerationConfiguration; validationBase: ValidationBase }
		>({
			query: ({ config, validationBase }) => ({
				url: `nachrichten?validierungsBasis=${validationBase}`,
				body: { ...config, standard: config.standard?.kennung },
				method: RequestMethods.Post,
				headers: {
					Accept: "application/json",
				},
			}),
			transformResponse: (response: MessageApiResponse) => response.nachrichten,
			invalidatesTags: ["Message"],
		}),
		uploadMessage: build.mutation<
			MessageType[],
			{ data: FormData; validationBase: ValidationBase }
		>({
			query: ({ data, validationBase }) => ({
				url: `nachrichten?validierungsBasis=${validationBase}`,
				body: data,
				method: RequestMethods.Post,
			}),
			transformResponse: (response: MessageApiResponse) => response.nachrichten,
			invalidatesTags: ["Message"],
		}),
		updateMessage: build.mutation<void, { message: MessageType }>({
			query: ({ message }) => ({
				url: `nachrichten/${message.id}`,
				body: message,
				method: RequestMethods.Put,
			}),
			async onQueryStarted({ message }, { dispatch, queryFulfilled }) {
				const patchResult = dispatch(
					messagesApiSlice.util.updateQueryData(
						"getMessages",
						undefined,
						(draftMessages) => {
							const index = draftMessages.findIndex(
								(draftMessage) => draftMessage.id === message.id,
							);
							draftMessages.splice(index, 1, message);
						},
					),
				);
				try {
					await queryFulfilled;
				} catch {
					patchResult.undo();
				}
			},
		}),
		deleteMessage: build.mutation<void, { messageId: GenerierteNachrichtId }>({
			query: ({ messageId }) => ({
				url: `nachrichten/${messageId}`,
				method: RequestMethods.Delete,
			}),
			async onQueryStarted({ messageId }, { dispatch, queryFulfilled }) {
				const patchResult = dispatch(
					messagesApiSlice.util.updateQueryData(
						"getMessages",
						undefined,
						(draftMessages) => {
							return draftMessages.filter(
								(message) => message.id !== messageId,
							);
						},
					),
				);

				try {
					await queryFulfilled;
				} catch {
					patchResult.undo();
				}
			},
		}),
		deleteMultipleMessages: build.mutation<
			void,
			{ messageIds: GenerierteNachrichtId[] }
		>({
			query: ({ messageIds }) => ({
				url: "nachrichtenliste",
				body: messageIds,
				method: RequestMethods.Delete,
			}),
			async onQueryStarted({ messageIds }, { dispatch, queryFulfilled }) {
				const patchResult = dispatch(
					messagesApiSlice.util.updateQueryData(
						"getMessages",
						undefined,
						(draftMessages) => {
							return draftMessages.filter(
								(message) => !messageIds.includes(message.id),
							);
						},
					),
				);
				try {
					await queryFulfilled;
				} catch {
					patchResult.undo();
				}
			},
		}),
		downloadMultipleReports: build.query<
			Blob,
			{ messageIds: GenerierteNachrichtId[] }
		>({
			query: ({ messageIds }) => ({
				url: "nachrichtenliste/berichte",
				method: RequestMethods.Post,
				body: messageIds,
				cache: "no-cache",
				responseHandler: async (response) => {
					try {
						const blob = await response.blob();
						const { headers } = response;
						const fileName = getFileName(headers, "validierungsberichte.zip");
						downloadFile(blob, fileName, "application/zip");
						/**
						 * RTK Query expects response handlers to return an object with "serializable" data,
						 * but does not consider blobs serializable. Since we use the js-file-download library
						 * to handle the download and don't actually need to return anything, we just return
						 * an empty object.
						 */
						return { data: {} };
					} catch (error) {
						return { error };
					}
				},
			}),
		}),
		downloadMultipleMessages: build.query<
			Blob,
			{ messageIds: GenerierteNachrichtId[] }
		>({
			query: ({ messageIds }) => ({
				url: "nachrichtenliste",
				method: RequestMethods.Post,
				body: messageIds,
				cache: "no-cache",
				responseHandler: async (response) => {
					try {
						const blob = await response.blob();
						const { headers } = response;
						const fileName = getFileName(headers, "nachrichten.zip");
						downloadFile(blob, fileName, "application/zip");
						/**
						 * RTK Query expects response handlers to return an object with "serializable" data,
						 * but does not consider blobs serializable. Since we use the js-file-download library
						 * to handle the download and don't actually need to return anything, we just return
						 * an empty object.
						 */
						return { data: {} };
					} catch (error) {
						return { error };
					}
				},
			}),
		}),
		refreshViewer: build.mutation<void, { validierungsBasis: ValidationBase }>({
			query: ({ validierungsBasis }) => ({
				url: `?refresh=${refreshId}&validierungsBasis=${validierungsBasis}`,
				method: RequestMethods.Post,
			}),
			invalidatesTags: ["Message"],
		}),
	}),
});

export const messageApiErrorMiddleware: Middleware =
	(api: MiddlewareAPI) =>
	(next) =>
	(
		action: PayloadAction<
			{ error: string },
			"",
			{
				arg: { endpointName: string };
			}
		>,
	) => {
		const { dispatch } = api;
		if (isRejectedWithValue(action) && action.type.startsWith("messageApi")) {
			const { endpointName } = action.meta.arg;
			const { error } = action.payload;
			dispatch(setRejectedRequest({ endpointName, error: { message: error } }));
		}

		return next(action);
	};
export const {
	useGenerateMessagesMutation,
	useGetMessagesQuery,
	useUploadMessageMutation,
	useUpdateMessageMutation,
	useDeleteMessageMutation,
	useDeleteMultipleMessagesMutation,
	useLazyDownloadMultipleReportsQuery,
	useLazyDownloadMultipleMessagesQuery,
	useRefreshViewerMutation,
	useGetMarkupQuery,
} = messagesApiSlice;
