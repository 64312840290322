// TODO: Remove these types
import type { LiteNode } from "../AppActor/actors/modellierungModel/schemas";

export enum TreeType {
	Node = "NODE",
	Leaf = "LEAF",
}

/**
 * The type of a node.
 * These are defined in `source/backend/service/src/main/java/de/xoev/software/suite/standards/ElementTyp.java`
 */
export enum NodeType {
	// Group types
	Package = "PAKET",
	Group = "GRUPPE",
	// Meta types
	Metadata = "METADATEN",
	Message = "NACHRICHT",
	GlobalElement = "GLOBALES_ELEMENT",
	// Element types
	InformationElement = "INFORMATIONS_ELEMENT",
	ValueElement = "WERT_ELEMENT",
	CodeValue = "CODE_WERT",
	// Datatypes
	Complex = "KOMPLEXER_TYP",
	Simple = "SIMPLER_TYP",
	CodeList = "CODELISTE",
	// Misc
	AnyContent = "ANY_CONTENT",
}

export enum InfoSet {
	Node = "NODE",
	Details = "DETAILS",
	Parent = "PARENT",
	Full = "FULL",
}

export interface MinimalNodeSet {
	id: string;
	name: string;
	treeType: TreeType;
	nodeType: NodeType;
	infoSet: InfoSet;
}

export type TreeNodeType = LiteNode;
