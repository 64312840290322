import { useCallback, useState } from "react";
import type { LiteNode } from "../AppActor/actors/modellierungModel/schemas";
import {
	LiteBausteinType,
	isLiteBaustein,
} from "../AppActor/actors/modellierungModel/schemas";

const allFilters = [
	LiteBausteinType.Datentyp,
	LiteBausteinType.CodeDatentyp,
	LiteBausteinType.Nachricht,
	LiteBausteinType.GlobaleEigenschaft,
];

export function useTreeFilters() {
	const [activeFilters, setActiveFilters] =
		useState<LiteBausteinType[]>(allFilters);

	const toggleFilter = useCallback(
		(...nodeTypes: LiteBausteinType[]) => {
			nodeTypes.forEach((nodeType) => {
				const index = activeFilters.indexOf(nodeType);

				if (index === -1) {
					setActiveFilters([...activeFilters, nodeType]);
				} else {
					setActiveFilters([
						...activeFilters.slice(0, index),
						...activeFilters.slice(index + 1),
					]);
				}
			});
		},
		[activeFilters, setActiveFilters],
	);

	return { activeFilters, toggleFilter };
}

export default function filterTreeNodes<TNode extends LiteNode>(
	nodes: TNode[],
	filters: LiteBausteinType[] = allFilters,
) {
	return nodes.filter((node) => {
		if (isLiteBaustein(node) && allFilters.includes(node.typ)) {
			return filters.includes(node.typ);
		}

		return true;
	});
}
