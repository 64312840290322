import type { ReactNode } from "react";
import { forwardRef } from "react";
import type { ExtendProps } from "../../../util/types";
import InteractiveNode from "../../InteractiveNode";
import type { InteractiveNodeProps } from "../../InteractiveNode/InteractiveNode";

export type NodeProps = ExtendProps<
	InteractiveNodeProps,
	{
		children?: ReactNode;
		isDisabled?: boolean;
		isProfiled?: boolean;
		isActive?: boolean;
	}
>;

const Node = forwardRef<HTMLDivElement, NodeProps>(function Node(
	{ children, isDisabled, isProfiled, isActive, ...props },
	ref,
) {
	return (
		<InteractiveNode
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			ref={ref as any}
			isDisabled={isDisabled}
			isActive={isActive}
			isEmphasized={isProfiled}
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...props}
		>
			{children}
		</InteractiveNode>
	);
});

export default Node;
