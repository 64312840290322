import { List, Seq } from "immutable";
import createImmutableMap from "@xoev/immutable-map";
import type { ImmutableMap } from "@xoev/immutable-map";
import { createSelector as rawCreateSelector } from "@xoev/state-container";
import { memoizeOnce } from "../../util/memoization";
import type { Nullish } from "../../util/types";
import { toMutable } from "../../util/types";
import type { SpecificRefItem } from "../DatatypesView/DetailsView/RestrictionView/RestrictionEditView/RestrictionEditForm/types";
import { RefType } from "../DatatypesView/DetailsView/RestrictionView/RestrictionEditView/RestrictionEditForm/types";
import type { SavedProfileDocumentation } from "../../types/SavedProfileData";
import type { ChapterMap } from "../DocumentationView/GeneratedChapterProvider";
import type {
	EditorState,
	MessageProfileValues,
	ReferenceMapItem,
	RestrictionEntries,
	RestrictionValues,
	TransitiveRef,
	SelectablilityStatus,
	RestrictionProfileValues,
	StateProfileMap,
	ProfileRef,
	DatatypeReference,
	RestrictionId,
} from "./types";
import { SelectablilityCode } from "./types";
import { stringifyRefId } from "../DatatypesView/DetailsView/RestrictionView/RestrictionEditView/RestrictionEditForm/refIds";
import {
	subSelectDatatypeReference,
	subSelectContainsProperties,
	subSelectSelectabilityStatus,
	EMPTY_SELECTABILITY_STATUS,
	subSelectRestrictionProfileSeq,
} from "./subSelectors";
import {
	isChildId,
	isChildIdOrSelf,
	joinIdSegments,
	segmentizeId,
} from "../../util/xoev";
import type { StandardType } from "../Profiling/ProfilingHome/types";
import type {
	QName,
	QNamePath,
} from "../AppActor/actors/modellierungModel/schemas";
import { QNamePathSchema } from "../AppActor/actors/modellierungModel/schemas";
import { AssertionError } from "../../util/error";

function createSelector<SelectorReturn, Args extends unknown[]>(
	selector: Parameters<
		typeof rawCreateSelector<EditorState, SelectorReturn, Args>
	>[0],
) {
	return rawCreateSelector<EditorState, SelectorReturn, Args>(selector);
}

/** Select the entire editor state */
export const selectState = createSelector((state) => state);
/** Select the metadata of the currently loaded project */
export const selectMetadata = createSelector((state) => state.get("metadaten"));
/**
 * Select the standard of the currently loaded project
 * (for example `"urn:xoev-de:xdomea:kosit:standard:xdomea_3.0.0"`)
 */
export const selectStandard = createSelector((state) =>
	state.get("metadaten").get("standard"),
);
/** Check if the currently loaded project has the bear minimum of metadata */
export const selectHasActiveProject = createSelector(
	(state) => !!selectStandard()(state),
);
/** Select the stripped `kennung` and `nameKurz` of the currently loaded project */
export const selectProjectName = createSelector(
	(state, standardsData?: StandardType[]) => {
		if (!selectHasActiveProject()(state)) return null;
		const standardKennung = selectMetadata()(state).get("standard");
		const standard = standardsData?.find((s) => s.kennung === standardKennung);
		return `${standard ? `${standard.nameKurz}:` : ""}${selectMetadata()(
			state,
		).get("nameKurz")}`;
	},
);
/** Select the datatypes, associated with the standard */
export const selectDatatypes = createSelector((state) =>
	state.get("datentypen"),
);
/** Select addidtional property config */
export const selectProperties = createSelector(
	(state) =>
		state.get("konfiguration")?.get("profilierung")?.get("eigenschaften") ||
		null,
);
/** Select a single datatype by id */
export const selectDatatypeEntry = createSelector(
	(state, datatypeId: QName | undefined | null) =>
		datatypeId ? selectDatatypes()(state)?.get(datatypeId) : undefined,
);
/** Select the map of all profiles */
export const selectProfiles = createSelector((state) => state.get("profile"));
/** Get the keys of all profiles as a Seq */
export const selectProfileKeys = createSelector(
	(state): Seq.Indexed<QNamePath> => selectProfiles()(state).keySeq(),
);
/** Select a single profile from the profile map */
export const selectProfile = createSelector(
	(state, profileId: QNamePath | undefined) =>
		profileId ? state.get("profile").get(profileId) : undefined,
);
export const selectDatatypeReference = createSelector(
	(state, profileId: QNamePath | undefined) =>
		subSelectDatatypeReference(selectProfile(profileId)(state)),
);

export const selectRestriction = createSelector(
	(state, datatypeId: QName, restrictionId: RestrictionId) =>
		state
			.get("datentypen")
			.get(datatypeId)
			?.get("restrictions")
			?.get(restrictionId) || null,
);
export const selectRestrictionProfileMap = createSelector(
	(state, datatypeId: QName, restrictionId: RestrictionId) =>
		selectRestriction(datatypeId, restrictionId)(state)?.get("profile") || null,
);
export const selectRestrictionProfile = createSelector(
	(
		state,
		datatypeId: QName,
		restrictionId: RestrictionId,
		restrictionProfileId: QNamePath,
	) =>
		selectRestrictionProfileMap(
			datatypeId,
			restrictionId,
		)(state)?.get(restrictionProfileId) || null,
);
export const selectRestrictionDatatypeReference = createSelector(
	(
		state,
		datatypeId: QName,
		restrictionId: RestrictionId,
		restrictionProfileId: QNamePath,
	) =>
		subSelectDatatypeReference(
			selectRestrictionProfile(
				datatypeId,
				restrictionId,
				restrictionProfileId,
			)(state),
		),
);
export const selectReferencedDatatype = createSelector(
	(state, profileId: QNamePath | undefined) =>
		selectDatatypeReference(profileId)(state)?.get("datentyp"),
);
export const selectReferencedName = createSelector(
	(state, profileId: QNamePath | undefined) =>
		selectDatatypeReference(profileId)(state)?.get("name"),
);
export const selectRestrictions = createSelector(
	(state, datatypeId: Nullish<QName>) =>
		selectDatatypeEntry(datatypeId)(state)?.get("restrictions"),
);
export const selectRestrictionEntry = createSelector(
	(
		state,
		datatypeId: Nullish<QName>,
		restrictionId: RestrictionId | undefined,
	) =>
		restrictionId
			? selectRestrictions(datatypeId)(state)?.get(restrictionId) ?? null
			: null,
);
export const selectRestrictionName = createSelector(
	(state, restrictionId: RestrictionId | undefined) => {
		if (!restrictionId) return null;
		const matchingDt = selectDatatypes()(state).find((dt) =>
			dt.hasIn(["restrictions", restrictionId]),
		);
		if (!matchingDt) return null;
		return matchingDt.getIn(["restrictions", restrictionId, "name"]) ?? null;
	},
);
export const selectRestrictionProfiles = createSelector(
	(
		state,
		datatypeId: QName | undefined,
		restrictionId: RestrictionId | undefined,
	) =>
		selectRestrictionEntry(datatypeId, restrictionId)(state)?.get("profile") ??
		null,
);
/** Get the keys of all restriction profiles as a Seq */
export const selectRestrictionProfileKeys = createSelector(
	(
		state,
		datatypeId: QName | undefined,
		restrictionId: RestrictionId | undefined,
	): Seq.Indexed<QNamePath> =>
		selectRestrictionProfiles(datatypeId, restrictionId)(state)?.keySeq() ||
		Seq.Indexed(),
);
export const selectReferencedRestriction = createSelector(
	(state, profileId: QNamePath | undefined) => {
		const datatype = selectReferencedDatatype(profileId)(state);
		const refName = selectReferencedName(profileId)(state);
		return (
			datatype &&
			selectRestrictions(datatype)(state)?.find(
				(restriction) => restriction.get("name") === refName,
			)
		);
	},
);
export const selectRestrictionEntries = createSelector(
	(state, datatypeId: QName | undefined) =>
		(selectRestrictions(datatypeId)(state)?.entrySeq() ||
			Seq()) as RestrictionEntries,
);
export const selectRestrictionValues = createSelector(
	(state, datatypeId: QName | undefined | null) =>
		(selectRestrictions(datatypeId)(state)?.valueSeq() ||
			Seq()) as RestrictionValues,
);
export const selectRestrictionNames = createSelector(
	(state, datatypeId: QName | undefined | null) =>
		(selectRestrictions(datatypeId)(state)?.toList() || List()).map(
			(restriction) => restriction.get("name"),
		),
);
export const selectDatatypeRestrictionSeq = createSelector(
	memoizeOnce((state) => {
		const datatypes = selectDatatypes()(state);
		return datatypes
			.entrySeq()
			.filter(([, dt]) => {
				const restrictionMap = dt.get("restrictions");
				return restrictionMap && !restrictionMap.isEmpty();
			})
			.map(([dtId, dt]) => {
				const restrictionMap = dt.get("restrictions");
				if (!restrictionMap) {
					throw new Error("Unreachable");
				}
				const restrictionIds = restrictionMap
					.valueSeq()
					.map((restriction) => restriction.get("id"));
				return toMutable([dtId, restrictionIds] as const);
			});
	}),
);
export const selectInverseRestrictionMap = createSelector(
	memoizeOnce((state) => {
		const inverseEntries = selectDatatypeRestrictionSeq()(state).flatMap(
			([dtId, restrictionIds]) =>
				restrictionIds.map((restrictionId) =>
					toMutable([restrictionId, dtId] as const),
				),
		);
		const inverseMap = createImmutableMap<{ [K in RestrictionId]?: QName }>(
			Object.fromEntries(inverseEntries),
		);
		return inverseMap;
	}),
);
export const selectDatatypeIdByRestriction = createSelector(
	(state, restrictionId: RestrictionId | undefined): QName | null => {
		if (restrictionId === undefined) return null;
		const inverseMap = selectInverseRestrictionMap()(state);
		return inverseMap.get(restrictionId) ?? null;
	},
);
export const selectRestrictionByName = createSelector(
	(
		state,
		datatypeId: QName | undefined | null,
		restrictionName: string | undefined | null,
	) => {
		const restrictions = selectRestrictions(datatypeId)(state);
		if (!restrictions) return null;
		return (
			restrictions.find(
				(restriction) => restriction.get("name") === restrictionName,
			) ?? null
		);
	},
);
export const selectRestrictionReferenceMap = createSelector(
	memoizeOnce((state) => {
		const inverseMap = selectInverseRestrictionMap()(state);
		const entries = inverseMap
			.entrySeq()
			.map(([restrictionId, dtId]) => {
				const restriction = selectRestrictionEntry(dtId, restrictionId)(state);
				const profiles = restriction?.get("profile");
				if (!profiles || profiles.isEmpty()) {
					return toMutable([restrictionId, toMutable([])] as const);
				}
				const references: ReferenceMapItem[] = profiles
					.entrySeq()
					.filter(([, profile]) => profile.hasIn(["datentypReferenz", "name"]))
					.map(([nodeId, profile]) => {
						const datatypeId = profile.getIn(["datentypReferenz", "datentyp"]);
						const restrictionName = profile.getIn(["datentypReferenz", "name"]);
						const profiledRestriction = selectRestrictionByName(
							datatypeId,
							restrictionName,
						)(state);
						const profiledRestrictionId =
							profiledRestriction?.get("id") ?? null;
						return {
							nodeId,
							datatypeId,
							restrictionId: profiledRestrictionId,
							restrictionName,
						};
					})
					.toArray();
				return toMutable([restrictionId, references] as const);
			})
			.toArray();
		return createImmutableMap<{ [K in RestrictionId]?: ReferenceMapItem[] }>(
			Object.fromEntries(entries),
		);
	}),
);

function collectTransitiveReferences<Item, Ref, Identifier = string>({
	items,
	getItemIdentifier,
	getReferences,
	getRef,
}: {
	items: Item[];
	getItemIdentifier: (item: Item) => Identifier;
	getReferences: (item: Item) => Item[];
	getRef: (item: Item) => Ref;
}): TransitiveRef<Ref, Identifier>[] {
	if (!items || !items.length) return [];
	const refs: TransitiveRef<Ref, Identifier>[] = [];
	// Keep all visited ids here, so we can skip circular references
	const visitedRefIds = new Set<Identifier>();
	// Important! Clone the list, so iterating and shifting the queue does not
	// modify the original list
	let queue = [...items];
	// We check if the queue is empty when we shift the next item. Since we also
	// check for circular references, the queue should empty eventually
	// eslint-disable-next-line no-constant-condition
	while (true) {
		const currentItem = queue.shift();
		if (!currentItem) break;
		const identifier = getItemIdentifier(currentItem);
		if (!currentItem || visitedRefIds.has(identifier)) {
			continue;
		}
		const prevRef = refs.at(-1);
		const prevVia = prevRef?.via || [];
		const prevIdentifier = prevRef?.identifier;
		const via = [
			...prevVia,
			...(prevIdentifier !== undefined ? [prevIdentifier] : []),
		];
		refs.push({ ref: getRef(currentItem), identifier, via });
		visitedRefIds.add(identifier);
		const transitiveRefs = getReferences(currentItem);
		if (transitiveRefs?.length) {
			queue = [...queue, ...transitiveRefs];
		}
	}
	return refs;
}
export const selectTransitiveReferences = createSelector(
	(state, restrictionId: RestrictionId) => {
		const refMap = selectRestrictionReferenceMap()(state);
		const refItems = refMap.get(restrictionId);

		return collectTransitiveReferences({
			items: refItems || [],
			getItemIdentifier: (ref) => ref.restrictionId,
			getRef: (ref) => ref,
			getReferences: (ref) =>
				(ref.restrictionId && refMap.get(ref.restrictionId)) || [],
		});
	},
);
export const selectRestrictionReferencedByMap = createSelector(
	memoizeOnce((state) => {
		const refMap = selectRestrictionReferenceMap()(state);
		const inverseRefEntries = refMap
			.entrySeq()
			.filter(([, refs]) => (refs || []).length > 0)
			.flatMap(([restrictionId, refs]) =>
				(refs || [])
					.filter((ref) => ref.restrictionId !== null)
					.map((ref) =>
						toMutable([
							AssertionError.asNotNullish(ref.restrictionId),
							restrictionId,
						] as const),
					),
			);
		const inverseRefMap: { [k: RestrictionId]: RestrictionId[] } = {};
		for (const [restrictionId, refdById] of inverseRefEntries) {
			const refdByList = inverseRefMap[restrictionId];
			if (!refdByList) {
				inverseRefMap[restrictionId] = [refdById];
			} else {
				refdByList.push(refdById);
			}
		}
		return createImmutableMap(inverseRefMap);
	}),
);
export const selectRestrictionProfileSeq = createSelector((state) =>
	selectDatatypes()(state)
		.valueSeq()
		.flatMap((dtProfile) => subSelectRestrictionProfileSeq(dtProfile)),
);
export const selectTransitiveReferencedBy = createSelector(
	(state, restrictionId: RestrictionId) => {
		const refdByMap = selectRestrictionReferencedByMap()(state);
		const refItems = refdByMap.get(restrictionId);
		return collectTransitiveReferences({
			items: refItems || [],
			getItemIdentifier: (refId) => refId,
			getRef: (refId) => ({
				restrictionId: refId,
				restrictionName: selectRestrictionName(refId)(state) as string,
			}),
			getReferences: (refId) => refdByMap.get(refId) || [],
		});
	},
);
export const selectDatatypeReferenceEntry = createSelector(
	(state, restrictionId: RestrictionId | undefined) => {
		const datatypeId = selectDatatypeIdByRestriction(restrictionId)(state);
		const restrictionName = selectRestrictionName(restrictionId)(state);
		return datatypeId && restrictionName
			? { datatypeId, restrictionName }
			: null;
	},
);
function profileMatchesRestriction(
	state: EditorState,
	profile: ImmutableMap<Partial<MessageProfileValues>>,
	restrictionId: RestrictionId,
) {
	const refEntry = selectDatatypeReferenceEntry(restrictionId)(state);
	if (!refEntry) return false;
	const { datatypeId, restrictionName } = refEntry;
	return (
		profile.getIn(["datentypReferenz", "datentyp"]) === datatypeId &&
		profile.getIn(["datentypReferenz", "name"]) === restrictionName
	);
}

export const selectReferencedByProfileIds = createSelector(
	(state, restrictionId: RestrictionId) => {
		const restrictionProfileSeq = selectRestrictionProfileSeq()(state);
		const transitiveRefs = selectTransitiveReferencedBy(restrictionId)(state);
		return restrictionProfileSeq
			.filter(
				({ profile }) =>
					profileMatchesRestriction(state, profile, restrictionId) ||
					transitiveRefs.some(({ ref }) =>
						profileMatchesRestriction(state, profile, ref.restrictionId),
					),
			)
			.map(({ profileId, restrictionId: currentRestrictionId }) => ({
				profileId,
				restrictionId: currentRestrictionId,
			}));
	},
);
export const selectDatatypeRestrictionMap = createSelector(
	memoizeOnce((state) => {
		const datatypeRestrictionSeq = selectDatatypeRestrictionSeq()(state);
		const map = createImmutableMap<{
			[K in QName]?: Seq.Indexed<RestrictionId>;
		}>(Object.fromEntries(datatypeRestrictionSeq));
		return map;
	}),
);
export const selectHasRestriction = createSelector(
	(state, datatypeId: Nullish<QName>, restrictionName: string | undefined) =>
		(restrictionName &&
			selectRestrictions(datatypeId)(state)?.some(
				(restriction) => restriction.get("name") === restrictionName,
			)) ||
		false,
);

function getModelSelection(
	state: EditorState,
	predicate: (profile: ImmutableMap<MessageProfileValues>) => boolean,
) {
	const messageProfileSelectionModel = selectProfiles()(state)
		.filter(predicate)
		.keySeq()
		.map((qnamePath) =>
			stringifyRefId({ type: RefType.MessageElement, qnamePath }),
		)
		.toArray();

	const datatypeProfileSelectionModel = selectRestrictionProfileSeq()(state)
		.filter(({ profile }) => predicate(profile))
		.map(({ profileId, restrictionId: profileRestrictionId }) =>
			stringifyRefId({
				type: RefType.Datatype,
				qnamePath: profileId,
				restrictionId: profileRestrictionId,
			}),
		)
		.toArray();

	return messageProfileSelectionModel.concat(datatypeProfileSelectionModel);
}

export const selectStandardSelectionModel = createSelector(
	(state, datatypeQName: Nullish<QName>) => {
		if (!datatypeQName) return [];
		const predicate = (profile: ImmutableMap<MessageProfileValues>) =>
			profile.getIn(["datentypReferenz", "datentyp"]) === datatypeQName;
		return getModelSelection(state, predicate);
	},
);

export const selectRestrictionSelectionModel = createSelector(
	(state, restrictionId: RestrictionId | undefined) => {
		if (!restrictionId) return [];
		const predicate = (profile: ImmutableMap<MessageProfileValues>) =>
			profileMatchesRestriction(state, profile, restrictionId);
		return getModelSelection(state, predicate);
	},
);

export const selectRestrictionProfileCardinality = createSelector(
	(
		state,
		activeDatatypeId: QName,
		activeRestrictionId: RestrictionId,
		activeRestrictionProfileId: QNamePath,
	) => {
		const profile = selectRestrictionProfile(
			activeDatatypeId,
			activeRestrictionId,
			activeRestrictionProfileId,
		)(state);
		const lowerBound = profile?.get("lowerBound");
		const upperBound = profile?.get("upperBound");
		return { lowerBound, upperBound };
	},
);

export const selectHasRestrictionProfileZeroCardinality = createSelector(
	(
		state,
		activeDatatypeId: QName,
		activeRestrictionId: RestrictionId,
		activeRestrictionProfileId: QNamePath,
	) => {
		const { lowerBound, upperBound } = selectRestrictionProfileCardinality(
			activeDatatypeId,
			activeRestrictionId,
			activeRestrictionProfileId,
		)(state);
		return lowerBound === "0" && upperBound === "0";
	},
);

export const selectCaridanlity = createSelector(
	(state, nodeId: QNamePath | undefined) => {
		const profile = selectProfile(nodeId)(state);
		const lowerBound = profile?.get("lowerBound");
		const upperBound = profile?.get("upperBound");
		return { lowerBound, upperBound };
	},
);
export const selectHasZeroCarnidality = createSelector(
	(state, nodeId: QNamePath | undefined) => {
		const { lowerBound, upperBound } = selectCaridanlity(nodeId)(state);
		return lowerBound === "0" && upperBound === "0";
	},
);

function traverseToParentId(
	state: EditorState,
	qnamePath: QNamePath,
	fun: (val: QNamePath) => boolean,
): QNamePath | null {
	if (!qnamePath) return null;
	const index = qnamePath.lastIndexOf("/");
	if (index === -1) {
		return null;
	}
	const parentId = QNamePathSchema.parse(qnamePath.slice(0, index));
	if (fun(parentId)) {
		return parentId;
	}
	if (!parentId.includes("/")) {
		return null;
	}
	return traverseToParentId(state, parentId, fun);
}

function getGenericParentIdProfiledWithDatatype(
	state: EditorState,
	nodeId: QNamePath,
	hasDatatypeReference: (currentId: QNamePath) => boolean,
): QNamePath | null {
	return traverseToParentId(state, nodeId, hasDatatypeReference);
}

function getGenericeParentIdZeroCardinality(
	state: EditorState,
	nodeId: QNamePath,
	hasZeroCardinality: (currentId: QNamePath) => boolean,
) {
	return traverseToParentId(state, nodeId, hasZeroCardinality);
}

export const selectParentZeroCardinality = createSelector(
	(state, nodeId: QNamePath) =>
		getGenericeParentIdZeroCardinality(state, nodeId, (parentId) =>
			selectHasZeroCarnidality(parentId)(state),
		),
);

export const selectHasParentZeroCardinality = createSelector(
	(state, nodeId: Nullish<QNamePath>) =>
		!!nodeId && selectParentZeroCardinality(nodeId)(state) !== null,
);

export const selectRestrictionParentZeroCardinality = createSelector(
	(
		state,
		activeDatatypeId: QName,
		activeRestrictionId: RestrictionId,
		activeRestrictionProfileId: QNamePath,
	) => {
		const foundId = getGenericeParentIdZeroCardinality(
			state,
			activeRestrictionProfileId,
			(parentId) =>
				selectHasRestrictionProfileZeroCardinality(
					activeDatatypeId,
					activeRestrictionId,
					parentId,
				)(state),
		);
		return foundId;
	},
);

export const selectHasRestrictionParentZeroCardinality = createSelector(
	(
		state,
		activeDatatypeId: QName,
		activeRestrictionId: RestrictionId,
		activeRestrictionProfileId: QNamePath,
	) =>
		selectRestrictionParentZeroCardinality(
			activeDatatypeId,
			activeRestrictionId,
			activeRestrictionProfileId,
		)(state) !== null,
);

export const selectParentProfiledDatatype = createSelector(
	(state, nodeId: QNamePath) =>
		getGenericParentIdProfiledWithDatatype(
			state,
			nodeId,
			(parentId) => !!selectDatatypeReference(parentId)(state),
		),
);

export const selectHasParentProfiledDatatype = createSelector(
	(state, nodeId: Nullish<QNamePath>) =>
		!!nodeId && selectParentProfiledDatatype(nodeId)(state) !== null,
);

export const selectRestrictionParentProfiledDatatype = createSelector(
	(
		state,
		activeDatatypeId: QName,
		activeRestrictionId: RestrictionId,
		activeRestrictionProfileId: QNamePath,
	) =>
		getGenericParentIdProfiledWithDatatype(
			state,
			activeRestrictionProfileId,
			(parentId) =>
				!!selectRestrictionDatatypeReference(
					activeDatatypeId,
					activeRestrictionId,
					parentId,
				)(state),
		),
);
export const selectHasRestrictionParentProfiledDatatype = createSelector(
	(
		state,
		activeDatatypeId: QName,
		activeRestrictionId: RestrictionId,
		activeRestrictionProfileId: QNamePath,
	) =>
		selectRestrictionParentProfiledDatatype(
			activeDatatypeId,
			activeRestrictionId,
			activeRestrictionProfileId,
		)(state) !== null,
);

export const selectRestrictionProfileByNode = createSelector(
	(state, nodeId: QNamePath) => {
		const profiledParentId = selectParentProfiledDatatype(nodeId)(state);

		const profiledParentDatatype =
			profiledParentId &&
			state
				.get("profile")
				.get(profiledParentId)
				?.get("datentypReferenz")
				?.get("datentyp");
		const restrictionSeq = selectRestrictionValues(profiledParentDatatype)(
			state,
		);
		const profiledParentDatatypeName =
			profiledParentId &&
			state
				.get("profile")
				.get(profiledParentId)
				?.get("datentypReferenz")
				?.get("name");

		const profiledParentRestrictionId = restrictionSeq
			.find(
				(restriction) => profiledParentDatatypeName === restriction.get("name"),
			)
			?.get("id");

		const restrictionProfiles =
			profiledParentDatatype && profiledParentRestrictionId
				? state
						.get("datentypen")
						.get(profiledParentDatatype)
						?.get("restrictions")
						?.get(profiledParentRestrictionId)
						?.get("profile")
				: null;
		const restrictionProfileName = restrictionProfiles
			?.keySeq()
			.find(
				(profileKey) =>
					!!profileKey &&
					nodeId.endsWith(segmentizeId(profileKey as string).pop() as string),
			);

		return restrictionProfileName
			? restrictionProfiles?.get(restrictionProfileName)
			: null;
	},
);

export const selectDatatypeRestrictonProfile = createSelector(
	(
		state,
		activeDatatypeId: QName,
		activeRestrictionId: RestrictionId,
		activeRestrictionProfileId: QNamePath,
	) => {
		const profiledParentId = selectRestrictionParentProfiledDatatype(
			activeDatatypeId,
			activeRestrictionId,
			activeRestrictionProfileId,
		)(state);

		const profiledParentDatatype =
			profiledParentId &&
			state
				.get("datentypen")
				.get(activeDatatypeId)
				?.get("restrictions")
				?.get(activeRestrictionId)
				?.get("profile")
				?.get(profiledParentId)
				?.get("datentypReferenz")
				?.get("datentyp");

		const restrictionSeq = selectRestrictionValues(profiledParentDatatype)(
			state,
		);

		const profiledParentDatatypeName =
			profiledParentId &&
			state
				.get("datentypen")
				.get(activeDatatypeId)
				?.get("restrictions")
				?.get(activeRestrictionId)
				?.get("profile")
				?.get(profiledParentId)
				?.get("datentypReferenz")
				?.get("name");

		const profiledParentDatatypeId = restrictionSeq
			.find(
				(restriction) => profiledParentDatatypeName === restriction.get("name"),
			)
			?.get("id");

		const restrictionProfiles =
			profiledParentDatatype && profiledParentDatatypeId
				? state
						.get("datentypen")
						.get(profiledParentDatatype)
						?.get("restrictions")
						?.get(profiledParentDatatypeId)
						?.get("profile")
				: null;

		const restrictionProfileName = restrictionProfiles
			?.keySeq()
			.find(
				(profileKey) =>
					!!profileKey &&
					activeRestrictionProfileId.endsWith(
						segmentizeId(profileKey as string).pop() as string,
					),
			);

		return restrictionProfileName
			? restrictionProfiles?.get(restrictionProfileName)
			: null;
	},
);
function getClosestChildProfileKey(
	profileKeys: Iterable<QNamePath>,
	parentNodeId: QNamePath,
) {
	let closest: QNamePath | null = null;
	let segmentLength = 0;
	const parentSegmentLength = segmentizeId(parentNodeId).length;
	for (const childId of profileKeys) {
		if (!isChildId(parentNodeId, childId)) continue;
		const currentSegmentLength = segmentizeId(childId).length;
		if (currentSegmentLength > segmentLength) {
			closest = childId;
			segmentLength = currentSegmentLength;
		}
		// If the currently closest child id only has one segment more than the
		// parent id, it is a direct child, so no other child can be any closer.
		// In that case we don't need to look any further for a closer child
		if (segmentLength === parentSegmentLength + 1) break;
	}
	return closest;
}
export const selectClosestChildProfile = createSelector(
	(state, nodeId: QNamePath) => {
		const profileKeys = selectProfileKeys()(state);
		return getClosestChildProfileKey(profileKeys, nodeId);
	},
);
export const selectIsChildDatatypedOrProfiled = createSelector(
	(state, nodeId: Nullish<QNamePath>) =>
		!!nodeId && selectClosestChildProfile(nodeId)(state) !== null,
);
export const selectClosestRestrictionChildProfile = createSelector(
	(
		state,
		datatypeId: QName | undefined,
		restrictionId: RestrictionId,
		restrictionProfileId: QNamePath,
	) => {
		if (!datatypeId) return null;
		const profileKeys = selectRestrictionProfileKeys(
			datatypeId,
			restrictionId,
		)(state);
		return getClosestChildProfileKey(profileKeys, restrictionProfileId);
	},
);
export const selectIsRestrictionChildDatatypedOrProfiled = createSelector(
	(
		state,
		datatypeId: QName | undefined,
		restrictionId: RestrictionId,
		restrictionProfileId: QNamePath,
	) =>
		selectClosestRestrictionChildProfile(
			datatypeId,
			restrictionId,
			restrictionProfileId,
		)(state) !== null,
);
export const selectRefRestrictionId = createSelector(
	(
		state,
		refProfile: ImmutableMap<Partial<MessageProfileValues>> | null | undefined,
		activeRestriction:
			| ImmutableMap<RestrictionProfileValues>
			| null
			| undefined,
	) => {
		const dtRef = subSelectDatatypeReference(refProfile);
		const dtRefName = dtRef?.get("name");
		const dtRefId = selectDatatypeIdByRestriction(activeRestriction?.get("id"))(
			state,
		);
		return (
			(dtRefId &&
				dtRefName &&
				selectRestrictionByName(dtRefId, dtRefName)(state)?.get("id")) ??
			null
		);
	},
);
export const selectRefItemSelectabilityStatus = createSelector(
	(
		state,
		refItem: SpecificRefItem,
		activeRestriction:
			| ImmutableMap<RestrictionProfileValues>
			| null
			| undefined,
	): SelectablilityStatus => {
		// Check message elements
		if (refItem.type === RefType.MessageElement) {
			const { qnamePath: syntaxPath } = refItem;
			// Check if parent or child profiles exist
			const parentProfileId = selectParentProfiledDatatype(syntaxPath)(state);
			const childProfileId = selectClosestChildProfile(syntaxPath)(state);
			const refProfile = selectProfile(syntaxPath)(state);
			const refRestrictionId = selectRefRestrictionId(
				refProfile,
				activeRestriction,
			)(state);
			return subSelectSelectabilityStatus({
				parentProfileId,
				childProfileId,
				refProfile,
				activeRestriction,
				refRestrictionId,
			});
		}
		// Check datatype elements
		const { qnamePath: restrictionProfileId, restrictionId } = refItem;
		const datatypeId = selectDatatypeIdByRestriction(restrictionId)(state);
		// This should not be possible, since a restriction can not exist without
		// a parent datatype, but it makes TS happy
		if (!datatypeId) return EMPTY_SELECTABILITY_STATUS;
		const parentProfileId = selectRestrictionParentProfiledDatatype(
			datatypeId,
			restrictionId,
			restrictionProfileId,
		)(state);
		const childProfileId = selectClosestRestrictionChildProfile(
			datatypeId,
			restrictionId,
			restrictionProfileId,
		)(state);
		const refProfile = selectRestrictionProfile(
			datatypeId,
			restrictionId,
			restrictionProfileId,
		)(state);
		const refRestrictionId = selectRefRestrictionId(
			refProfile,
			activeRestriction,
		)(state);
		return subSelectSelectabilityStatus({
			parentProfileId,
			childProfileId,
			refProfile,
			activeRestriction,
			refRestrictionId,
		});
	},
);
export const selectIsRefItemChecked = createSelector(
	(
		state,
		refItem: SpecificRefItem,
		activeRestriction:
			| ImmutableMap<RestrictionProfileValues>
			| null
			| undefined,
	): boolean => {
		if (!activeRestriction) return false;
		const datatypeId = selectDatatypeIdByRestriction(
			activeRestriction.get("id"),
		)(state);
		if (datatypeId === null) return false;
		let dtRef: ImmutableMap<DatatypeReference> | null = null;
		// Check message elements
		if (refItem.type === RefType.MessageElement) {
			const { qnamePath: syntaxPath } = refItem;
			dtRef = selectDatatypeReference(syntaxPath)(state);
		} else if (refItem.type === RefType.Datatype) {
			// Check datatype elements
			const { qnamePath: restrictionProfileId, restrictionId } = refItem;
			const refRestrictionProfile = selectRestrictionProfile(
				datatypeId,
				restrictionId,
				restrictionProfileId,
			)(state);
			dtRef = subSelectDatatypeReference(refRestrictionProfile);
		}
		if (!dtRef) return false;
		return (
			dtRef.get("name") === activeRestriction.get("name") &&
			dtRef.get("datentyp") === datatypeId
		);
	},
);
export const selectIsRefItemSelectable = createSelector(
	(
		state,
		refItem: SpecificRefItem,
		activeRestriction:
			| ImmutableMap<RestrictionProfileValues>
			| null
			| undefined,
	) => {
		const status = selectRefItemSelectabilityStatus(
			refItem,
			activeRestriction,
		)(state);
		return (
			status.code === SelectablilityCode.None ||
			status.code === SelectablilityCode.CodelistValues
		);
	},
);

export const selectIsMessageProfiled = createSelector(
	(state: EditorState, messageId: QNamePath) =>
		selectProfileKeys()(state).some((key) => isChildIdOrSelf(messageId, key)),
);

/** Select the documentation, associated with the standard */
export const selectDocumentation = createSelector((state) =>
	state.get("dokumentation"),
);
/** Select a chapter of the documentation by name */
export const selectDocChapter = createSelector(
	(state, chapterName: string | undefined) =>
		chapterName ? selectDocumentation()(state).get(chapterName) : undefined,
);
/**
 * Select the current markup. If a chapter name is provided, only the markup of
 * that chapter (plus the configuration) is returned.
 */
export const selectAdocMarkup = createSelector(
	(state, generatedChapters: ChapterMap, chapterName?: string) => {
		const documentation = selectDocumentation()(state);
		// If we don't pass a chapter, all chapters are returned
		const shouldGetAllChapters = chapterName === undefined;
		const getContent = (doc: ImmutableMap<SavedProfileDocumentation>) =>
			doc.get("generiert")
				? generatedChapters[doc.get("name")]?.inhalt ?? ""
				: doc.get("inhalt");
		const configMarkup = documentation
			// Only get the hidden documents, so we can be sure that the
			// configuration is always at the top of the document
			.filter((doc) => doc.get("versteckt"))
			.map((doc) => getContent(doc))
			.join("\n\n");
		const adocMarkup = documentation
			// We'll merge the hidden chapters back in later
			.filter((doc) => !doc.get("versteckt"))
			// only use the selected doc file if we are not in full preview mode
			.filter(
				(_doc, chapter) => shouldGetAllChapters || chapter === chapterName,
			)
			.sortBy((doc) => doc.get("index"))
			.map((doc) => getContent(doc))
			.join("\n\n");
		// The config needs to be before the main markup, so the variables are
		// always defined at the top and usable throughout the whole document
		const completeMarkup = `\n${configMarkup}\n\n${adocMarkup}\n`;
		return completeMarkup;
	},
);
/** Select all visible documents, sorted by their index */
export const selectVisibleDocs = createSelector((state) =>
	selectDocumentation()(state)
		.filter((doc) => !doc.get("versteckt"))
		.sortBy((doc) => doc.get("index")),
);
/** Select all hidden documents */
export const selectHiddenDocs = createSelector((state) =>
	selectDocumentation()(state).filter((doc) => doc.get("versteckt")),
);
function collectReferences(
	state: EditorState,
	doesProfileContainReference: (
		profile: ImmutableMap<Partial<MessageProfileValues>>,
	) => boolean,
): Seq.Indexed<ProfileRef> {
	const profiles = selectProfiles()(state);
	const referencedMessageProfiles = profiles
		.filter((profile) => doesProfileContainReference(profile))
		.entrySeq()
		.map(([key]) => ({
			type: RefType.MessageElement as const,
			profileId: key,
		}));
	const restrictionProfiles = selectRestrictionProfileSeq()(state);
	const referencedRestrictionProfiles = restrictionProfiles
		.filter(({ profile }) => doesProfileContainReference(profile))
		.map(({ profileId, restrictionId }) => {
			const datatypeId = selectDatatypeIdByRestriction(restrictionId)(state);
			return {
				type: RefType.Datatype as const,
				profileId,
				restrictionId,
				restrictionName: selectRestrictionName(restrictionId)(state) as string,
				datatypeId: AssertionError.asNotNullish(datatypeId),
			};
		});
	return referencedMessageProfiles.concat(referencedRestrictionProfiles);
}
/** Select all profiles that reference a given restriction */
export const selectDatatypeRestrictionReferences = createSelector(
	(state, restrictionId: RestrictionId) =>
		collectReferences(state, (profile) =>
			profileMatchesRestriction(state, profile, restrictionId),
		),
);
/** Select all profiles that reference the given property */
export const selectPropertyReferences = createSelector(
	(state, propertyNameTechnisch: string) =>
		collectReferences(state, (profile) =>
			subSelectContainsProperties(profile, propertyNameTechnisch),
		),
);

export const selectIsRestrictionDeleteVisible = createSelector(
	(
		state,
		activeDatatypeId: QName,
		activeRestrictionId: RestrictionId,
		activeRestrictionProfileId: QNamePath,
	) =>
		!!selectDatatypeEntry(activeDatatypeId)(state)
			?.get("restrictions")
			?.get(activeRestrictionId)
			?.get("profile")
			?.get(activeRestrictionProfileId),
);

export const selectIsProfileDeleteVisible = createSelector(
	(state, activeNodeId: QNamePath) => !!selectProfile(activeNodeId)(state),
);

/**
 * Select a profile that might be nested deeply behind several datatype
 * references, by traversing the profile tree and re-basing the profile
 * onto datatype references, when hitting a datatype reference.
 * @param profileId The id of the node
 * @param baseProfileMap The profile map to start looking for the id in
 */
export const selectDeepProfile = createSelector(
	(state, profileId: QNamePath, baseProfileMap: StateProfileMap | null) => {
		const segments = QNamePathSchema.array().parse(segmentizeId(profileId));
		let currentProfileMap = baseProfileMap;
		let currentSegments = segments;

		// We can skip the first index, since every id needs to consist of at least
		// on segment. We cannot work with an empty segment, so we can just ignore
		// that initial loop
		for (let iInSegments = 1; iInSegments < segments.length; iInSegments += 1) {
			// Since we're switching between profiles, our ids are re-based depending
			// on the current profile. When that happens, the ids number of segments
			// can change, so we re-calculate the index each round, by comparing the
			// lengths of the initial segments and the current segments
			const i = iInSegments - (segments.length - currentSegments.length);
			const currentPath = QNamePathSchema.parse(
				joinIdSegments(currentSegments.slice(0, i)),
			);
			const currentProfile = currentProfileMap?.get(currentPath);
			// If the current profile contains a dt-ref, we need to switch to the
			// profile map of that restriction
			const datentypReferenz = subSelectDatatypeReference(currentProfile);
			const restrictionName = datentypReferenz?.get("name");
			const datatypeId = datentypReferenz?.get("datentyp");
			// The restriction name might be an empty string, which doesn't make
			// sense, but can potentially be input by the user, so we cannot check
			// for truthiness of the name, but rather check that it is defined
			if (restrictionName !== undefined && datatypeId !== undefined) {
				// We've found a dt-ref, so we get the corresponding restriction and
				// use its profile map from now on
				const restrictionId = selectRestrictionByName(
					datatypeId,
					restrictionName,
				)(state)?.get("id");
				const restrictionEntry = selectRestrictionEntry(
					datatypeId,
					restrictionId,
				)(state);
				// Set the new profile map, so we can check the next segments against it
				currentProfileMap = restrictionEntry?.get("profile") ?? null;
				// Rebase the id segments onto the current datatype
				currentSegments = [
					QNamePathSchema.parse(datatypeId),
					...currentSegments.slice(i),
				];
			}
		}

		// Finally get the profile of the last profile map using the re-based
		// profile id
		return (
			currentProfileMap?.get(
				QNamePathSchema.parse(joinIdSegments(currentSegments)),
			) ?? null
		);
	},
);
