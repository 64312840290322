import { Fragment, useReducer, useState } from "react";
import EditFormField from "../../../../../EditFormField";
import type { FieldRendererComponentProps } from "../../types";
import DetailsComponent from "../../../DetailsComponent/DetailsComponent";
import { cardinalityMsgSelector } from "./cardinalityRendererHelper";
import { useGetErrorMessagesQuery } from "../../../../../../redux/apiSlice";
import { ValidationTargetField } from "../../../../../Validation/types";
import { useHtmlId } from "../../../../../../hooks";
import ValidationFormatter from "../../../../../Validation/ValidationFormatter";
import { useProfilingValidationResult } from "../../../../../Profiling/ProfilingValidationDisplay";
import { extractFromList } from "../../../../../../util/lists";
import ZeroCardinalityWarning from "./ZeroCardnialityWarning";
import useSectionDependentProfile from "../../../../../Profiling/useSectionDependentProfile";
import DynamicInfo from "../../../../../ui/DynamicInfo";
import { isLiteEigenschaft } from "../../../../../AppActor/actors/modellierungModel/schemas";
import "./CardinalityRenderer.scss";

type Keys = "lowerBound" | "upperBound";
interface WarningState {
	isOpen: boolean;
	name: Keys;
	value: string;
}

const INITIAL_WARNING_STATE: WarningState = {
	isOpen: false,
	name: "lowerBound",
	// The value will always be "0", since that is the only case the warning
	// dialog will be shown, but by storing it here, we'll be able to adapt the
	// component in the future
	value: "0",
};

export function parseMultiplizitaet(multiplizitaet: string | undefined) {
	if (!multiplizitaet) return { lowerBound: undefined, upperBound: undefined };
	const [lowerBound, upperBound] = multiplizitaet.split("..");
	return {
		lowerBound: lowerBound || undefined,
		upperBound: upperBound || undefined,
	};
}

function CardinalityRenderer({
	fields,
	activeNode,
	activePath,
	onBlur,
	onFix,
	profile,
}: FieldRendererComponentProps<
	Keys,
	ValidationTargetField.Cardinality
>): JSX.Element {
	const [formKey, resetForm] = useReducer(
		(keyCounter: number) => keyCounter + 1,
		0,
	);
	const [definition] = fields;

	const { isChildDatatypedOrProfiled } = useSectionDependentProfile(activePath);

	const validationResultId = useHtmlId();
	const validationResults = useProfilingValidationResult({
		nodeId: activeNode.id,
		targetField: ValidationTargetField.Cardinality,
	});
	const [lowerValidationResults, upperValidationResults] = extractFromList(
		validationResults,
		(result) => !!result.target?.includes("lowerBound"),
	);

	const [warningState, setWarningState] = useState(INITIAL_WARNING_STATE);
	const { data: errorMessages } = useGetErrorMessagesQuery();

	const [[lower], [upper]] = extractFromList(
		fields,
		(field) => field.name === "lowerBound",
	);

	const selectMsg = () => {
		return cardinalityMsgSelector(
			errorMessages,
			activeNode.name,
			profile?.get(lower.name),
			profile?.get(upper.name),
		);
	};

	const lowerValue = (profile && profile.get(lower.name)) || "";
	const upperValue = (profile && profile.get(upper.name)) || "";
	const { lowerBound, upperBound } =
		(isLiteEigenschaft(activeNode) &&
			parseMultiplizitaet(activeNode.multiplizitaet)) ||
		{};
	const isCardinalityReadonly =
		definition?.readOnly || lowerBound === upperBound;

	const handleCardinalityBlur = (name: string, value: string) => {
		const otherValue = name === "lowerBound" ? upperValue : lowerValue;
		// If both bounds are `0` and we have child profiles, we delete the child
		// profiles. First we activate a confirm dialog though, so the user can
		// cancel deleting the child profiles
		if (value === "0" && otherValue === "0" && isChildDatatypedOrProfiled) {
			// Store the name and value for later, so we can call the blur handler
			// with the correct data on accept
			setWarningState({ isOpen: true, name: name as Keys, value });
		} else {
			onBlur(name, value);
		}
	};

	const handleAccept = () => {
		onBlur(warningState.name, warningState.value);
		setWarningState(INITIAL_WARNING_STATE);
	};

	const handleDismiss = () => {
		// Reset all statefull form field child components, so we don't display
		// any old local states, but reset to the current state container state
		resetForm();
		setWarningState(INITIAL_WARNING_STATE);
	};

	return (
		// We need to rely on the label to work with nesting here, as there
		// are two inputs, each with an own, more specific label
		<>
			<ZeroCardinalityWarning
				isOpen={warningState.isOpen}
				onAccept={handleAccept}
				onDismiss={handleDismiss}
			/>
			{/* Force the child components to re-render an reset their local states */}
			<Fragment key={formKey}>
				{/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
				<label
					key={lower.name}
					className="cardinality-renderer"
					data-testid="cardinality-renderer"
				>
					<span className="cardinality-renderer__label-text">
						Kardinalität der Einschränkung{" "}
						{isCardinalityReadonly && (
							<DynamicInfo infoKey="cardinalityReadOnly" />
						)}
					</span>
					<div className="cardinality-renderer__bounds">
						<EditFormField
							inputProps={{ "data-testid": "lower-bound" }}
							className="cardinality-renderer__lower-bound cardinality-renderer__bound-input"
							labelClassName="cardinality-renderer__hidden-label"
							definition={{ ...lower, readOnly: isCardinalityReadonly }}
							value={lowerValue}
							onBlur={handleCardinalityBlur}
							aria-describedby={validationResultId}
						/>
						<span className="cardinality-renderer__dbl-dot">..</span>
						<EditFormField
							inputProps={{ "data-testid": "upper-bound" }}
							className="cardinality-renderer__upper-bound cardinality-renderer__bound-input"
							labelClassName="cardinality-renderer__hidden-label"
							definition={{ ...upper, readOnly: isCardinalityReadonly }}
							value={upperValue}
							onBlur={handleCardinalityBlur}
							aria-describedby={validationResultId}
						/>
					</div>
					<DetailsComponent
						label="Fehlermeldung Kardinalität"
						name="errorMessageCardinality"
						value={selectMsg()}
						readOnly
					/>
					{!!lowerValidationResults.length && (
						<div
							className="cardinality-renderer__error-message"
							id={validationResultId}
						>
							<ValidationFormatter
								results={lowerValidationResults}
								onFix={onFix}
								value={lowerValue}
							/>
						</div>
					)}
					{!!upperValidationResults.length && (
						<div
							className="cardinality-renderer__error-message"
							id={validationResultId}
						>
							<ValidationFormatter
								results={upperValidationResults}
								onFix={onFix}
								value={upperValue}
							/>
						</div>
					)}
				</label>
			</Fragment>
		</>
	);
}

export default CardinalityRenderer;
