import type { ReactNode } from "react";
import { forwardRef } from "react";
import type { ExtendProps } from "../../../util/types";
import InteractiveNode from "../../InteractiveNode";
import type { InteractiveNodeProps } from "../../InteractiveNode/InteractiveNode";

interface ListNodeProps {
	children?: ReactNode;
	isActive?: boolean;
	isProfiled?: boolean;
}

const ListNode = forwardRef<
	HTMLDivElement,
	ExtendProps<InteractiveNodeProps, ListNodeProps>
>(function ListNode({ children, isActive, isProfiled, ...props }, ref) {
	return (
		<InteractiveNode
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			ref={ref as any}
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...props}
			isActive={isActive}
			isEmphasized={isProfiled}
		>
			{children}
		</InteractiveNode>
	);
});

export default ListNode;
