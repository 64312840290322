import type SegmentTrie from "@xoev/segment-trie";
import type { UiFilterGroup } from "../../../redux/uiSlice";
import type { RequestStatus } from "../../Api";
import type { StateProfileMap } from "../../EditorState/types";
import type { useActiveNode } from "../../MessageProfilingView/useActiveNode";
import type { TreeNodeType } from "../../Profiling/types";
import type { NodeEventArg } from "../../Tree";
import type { LiteId } from "../../AppActor/actors/modellierungModel/schemas";

export type SearchTrie = SegmentTrie<LiteId[], LiteId, number>;

export type ProfilingTreeFilterGroup =
	| UiFilterGroup.MessageStructureTree
	| UiFilterGroup.StandardTree
	| UiFilterGroup.RestrictionTree
	| UiFilterGroup.StandardDatatypeSubTree;

export enum TreeDisplayState {
	ShowFallback = "ShowFallback",
	ShowLoading = "ShowLoading",
	ShowTree = "ShowTree",
}

export interface StructureTreeProps {
	rootId: LiteId;
	profiles: StateProfileMap;
	standard?: string;
	isFilterMatch?: (node: TreeNodeType) => boolean;
	getUrl: (event: NodeEventArg<TreeNodeType, LiteId>) => string;
	activeNodeState: ReturnType<typeof useActiveNode>;
	nodeProps?: Record<string, unknown>;
	filterGroup: ProfilingTreeFilterGroup;
	renderNodeName?: (node: TreeNodeType) => string | JSX.Element;
	parentStatus?: RequestStatus;
	searchInputClassName?: string;
}
