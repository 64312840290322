import { ListItemText, MenuItem, Switch } from "@mui/material";
import { DataObject, MailOutline } from "@mui/icons-material";
import { LiteBausteinType } from "../../../AppActor/actors/modellierungModel/schemas";

export default function BausteinNodeMenuItems({
	activeFilters,
	toggleFilter,
}: {
	activeFilters: LiteBausteinType[];
	toggleFilter: (...filters: LiteBausteinType[]) => void;
}): JSX.Element {
	return (
		<>
			<MenuItem onClick={() => toggleFilter(LiteBausteinType.Datentyp)}>
				<Switch checked={!activeFilters.includes(LiteBausteinType.Datentyp)} />
				<ListItemText>
					<DataObject /> Datentyp
				</ListItemText>
			</MenuItem>
			<MenuItem onClick={() => toggleFilter(LiteBausteinType.CodeDatentyp)}>
				<Switch
					checked={!activeFilters.includes(LiteBausteinType.CodeDatentyp)}
				/>
				<ListItemText>
					<DataObject /> Codedatentyp
				</ListItemText>
			</MenuItem>
			<MenuItem onClick={() => toggleFilter(LiteBausteinType.Nachricht)}>
				<Switch checked={!activeFilters.includes(LiteBausteinType.Nachricht)} />
				<ListItemText>
					<MailOutline /> Nachricht
				</ListItemText>
			</MenuItem>
			<MenuItem
				onClick={() => toggleFilter(LiteBausteinType.GlobaleEigenschaft)}
			>
				<Switch
					checked={!activeFilters.includes(LiteBausteinType.GlobaleEigenschaft)}
				/>
				<ListItemText>
					<DataObject /> Globale Eigenschaften
				</ListItemText>
			</MenuItem>
		</>
	);
}
