import { useContext } from "react";
import { useSelector } from "@xstate/react";
import type { AnyActorSystem } from "xstate/dist/declarations/src/system";
import AppActorRedirectContext from "./AppActorRedirectContext";
import type { ActorSystemRef } from "./actors/actorSystem/actorSystem.machine";
import { getFromSystem } from "../../util/machines";

function useAppActorRef() {
	const AppActorContext = useContext(AppActorRedirectContext);
	return AppActorContext.useActorRef();
}

export function useAppSystem() {
	return useAppActorRef().system;
}

export function getActorSystemFromSystem(
	system: AnyActorSystem,
): ActorSystemRef {
	return getFromSystem(system, "actorSystem");
}

export function useActorSystemRef(): ActorSystemRef {
	const system = useAppSystem();
	return getActorSystemFromSystem(system);
}

export function useSelectFromSystem<TReturn>(
	selector: (system: AnyActorSystem) => TReturn,
) {
	const systemRef = useActorSystemRef();
	return useSelector(systemRef, ({ context }) => {
		return selector(context.systemRef.current);
	});
}
