import ConfirmDialog from "../../../ConfirmDialog";

const getTitle = (isCodeListOverride?: boolean) =>
	`Warnung: Löschung der Profilierung ${
		isCodeListOverride ? "" : "von Unterelementen"
	}`;

const getContent = (isCodeListOverride?: boolean) =>
	`Wenn eine Einschränkung mit einen Datentyp vorgenommen wird, ${
		isCodeListOverride
			? "wird die Profilierung des Elements gelöscht."
			: "werden die Proflierungen der Unterelemente gelöscht."
	}`;

const OverrideDialog = ({
	isOpen,
	onDismiss,
	onAccept,
	isCodeListOverride,
}: {
	isOpen: boolean;
	onDismiss: () => void;
	onAccept: () => void;
	isCodeListOverride?: boolean;
}): JSX.Element => {
	return (
		<ConfirmDialog
			isOpen={isOpen}
			onAccept={onAccept}
			onDismiss={onDismiss}
			title={getTitle(isCodeListOverride)}
			description={getContent(isCodeListOverride)}
			componentsProps={{
				acceptProps: { "data-testid": "override-accept" },
				cancelProps: { "data-testid": "override-dismiss" },
			}}
			data-testid="override-dialog"
		/>
	);
};

export default OverrideDialog;
