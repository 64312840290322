import classNames from "classnames";
import type { PropsOf } from "../../util/types";
import AdocView from "../AdocView";
import "./PartialAdocView.scss";

function PartialAdocView({
	className,
	...props
}: PropsOf<typeof AdocView>): JSX.Element {
	return (
		<div className="partial-adoc-view__wrapper">
			<AdocView
				className={classNames("partial-adoc-view", className)}
				{...props}
			/>
		</div>
	);
}

export default PartialAdocView;
