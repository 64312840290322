import { Link } from "react-router-dom";
import { encodeXPath } from "../../../util/url";

function DatatypeLink({
	standardType,
	standardName,
	profileId: profileType,
	profileName,
}: {
	standardType?: string | null;
	standardName?: string | null;
	profileId?: string | null;
	profileName?: string | null;
}): JSX.Element {
	if (!standardType) return <></>;
	const basePath = `/profilierung/datentypen/${encodeXPath(standardType)}`;
	let linkPath: string;
	let linkContent: string;
	if (!profileType) {
		linkPath = `${basePath}/standard`;
		linkContent = standardName || standardType;
	} else {
		linkPath = `${basePath}/profil/${profileType}`;
		linkContent = profileName || profileType;
	}
	return (
		<Link to={linkPath} data-testid="datatype-link">
			{linkContent}
		</Link>
	);
}

export default DatatypeLink;
