import type { SnapshotFrom } from "xstate";
import type { AnyActorSystem } from "xstate/dist/declarations/src/system";
import type { ExpansionTree } from "../../../Tree";
import { useActiveModellPath, useActiveProjectId } from "../navigation/hooks";
import { useModellierungModellSelector } from "../modellierungModel/hooks";
import {
	createSelectDefinitionPathFromModell,
	createSelectNode,
	createSelectNodesByPath,
	selectBausteinRootNode,
	selectNodeFromModell,
} from "../modellierungModel/selectors";
import type { LiteId, LiteNode, ProjektId } from "../modellierungModel/schemas";
import {
	LiteIdListSchema,
	ProjektType,
	isLiteEigenschaft,
} from "../modellierungModel/schemas";
import type {
	TreeStateQueryActorRef,
	TreeStateQueryContext,
} from "./treeState.query.machine";
import { getFromSystem, useActorRefSelector } from "../../../../util/machines";
import type { TreePathVariants } from "./helpers";
import {
	EMPTY_EXPANSION_STATE,
	createQueryId,
	getTreePathVariants,
} from "./helpers";
import { useSelectFromSystem } from "../../hooks";
import { pathEquals } from "../../../Tree/treeHelpers";

const EMPTY_PATH: LiteId[] = [];

export function getTreeStateQueryRef(
	system: AnyActorSystem,
	projektId: ProjektId | null | undefined,
): TreeStateQueryActorRef | null {
	if (!projektId) return null;
	return getFromSystem(system, createQueryId(projektId));
}

export function selectTreeStateFromSystem(
	system: AnyActorSystem,
	projektId: ProjektId | null | undefined,
): TreeStateQueryContext | null {
	const ref = getTreeStateQueryRef(system, projektId);
	return ref?.getSnapshot().context ?? null;
}

export function useTreeStateQueryRef(
	projektId: ProjektId | null | undefined,
): TreeStateQueryActorRef | null {
	return useSelectFromSystem((system) =>
		getTreeStateQueryRef(system, projektId),
	);
}

export function useTreeStateSnapshotSelector<TReturn>(
	projektId: ProjektId | null | undefined,
	selector: (snapshot: SnapshotFrom<TreeStateQueryActorRef>) => TReturn,
): TReturn | null {
	const ref = useTreeStateQueryRef(projektId);
	return useActorRefSelector(ref, selector);
}

export function useActiveTreeStateSnapshotSelector<TReturn>(
	selector: (snapshot: SnapshotFrom<TreeStateQueryActorRef>) => TReturn,
): TReturn | null {
	const projektId = useActiveProjectId();
	return useTreeStateSnapshotSelector(projektId, selector);
}

export function useActiveTreePathVariants(): TreePathVariants | null {
	const modellPath = useActiveModellPath();
	return useModellierungModellSelector(({ projekt }) => {
		if (modellPath?.length)
			return getTreePathVariants(
				projekt.modell,
				LiteIdListSchema.parse(modellPath),
			);
		const rootNode = selectBausteinRootNode(projekt);
		if (!rootNode) return null;
		return getTreePathVariants(projekt.modell, [rootNode.id]);
	});
}

export function useActiveBasePath(): LiteId[] {
	return useActiveTreePathVariants()?.basePath || EMPTY_PATH;
}

export function useActiveBausteinPath(): LiteId[] {
	return useActiveTreePathVariants()?.bausteinPath || EMPTY_PATH;
}

export function useActiveFullBausteinPath(): LiteId[] {
	return useActiveTreePathVariants()?.fullBausteinPath || EMPTY_PATH;
}

export function useActiveBausteinNodeId(): LiteId | null {
	const bausteinPath = useActiveFullBausteinPath();
	return bausteinPath.at(-1) || null;
}

export function useActiveBausteinExpansionState(): ExpansionTree<LiteId> {
	const expansionState = useActiveTreeStateSnapshotSelector(
		({ context }) => context.bausteinView.expansionState,
	);
	return expansionState || EMPTY_EXPANSION_STATE;
}

export function useActiveTreePath(): LiteId[] {
	return useActiveTreePathVariants()?.fullPath || EMPTY_PATH;
}

export function useActiveStructureNodeId(): LiteId | null {
	const fullPath = useActiveTreePath();
	return fullPath.at(-1) || null;
}

export function useAssertiveActiveStructureNodeId(): LiteId {
	const nodeId = useActiveStructureNodeId();
	if (!nodeId) {
		throw new Error(`Active project "${nodeId}" does not exist.`);
	}
	return nodeId;
}

export function useActiveStructureNode(): LiteNode | null {
	const nodeId = useActiveStructureNodeId();
	const node = useModellierungModellSelector(({ projekt }) =>
		nodeId ? createSelectNode(projekt)(nodeId) : null,
	);
	return node;
}

export function useActiveStructureExpansionState(): ExpansionTree<LiteId> {
	const expansionState = useActiveTreeStateSnapshotSelector(
		({ context }) => context.structureView.expansionState,
	);
	return expansionState || EMPTY_EXPANSION_STATE;
}

export function useNodeHierarchy(fullTreePath: LiteId[]): LiteNode[] | null {
	return useModellierungModellSelector((context) =>
		createSelectNodesByPath(context.projekt)(fullTreePath),
	);
}

export function useActiveStructureNodeHierarchy(): LiteNode[] | null {
	const activeTreePath = useActiveTreePath();
	return useNodeHierarchy(activeTreePath);
}

export function useIsDefinedInExtension(fullTreePath: LiteId[]): boolean {
	return (
		useModellierungModellSelector(({ projekt }) => {
			const hasParentDatatypeRef = fullTreePath
				.slice(0, -1)
				.some((parentId) => {
					const parent = selectNodeFromModell(projekt.modell, parentId);
					return parent && isLiteEigenschaft(parent) && parent.datentypReferenz;
				});
			if (hasParentDatatypeRef) return false;
			const nodeId = fullTreePath.at(-1);
			const definition = nodeId
				? createSelectDefinitionPathFromModell(projekt.modell)(nodeId)
				: [];
			return !pathEquals(fullTreePath, definition);
		}) ?? false
	);
}

export function useDefinedIn(nodeId: LiteId): LiteNode | null {
	return (
		useModellierungModellSelector(({ projekt }) => {
			const node = selectNodeFromModell(projekt.modell, nodeId);
			if (!node) return null;
			return node.parent && selectNodeFromModell(projekt.modell, node.parent);
		}) ?? null
	);
}

export function useIsNodeReadOnly(fullTreePath: LiteId[]): boolean {
	return (
		useModellierungModellSelector(({ projekt }) => {
			if (projekt.type === ProjektType.Standard) return false;
			const nodeId = fullTreePath.at(-1);
			const definition = nodeId
				? createSelectDefinitionPathFromModell(projekt.modell)(nodeId)
				: [];
			if (!pathEquals(fullTreePath, definition)) {
				return true;
			}
			const hierachy = createSelectNodesByPath(projekt)(fullTreePath);
			return !!hierachy
				?.slice(0, -1)
				?.some((node) => isLiteEigenschaft(node) && !!node.datentypReferenz);
		}) ?? true
	);
}

export function useIsActiveNodeReadOnly(): boolean {
	const activeTreePath = useActiveTreePath();
	return useIsNodeReadOnly(activeTreePath);
}
