import {
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	FormControlLabel,
	FormGroup,
} from "@mui/material";
import type { ChangeEvent, FormEventHandler, SetStateAction } from "react";
import { useEffect } from "react";
import { LoadingButton } from "@mui/lab";
import type { GridSelectionModel } from "@mui/x-data-grid";
import Transition from "../../ui/Transition";
import MessageSelection from "../../Configuration/MessageSelection";
import { useEventHandler } from "../../../hooks";
import GenerationBaseSelect from "./GenerationBaseSelect";
import ModalHeader from "../../ui/Modal/ModalHeader";
import type { PropsOf } from "../../../util/types";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
	selectGenerationConfiguration,
	setGenerationConfiguraton,
} from "../../../redux/configuration/configurationSlice";
import {
	CacheKeys,
	useGenerateMessagesMutation,
} from "../../../redux/messagesApiSlice";
import { selectValidationBase } from "../../../redux/messagesSlice";
import "./GenerationConfiguration.scss";
import { QNameSchema } from "../../AppActor/actors/modellierungModel/schemas";

const GenerationConfiguration = ({
	isConfigOpen,
	setIsConfigOpen,
}: {
	isConfigOpen: boolean;
	setIsConfigOpen: (value: SetStateAction<boolean>) => void;
}): JSX.Element => {
	const dispatch = useAppDispatch();
	const [generateMessages, { isLoading }] = useGenerateMessagesMutation({
		fixedCacheKey: CacheKeys.MessageGeneration,
	});

	const generationConfiguration = useAppSelector(
		selectGenerationConfiguration(),
	);

	const validationBase = useAppSelector(selectValidationBase());

	const closeDialog = useEventHandler(() => {
		if (isLoading) return;
		setIsConfigOpen(false);
	});

	const handleCloseButtonClick = () => {
		closeDialog();
	};

	const handleDialogClose: PropsOf<typeof Dialog>["onClose"] = (
		_event,
		reason,
	) => {
		if (reason === "backdropClick" || reason === "escapeKeyDown") {
			closeDialog();
		}
	};

	const handleSelectionChange = (newGridSelection: GridSelectionModel) => {
		dispatch(
			setGenerationConfiguraton({
				configuration: {
					nachrichten: QNameSchema.array().parse(newGridSelection),
				},
			}),
		);
	};

	const selectionModel = generationConfiguration.nachrichten || [];

	const { standard } = generationConfiguration;

	const handleSubmit: FormEventHandler<HTMLDivElement> = (e) => {
		e.preventDefault();
	};

	const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
		const { checked } = event.target;
		dispatch(
			setGenerationConfiguraton({
				configuration: { optionaleElementeGenerieren: checked },
			}),
		);
	};

	const { optionaleElementeGenerieren: includeOptional } =
		generationConfiguration;

	const handleGenerationClick = () => {
		generateMessages({ config: generationConfiguration, validationBase });
	};

	// The generation request has completed, so we close the modal
	useEffect(() => {
		if (!isLoading) {
			closeDialog();
		}
	}, [closeDialog, isLoading]);

	return (
		<>
			{isConfigOpen && (
				<Dialog
					className="generation-configuration"
					data-testid="generation-configuration"
					open={isConfigOpen}
					TransitionComponent={Transition}
					onClose={handleDialogClose}
					keepMounted
					maxWidth="md"
				>
					<ModalHeader
						title="Konfiguration der Nachrichtenerzeugung"
						className="generation-configuration__title"
						handleClose={handleCloseButtonClick}
					/>
					<DialogContent>
						<FormGroup
							className="generation-configuration__content"
							onSubmit={handleSubmit}
						>
							<FormControlLabel
								className="generation-configuration__generation-base"
								control={<GenerationBaseSelect />}
								label="Grundlage der Nachrichtenerzeugung"
								labelPlacement="start"
							/>
							<FormControlLabel
								control={
									<Checkbox
										name="includeOptional"
										checked={
											includeOptional !== undefined ? includeOptional : true
										}
										onChange={handleChange}
									/>
								}
								label="Optionale Elemente generieren"
							/>
							{standard && (
								<MessageSelection
									standard={standard?.kennung}
									headerTitle="Nachrichten auswählen"
									selectionModel={selectionModel}
									handleSelectionChange={handleSelectionChange}
								/>
							)}
						</FormGroup>
					</DialogContent>
					<DialogActions>
						<Button
							variant="outlined"
							onClick={handleCloseButtonClick}
							disabled={isLoading}
							data-testid="close-dialog"
						>
							Schließen
						</Button>
						<LoadingButton
							disabled={!selectionModel.length}
							loading={isLoading}
							onClick={handleGenerationClick}
							variant="contained"
							data-testid="send-config"
						>
							Beispielnachricht generieren
						</LoadingButton>
					</DialogActions>
				</Dialog>
			)}
		</>
	);
};

export default GenerationConfiguration;
