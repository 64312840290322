import { useGridApiContext } from "@mui/x-data-grid";
import type { ChangeEvent, KeyboardEventHandler } from "react";
import { useState } from "react";
import { Checkbox, Tooltip } from "@mui/material";
import { useStateSelector } from "../../../../../EditorState";
import { selectRefItemSelectabilityStatus } from "../../../../../EditorState/selectors";
import type { CellParams, SpecificRefItem } from "./types";
import { RefType } from "./types";
import { joinIdSegments, segmentizeId } from "../../../../../../util/xoev";
import { InvalidRefTypeError } from "./refIds";
import type { SelectablilityStatus } from "../../../../../EditorState/types";
import { SelectablilityCode } from "../../../../../EditorState/types";
import useActiveRestriction from "../../../../useActiveRestriction";
import OverrideDialog from "../../../../../Profiling/ProfilingEditForm/DatatypeSelect/OverrideDialog";

const ENABLED_STATUS_CODES = [
	SelectablilityCode.None,
	SelectablilityCode.CodelistValues,
];

function formatId(profileId: string, ref: SpecificRefItem) {
	if (ref.type === RefType.MessageElement) return profileId;
	if (ref.type === RefType.Datatype) {
		const [, ...restSegments] = segmentizeId(profileId);
		return joinIdSegments([ref.restrictionName, ...restSegments]);
	}
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	throw new InvalidRefTypeError((ref as any).type);
}

function createTitle(status: SelectablilityStatus, ref: SpecificRefItem) {
	if (
		status.code === SelectablilityCode.StandardView ||
		status.code === SelectablilityCode.None
	) {
		return null;
	}
	if (status.code === SelectablilityCode.CodelistValues) {
		return `Beim auswählen des Datentyps wird die bestehende Profilierung des Elements gelöscht.`;
	}
	if (status.code === SelectablilityCode.SiblingRestriction) {
		return (
			`Dieses Element ist bereits durch das Profil ` +
			`${status.restrictionName} eingeschränkt.`
		);
	}
	const parentChildLabel =
		status.code === SelectablilityCode.Child ? "Kindelement" : "Elternelement";
	const id = formatId(status.profileId, ref);
	return (
		`Das Element kann nicht eingeschränkt werden, da das ${parentChildLabel} ` +
		`"${id}" bereits profiliert ist.`
	);
}

export default function SelectionTableCheckbox({
	params,
}: {
	params: CellParams<boolean>;
}): JSX.Element {
	const apiRef = useGridApiContext();
	const activeRestriction = useActiveRestriction();
	const selectabilityStatus = useStateSelector(
		selectRefItemSelectabilityStatus(params.row, activeRestriction),
	);
	const [isOpen, setIsOpen] = useState(false);

	const handleCheck = (nextValue: boolean) => {
		const selectedRows = apiRef.current.getSelectedRows();
		const selectedIds = new Set(selectedRows.keys());
		if (nextValue) {
			selectedIds.add(params.id);
		} else {
			selectedIds.delete(params.id);
		}
		apiRef.current.setSelectionModel([...selectedIds]);
	};

	const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
		if (
			selectabilityStatus.code === SelectablilityCode.CodelistValues &&
			!params.value
		) {
			setIsOpen(true);
		} else {
			handleCheck(event.target.checked);
		}
	};

	const handleAccept = () => {
		handleCheck(!params.value);
		setIsOpen(false);
	};
	const handleDismiss = () => {
		setIsOpen(false);
	};

	const handleKeyDown: KeyboardEventHandler<HTMLButtonElement> = (event) => {
		if (event.key === " " || event.key === "Enter") {
			event.stopPropagation();
		}

		const isNavigationKey = (key: string) =>
			key === "Home" ||
			key === "End" ||
			key.includes("Arrow") ||
			key.includes("Page") ||
			key === " ";
		if (isNavigationKey(event.key) && !event.shiftKey) {
			apiRef.current.publishEvent("cellNavigationKeyDown", params, event);
		}
	};

	const checkbox = (
		<Checkbox
			checked={params.value}
			disabled={!ENABLED_STATUS_CODES.includes(selectabilityStatus.code)}
			onChange={handleChange}
			onKeyDown={handleKeyDown}
			data-testid="type-selection-checkbox"
			data-node-qname={params.row.qnamePath}
		/>
	);

	const title = createTitle(selectabilityStatus, params.row);
	if (!title) return checkbox;

	return (
		<Tooltip title={title}>
			<span>
				{checkbox}
				{selectabilityStatus.code === SelectablilityCode.CodelistValues && (
					<OverrideDialog
						isOpen={isOpen}
						onAccept={handleAccept}
						onDismiss={handleDismiss}
						isCodeListOverride
					/>
				)}
			</span>
		</Tooltip>
	);
}
