import DatatypeRefIcon from "@mui/icons-material/Link";
import AnonymousDatatypeIcon from "@mui/icons-material/DataObject";
import DefinedInExtensionIcon from "@mui/icons-material/MergeType";
import type { MouseEvent } from "react";
import {
	useIsCodeliste,
	useModellierungProjekt,
} from "../../AppActor/actors/modellierungModel/hooks";
import {
	createSelectInfiniteChildren,
	createSelectIsRecursive,
	createSelectNode,
	selectNodeFromModell,
} from "../../AppActor/actors/modellierungModel/selectors";
import {
	isLiteEigenschaft,
	isLiteNachricht,
	joinLitePath,
	type LiteId,
	type LiteNode,
} from "../../AppActor/actors/modellierungModel/schemas";
import {
	useActiveBausteinNodeId,
	useDefinedIn,
	useIsDefinedInExtension,
	useIsNodeReadOnly,
} from "../../AppActor/actors/treeState/hooks";
import RecursionIcon from "../../Icons/RecursionIcon";
import InteractiveNode from "../../InteractiveNode";
import type { NodeElementProps, NodeEventArg } from "../../Tree";
import Tree from "../../Tree";
import LiteNodeIcon from "../LiteNodeIcon";
import LiteNodeKindIcon from "../LiteNodeKindIcon";
import type { StandardProjekt } from "../../AppActor/actors/project/types";
import type { Nullish } from "../../../util/types";
import ContextMenu from "../ContextMenu";
import { pathEquals } from "../../Tree/treeHelpers";
import ChoiceIcon from "../../Icons/ChoiceIcon";
import {
	isChoice,
	isGroupTypeAll,
} from "../../AppActor/actors/modellierungModel/helpers";
import CodeListIcon from "../../Icons/CodeListIcon";
import { useIsProjektDisplayOnly } from "../../AppActor/actors/project/hooks";
import ValidationLabel from "../../Validation/ValidationLabel";
import { useValidationSeverity } from "../../ValidationDisplayLite/useValidationResults";
import "./ModelTreeNode.scss";
import AllIcon from "../../Icons/AllIcon";

function createTitle(projekt: Nullish<StandardProjekt>, node: LiteNode) {
	const selectNode = createSelectNode(projekt);
	const refName =
		(isLiteEigenschaft(node) &&
			node.datentypReferenz &&
			selectNode(node.datentypReferenz)?.name) ||
		"Unbekannter Datentyp";
	return `Referenziert Datentyp "${refName}". Kindelemente können nur im Datentyp bearbeitet werden`;
}

export default function ModelTreeNode({
	activePath,
	node,
	path,
	isExpanded,
	rootPath,
	getProps,
	NodeMenu,
	onActivate,
	isMarked,
}: {
	activePath: LiteId[];
	node: LiteNode;
	path: LiteId[];
	isExpanded: boolean;
	rootPath: LiteId[];
	getProps: () => NodeElementProps;
	NodeMenu: (props: { isOpen: boolean; closeMenu: () => void }) => JSX.Element;
	onActivate?: (event: NodeEventArg<LiteNode, LiteId>) => void;
	isMarked?: boolean;
}): JSX.Element {
	const fullPath = [...rootPath, ...path];
	const projekt = useModellierungProjekt();
	const isDisplayOnly = useIsProjektDisplayOnly();
	const selectIsRecursive = createSelectIsRecursive(projekt);
	const selectInfiniteChildren = createSelectInfiniteChildren(projekt);
	const isActive = pathEquals(activePath, fullPath);
	const isReadOnlyByPath = useIsNodeReadOnly(fullPath);
	const isDefiniedInExtension = useIsDefinedInExtension(fullPath);
	const definedIn = useDefinedIn(node.id);
	const isReadOnly = !!rootPath && isReadOnlyByPath;
	const isCodeliste = useIsCodeliste(node);
	const severity = useValidationSeverity(fullPath);

	const createHandleContextMenu =
		(handleContextMenu: (e: MouseEvent) => void) => (e: MouseEvent) => {
			onActivate?.({ node, path });
			handleContextMenu(e);
		};

	const parentNodeId = useActiveBausteinNodeId();
	const parentNode =
		(projekt &&
			parentNodeId &&
			selectNodeFromModell(projekt.modell, parentNodeId)) ||
		node;

	return (
		<ContextMenu disabled={isDisplayOnly}>
			{({ closeMenu, handleContextMenu, isOpen }) => (
				<>
					<InteractiveNode
						{...getProps()}
						data-testid="model-tree-node"
						data-node-id={node.id}
						data-node-name={node.name}
						data-node-path={joinLitePath(fullPath)}
						data-is-expanded={isExpanded}
						data-is-marked={isMarked}
						isActive={isActive}
						isDisabled={isReadOnly}
						onContextMenu={createHandleContextMenu(handleContextMenu)}
					>
						<Tree.NodeToggle />
						<LiteNodeKindIcon node={node} />
						<ValidationLabel severity={severity}>
							<span className="model-tree-node__name">
								{isMarked ? <mark>{node.name}</mark> : node.name}
							</span>
						</ValidationLabel>
						<RecursionIcon isRecursive={selectIsRecursive(node)} />
						{isChoice(node) && <ChoiceIcon />}
						{isGroupTypeAll(node) && <AllIcon />}

						{isLiteEigenschaft(node) &&
							node.datentypReferenz &&
							selectInfiniteChildren(node).length > 0 && (
								<LiteNodeIcon
									icon={DatatypeRefIcon}
									title={createTitle(projekt, node)}
								/>
							)}
						{isLiteEigenschaft(node) && !node.datentypReferenz && (
							<LiteNodeIcon
								icon={AnonymousDatatypeIcon}
								title="Anonymer Datentyp"
							/>
						)}
						{isDefiniedInExtension && (
							<LiteNodeIcon
								icon={DefinedInExtensionIcon}
								title={`Außerhalb ${
									isLiteNachricht(parentNode)
										? "der Nachricht"
										: "des Datentyps"
								} definiert in "${definedIn?.name}"`}
							/>
						)}
						<CodeListIcon isCodeList={isCodeliste} />
					</InteractiveNode>
					<ContextMenu.Menu>
						<NodeMenu closeMenu={closeMenu} isOpen={isOpen} />
					</ContextMenu.Menu>
				</>
			)}
		</ContextMenu>
	);
}
