import ListNode from "../../../Profiling/ListNode";
import useActiveDocTitle from "../../useActiveDocTitle";
import { filterTitlesBySearchterm, generateListItemText } from "../helpers";
import type { BaseListProps } from "../types";
import "./HiddenChaptersList.scss";

const HiddenChaptersList = ({
	docs,
	searchTerm,
	onActivate,
}: BaseListProps): JSX.Element => {
	const [activeDocTitleKey] = useActiveDocTitle();
	const docTitles = filterTitlesBySearchterm(docs, searchTerm);

	const createHandleActivate = (docTitle: string) => () => {
		onActivate(docTitle);
	};

	const createHandleItemKeyDown =
		(docTitle: string) => (e: React.KeyboardEvent<HTMLInputElement>) => {
			if (e.key === " " || e.key === "Enter") {
				createHandleActivate(docTitle)();
			}
		};

	return (
		<ul className="hidden-chapters-list">
			{docTitles.map((docTitle) => (
				<ListNode
					as="li"
					className="hidden-chapters-list__item"
					data-testid="hidden-chapters-list__item"
					data-node-id={docTitle}
					key={docTitle}
					onClick={createHandleActivate(docTitle)}
					tabIndex={0}
					role="menuitem"
					onKeyDown={createHandleItemKeyDown(docTitle)}
					isActive={activeDocTitleKey === docTitle}
				>
					{generateListItemText(docs, docTitle)}
				</ListNode>
			))}
		</ul>
	);
};

export default HiddenChaptersList;
