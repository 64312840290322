import classNames from "classnames";
import type { DetailedHTMLProps, HTMLAttributes, ReactNode } from "react";
import { extraProps } from "../../util/props";
import type { ExtendProps } from "../../util/types";
import "./InactivePageGuard.scss";

type DivProps = DetailedHTMLProps<
	HTMLAttributes<HTMLDivElement>,
	HTMLDivElement
>;
export type InactivePageGuardProps = ExtendProps<
	DivProps,
	{
		className?: string;
		children: ReactNode;
		heading?: ReactNode;
		description?: ReactNode;
		isLocked?: boolean;
	}
>;

export default function InactivePageGuard({
	className,
	children,
	heading,
	description,
	isLocked = true,
	...props
}: InactivePageGuardProps): JSX.Element {
	return (
		<div
			{...props}
			className={classNames(
				"inactive-page-guard",
				isLocked && "inactive-page-guard--locked",
				className,
			)}
		>
			<div
				className="inactive-page-guard__guarded-content"
				{...extraProps(isLocked && { "aria-hidden": true })}
			>
				{children}
			</div>

			{isLocked && (
				<div className="inactive-page-guard__overlay">
					<div className="inactive-page-guard__overlay-content">
						{heading && (
							<h2 className="inactive-page-guard__heading">{heading}</h2>
						)}
						{description && (
							<p className="inactive-page-guard__description">{description}</p>
						)}
					</div>
				</div>
			)}
		</div>
	);
}
