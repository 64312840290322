import type { ActorRefFrom } from "xstate";
import { assertEvent, assign, setup } from "xstate";
import { translateStoreEvents } from "../../EventStore/helpers";
import type { ProjektId, RawLiteModel } from "../modellierungModel/schemas";
import treeStateMachine from "../treeState/treeState.machine";
import modellierungModellMachine from "../modellierungModel/modellierungModel.machine";
import type { ProjektMeta } from "./types";
import { omit } from "../../../../util/object";
import { createLiteProject } from "../modellierungModel/helpers";

// eslint-disable-next-line no-use-before-define
export type ProjectQueryActorRef = ActorRefFrom<typeof projectQueryMachine>;
export type ProjectQueryContext = ProjektMeta & { rawModel?: RawLiteModel };
export type ProjectQueryEvent = {
	type: "LOAD_SUCCESS";
	projektId: ProjektId;
	projektMeta: ProjektMeta;
	rawModel: RawLiteModel;
};

const projectQueryMachine = setup({
	types: {
		events: {} as ProjectQueryEvent,
		context: {} as ProjectQueryContext,
		input: {} as ProjektMeta,
	},
	actors: {
		modellierungModell: modellierungModellMachine,
		treeState: treeStateMachine,
		translateIdleEvents: translateStoreEvents<ProjectQueryEvent>(
			{
				"PROJECT.OPEN_EXISTING.SUCCESS": ({
					payload: { rawModel, projektMeta },
				}) => ({
					type: "LOAD_SUCCESS",
					rawModel,
					projektMeta,
					projektId: projektMeta.projektId,
				}),
			},
			{ replayEventLog: true },
		),
	},
	actions: {
		spawnModell: assign(({ spawn, context, event }) => {
			assertEvent(event, "LOAD_SUCCESS");
			if (context.rawModel) {
				spawn("modellierungModell", {
					id: `modellierungModell:${context.projektId}`,
					systemId: `modellierungModell:${context.projektId}`,
					input: {
						...context,
						rawModel: context.rawModel,
						projekt: createLiteProject(
							context.rawModel,
							event.projektMeta,
							"Neues Modellierungsprojekt",
						),
					},
				});
			}
			return {};
		}),
		spawnTreeState: assign(({ spawn, context, event }) => {
			assertEvent(event, "LOAD_SUCCESS");
			spawn("treeState", {
				id: `treeState:${context.projektId}`,
				systemId: `treeState:${context.projektId}`,
				input: omit(context, ["rawModel"]),
			});
			return {};
		}),
		saveRawModel: assign(({ event }) => {
			assertEvent(event, "LOAD_SUCCESS");
			return { rawModel: event.rawModel };
		}),
	},
	guards: {
		isSelf: ({ event, context }) => {
			return event.projektId === context.projektId;
		},
	},
}).createMachine({
	id: "project:query",
	context: ({ input }) => ({ ...input }),
	invoke: { src: "translateIdleEvents" },
	on: {
		LOAD_SUCCESS: {
			guard: "isSelf",
			actions: ["saveRawModel", "spawnModell", "spawnTreeState"],
		},
	},
});

export default projectQueryMachine;
