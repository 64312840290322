import { useSelector } from "@xstate/react";
import type { AnyActorSystem } from "xstate/dist/declarations/src/system";
import { getFromSystem } from "../../../../util/machines";
import type { ProjectsActorRef } from "./projects.machine";
import { memoize } from "../../../../util/memoization";
import type {
	ProjectsQueryActorRef,
	ProjectsQueryContext,
} from "./projects.query.machine";
import type { ProjectsCommandActorRef } from "./projects.command.machine";
import { useSelectFromSystem } from "../../hooks";
import { ProjektIdSchema } from "../modellierungModel/schemas";
import type { ProjectActorRef } from "../project/project.machine";

export function getProjectsQueryFromSystem(
	system: AnyActorSystem,
): ProjectsQueryActorRef {
	return getFromSystem(system, "projects:query");
}

export function useProjectsRef(): ProjectsActorRef {
	return useSelectFromSystem((system) => system.get("projects"));
}

export function useProjectsQueryRef(): ProjectsQueryActorRef {
	return useSelectFromSystem((system) => getProjectsQueryFromSystem(system));
}

export function useProjectsCommandRef(): ProjectsCommandActorRef {
	return useSelectFromSystem((system) => system.get("projects:command"));
}

export function useProjectTabActorRefRecord(): ProjectsQueryContext["projects"] {
	const projectsRef = useProjectsQueryRef();
	return useSelector(projectsRef, ({ context }) => context.projects);
}

export const selectProjektIds = memoize(
	(projects: ProjectsQueryContext["projects"]) =>
		ProjektIdSchema.array().parse(Object.keys(projects)),
);
export function useProjektIds() {
	const projectsRef = useProjectsQueryRef();
	return useSelector(projectsRef, ({ context }) =>
		selectProjektIds(context.projects),
	);
}

export const selectProjektActorRefs = memoize(
	(projects: ProjectsQueryContext["projects"]): ProjectActorRef[] =>
		Object.values(projects),
);
export function useProjektActorRefs(): ProjectActorRef[] {
	const projectsRef = useProjectsQueryRef();
	return useSelector(projectsRef, ({ context }) =>
		selectProjektActorRefs(context.projects),
	);
}

export const selectProjects = memoize(
	(projects: ProjectsQueryContext["projects"]) =>
		Object.entries(projects).map(([projektId, ref]) => ({
			projektId: ProjektIdSchema.parse(projektId),
			data: ref.getSnapshot().context,
		})),
);
export function useProjectList() {
	const projectsRef = useProjectsQueryRef();
	return useSelector(projectsRef, ({ context }) =>
		selectProjects(context.projects),
	);
}
