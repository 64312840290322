import { Autocomplete, Chip, IconButton, TextField } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditFormFieldLabel from "../../EditFormField/EditFormFieldLabel";
import type InputFieldWithInlineChipsProps from "./types";
import type { PropsOf } from "../../../util/types";

const InputFieldWithInlineChips = ({
	inputId,
	isReadOnly,
	dataFieldId,
	inputDataTestId,
	addButtonDataTestId,
	chipTestField,
	values,
	inputValue,
	isAddDisabled,
	label,
	options,
	ariaDescribedBy,
	handleInputChange,
	createHandleDelete,
	onKeyDown,
	handleChange,
}: InputFieldWithInlineChipsProps): JSX.Element => {
	return (
		<EditFormFieldLabel text={<span>{label}</span>} htmlFor={inputId}>
			<Autocomplete
				id={inputId}
				className="multi-value-renderer__autocomplete"
				data-testid="multi-value-renderer"
				disabled={isReadOnly}
				freeSolo
				multiple
				disableClearable
				autoSelect
				fullWidth
				aria-describedby={ariaDescribedBy}
				options={options ?? []}
				value={values}
				onChange={handleChange}
				inputValue={inputValue}
				onKeyDown={onKeyDown as PropsOf<typeof Autocomplete>["onKeyDown"]}
				onInputChange={handleInputChange}
				renderTags={(value: readonly string[], getTagProps) =>
					value.map((option: string, index: number) => (
						// eslint-disable-next-line react/jsx-key
						<Chip
							variant="outlined"
							size="small"
							data-testid={chipTestField}
							data-chipid={option}
							label={option}
							{...getTagProps({ index })}
							onDelete={createHandleDelete(option)}
						/>
					))
				}
				renderInput={(props) => (
					<TextField
						{...props}
						inputProps={{
							...props.inputProps,
							"data-field-id": dataFieldId,
							"data-testid": inputDataTestId,
						}}
						// eslint-disable-next-line react/jsx-no-duplicate-props
						InputProps={{
							...props.InputProps,
							endAdornment: (
								<IconButton
									onClick={handleChange}
									disabled={isAddDisabled}
									data-testid={addButtonDataTestId}
								>
									<AddIcon />
								</IconButton>
							),
						}}
					/>
				)}
			/>
		</EditFormFieldLabel>
	);
};

export default InputFieldWithInlineChips;
