import { useCallback, useMemo } from "react";
import type { StructureTreeProps } from "./types";
import { TreeDisplayState } from "./types";
import useSearchFilter from "./useSearchFilter";
import { RequestStatus } from "../../Api";
import RequestErrorNotification from "../../RequestErrorNotification";
import StructureTreeSearchInfo from "./StructureTreeSearchInfo";
import { useHtmlId } from "../../../hooks";
import { extraProps } from "../../../util/props";
import Tree, { useExpansionState } from "../../Tree";
import ProfilingNode from "../../Profiling/ProfilingNode";
import useTree from "../../Profiling/useTree";
import NoSearchResultsInfo from "./NoSearchResultsInfo";
import useLoaderAttributes from "../../../hooks/useLoaderAttributes";
import useDelayedValue from "../../../hooks/useDelayedValue";
import useUrlTreeState from "../../MessageProfilingView/useUrlTreeState";
import EditorSideBar from "../../EditorSideBar";
import { getTreeDisplayState, shouldFilterNode } from "./helpers";
import StructureNoDataInfo from "../StructureInlineInfo/StructureNoDataInfo";
import StructureLoadingInfo from "../StructureInlineInfo/StructureLoadingInfo";
import { STRUCTURE_LOADING_SMOOTHING_DELAY } from "../helpers";
import { useAppSelector } from "../../../redux/hooks";
import { selectModellContainer } from "../../../redux/treeSlice";
import { isLiteDatatype } from "../../AppActor/actors/modellierungModel/schemas";
import type {
	LiteNode,
	LiteId,
} from "../../AppActor/actors/modellierungModel/schemas";
import {
	createSelectChildrenFromModell,
	selectNodeId,
	selectQNamePathFromModell,
} from "../../AppActor/actors/modellierungModel/selectors";
import { AssertionError } from "../../../util/error";
import { pathEquals } from "../../Tree/treeHelpers";
import { isSearchMatch } from "../../AppActor/actors/modellierungModel/search/helpers";
import "./StructureTree.scss";

function StructureTree({
	rootId,
	profiles,
	standard,
	isFilterMatch: isFilterMatchProp,
	getUrl,
	activeNodeState,
	nodeProps,
	filterGroup,
	renderNodeName,
	parentStatus = RequestStatus.Success,
	searchInputClassName,
}: StructureTreeProps): JSX.Element {
	const { tree, status: treeStatus, isFallback } = useTree(standard, rootId);

	const modell = useAppSelector(selectModellContainer(standard));
	const getChildrenFromModell = useCallback(
		(n: LiteNode, path: LiteId[]) =>
			(modell && createSelectChildrenFromModell(modell)(n, path)) || [],
		[modell],
	);
	const getChildren = useCallback(
		(node: LiteNode, path: LiteId[]) => {
			const childNodes = getChildrenFromModell(node, path);
			return childNodes.filter((childNode) => !isLiteDatatype(childNode));
		},
		[getChildrenFromModell],
	);
	const isExpandable = useCallback(
		(n: LiteNode, path: LiteId[]) => getChildren(n, path).length > 0,
		[getChildren],
	);

	const { expansionState, closePath, openPath } = useExpansionState<LiteId>([
		rootId,
	]);
	const { handleActivate, handleClose, handleOpen } = useUrlTreeState({
		activeNodeState,
		closePath,
		openPath,
		getDomPath(path) {
			if (!modell) {
				return null;
			}
			const qnamePath = selectQNamePathFromModell(modell, path);
			return document.querySelector<HTMLElement>(
				`[data-node-qname="${qnamePath}"]`,
			);
		},
		getUrl,
	});

	const root = useMemo(() => [tree], [tree]);
	const { activePath } = activeNodeState;

	const {
		getInputProps,
		trie,
		isActive: isSearchFilterActive,
		status,
		error,
		focusPrevious,
		focusNext,
		setCurrentIndex,
	} = useSearchFilter({
		rootId,
		getUrl,
		activeNodeState,
		filterGroup,
		standard,
	});
	const hasNoSearchResults = useMemo(
		() => isSearchFilterActive && !trie.size,
		[isSearchFilterActive, trie],
	);
	const isSuccess = status === RequestStatus.Success;
	const shouldShowSearchInfo =
		!hasNoSearchResults && isSearchFilterActive && isSuccess;

	const searchInfoId = useHtmlId();

	const getFilterMatch = (node: LiteNode, path: LiteId[]) => {
		AssertionError.notNullish(modell);
		if (shouldFilterNode(modell, rootId, node)) {
			return { isFilterMatch: false, isExactSearchMatch: false };
		}

		const isExactSearchMatch = !isSearchFilterActive || trie.has(path);
		const isSearchFilterMatch =
			!isSearchFilterActive || isSearchMatch(trie, path);
		const isFilterMatch = isFilterMatchProp
			? isSearchFilterMatch && isFilterMatchProp(node)
			: isSearchFilterMatch;
		return { isFilterMatch, isExactSearchMatch };
	};

	// While the project is still loading, we show the loading indicator as well
	// since the request for the tree only starts after the project load has
	// finished. It is a little unstable though, since there can be points in
	// time where `parentStatus` is "success", and `treeStatus` is "idle", but
	// just about to turn to "loading". We smooth over that using the delayed
	// value below
	const unstableIsLoading =
		treeStatus === RequestStatus.Loading ||
		parentStatus === RequestStatus.Loading;
	// Delay the disapearing of the loading indicator slightly, so the ui does
	// not flicker while calculating its next version
	const delayedIsLoading = useDelayedValue(
		unstableIsLoading,
		STRUCTURE_LOADING_SMOOTHING_DELAY,
	);
	const isLoading = unstableIsLoading || delayedIsLoading;
	const displayState = getTreeDisplayState({ isLoading, isFallback });
	// Mark the wrapping element as a loading indicator for screen readers
	const treeLoaderProps = useLoaderAttributes({
		isLoading,
		description: "Die Strukturdaten werden geladen.",
	});

	return (
		<>
			<RequestErrorNotification
				id="structure-tree-search-error"
				error={error}
				status={status}
			>
				Bei der Suche ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.
			</RequestErrorNotification>
			<EditorSideBar.Search
				{...getInputProps()}
				className={searchInputClassName}
				label="Nachrichtenstrukturen durchsuchen"
				{...extraProps(
					shouldShowSearchInfo && { "aria-describedby": searchInfoId },
				)}
				loading={status === RequestStatus.Loading}
				disabled={displayState !== TreeDisplayState.ShowTree}
			/>
			<EditorSideBar.Scroller
				className="structure-tree__scroller"
				{...treeLoaderProps}
			>
				{displayState === TreeDisplayState.ShowTree && (
					<>
						{hasNoSearchResults && isSuccess && (
							<NoSearchResultsInfo infoKey="noSearchResultsTree" />
						)}
						{shouldShowSearchInfo && (
							<StructureTreeSearchInfo
								activePath={activePath}
								focusNext={focusNext}
								focusPrevious={focusPrevious}
								treeStatus={treeStatus}
								trie={trie}
								setCurrentIndex={setCurrentIndex}
								id={searchInfoId}
							/>
						)}
						<Tree<LiteNode, LiteId>
							className="structure-tree"
							rootNodes={root}
							onOpen={handleOpen}
							onActivate={handleActivate}
							onClose={handleClose}
							expansionState={expansionState}
							// Only render the node when filterMode is inactive or the node or
							// one of its descendants is profiled
							renderNode={({ node, path, getProps, isExpanded }) => {
								const { isExactSearchMatch, isFilterMatch } = getFilterMatch(
									node,
									path,
								);
								return isFilterMatch ? (
									<ProfilingNode
										node={node}
										nodeProps={getProps()}
										path={path}
										profiles={profiles}
										root={root}
										renderNodeName={renderNodeName}
										isActive={pathEquals(activeNodeState.activePath, path)}
										isMarked={isSearchFilterActive && isExactSearchMatch}
										isExpanded={isExpanded}
										{...(nodeProps || {})}
									/>
								) : (
									<></>
								);
							}}
							getChildren={getChildren}
							getNodeId={selectNodeId}
							isNodeExpandable={isExpandable}
						/>
					</>
				)}
				<StructureNoDataInfo
					isActive={displayState === TreeDisplayState.ShowFallback}
				>
					Kein Nachrichtenbaum verfügbar
				</StructureNoDataInfo>
				<StructureLoadingInfo
					isActive={displayState === TreeDisplayState.ShowLoading}
				>
					Nachrichtenbaum wird geladen...
				</StructureLoadingInfo>
			</EditorSideBar.Scroller>
		</>
	);
}

export default StructureTree;
