import { memoize } from "@xoev/memo";
import type { ImmutableMap } from "@xoev/immutable-map";
import { fields } from "../../InfoNodeEditView/InfoNodeEditForm/infoNodeFormHelpers";
import FormFieldRenderer from "../../InfoNodeEditView/InfoNodeEditForm/FormFieldRenderer/FormFieldRenderer";
import type { TreeNodeType } from "../types";
import { useActiveNode } from "../../MessageProfilingView/useActiveNode";
import { useStateSelector } from "../../EditorState";
import {
	selectIsRestrictionDeleteVisible,
	selectIsProfileDeleteVisible,
	selectStandard,
} from "../../EditorState/selectors";
import useActiveDataType from "../../DatatypesView/useActiveDataType";
import useActiveRestrictionProfile from "../../DatatypesView/useActiveRestrictionProfile";
import useActiveRestriction from "../../DatatypesView/useActiveRestriction";
import DatatypeSelect from "./DatatypeSelect";
import DeleteDialoag from "./DeleteDialog";
import useSectionDependentProfile from "../useSectionDependentProfile";
import {
	RestrictionIdSchema,
	type MessageProfileConfiguration,
	type MessageProfileValues,
} from "../../EditorState/types";
import type {
	ValidationTargetField,
	AutoFixValue,
} from "../../Validation/types";
import type { QNamePath } from "../../AppActor/actors/modellierungModel/schemas";
import { isLiteEigenschaft } from "../../AppActor/actors/modellierungModel/schemas";
import { useAppSelector } from "../../../redux/hooks";
import {
	selectModellContainer,
	selectQName,
	selectQNamePath,
} from "../../../redux/treeSlice";
import type { Nullish } from "../../../util/types";
import type { RootState } from "../../../redux/store";
import type { LiteModellContainer } from "../../AppActor/actors/modellierungModel/types";
import { AssertionError } from "../../../util/error";
import type { FieldRendererDefinition } from "../../InfoNodeEditView/InfoNodeEditForm/FormFieldRenderer/types";
import "./ProfilingEditForm.scss";
import {
	LiteKind,
	getLiteNodeKind,
} from "../../AppActor/actors/modellierungModel/LiteKind";
import { selectDatatypeOrReferenceFromModell } from "../../AppActor/actors/modellierungModel/selectors";

const FIELD_NAMES = [
	"beschreibung",
	"umsetzungshinweis",
	"istRekursionsStart",
	"defaultWert",
	"fixedWert",
	"beispielWert",
	"beispielWertNachricht",
	"eigenschaften",
];
const MULTIPLIZITAET_NAMES = ["lowerBound", "upperBound"];
const CODE_VALUE_FIELD_NAMES = [
	"nutzungsArt",
	"version",
	"kennung",
	"codeliste",
];

const EMPTY_FIELDS: FieldRendererDefinition[] = [];

const selectFieldsFromModell = memoize(
	(
		modell: LiteModellContainer,
		activeNode: TreeNodeType,
		isParentProfiledDatatype: boolean,
		hasParentZeroCardinality: boolean,
		profile: ImmutableMap<Partial<MessageProfileValues>>,
	) => {
		const datatype = selectDatatypeOrReferenceFromModell(modell, activeNode);
		const isCodeValue =
			!!datatype && getLiteNodeKind(datatype) === LiteKind.CodeDatentyp;
		const isCodeListWithDatatypeRef =
			isCodeValue && !!profile.get("datentypReferenz");
		return fields
			.filter(
				(field) =>
					FIELD_NAMES.includes(field.name) ||
					(isLiteEigenschaft(activeNode) &&
						MULTIPLIZITAET_NAMES.includes(field.name)) ||
					(isCodeValue && CODE_VALUE_FIELD_NAMES.includes(field.name)),
			)
			.map((field) =>
				isParentProfiledDatatype ||
				hasParentZeroCardinality ||
				(isCodeListWithDatatypeRef &&
					CODE_VALUE_FIELD_NAMES.includes(field.name))
					? { ...field, readOnly: true }
					: field,
			);
	},
);

function selectFields(
	standard: Nullish<string>,
	activeNode: TreeNodeType,
	isParentProfiledDatatype: boolean,
	hasParentZeroCardinality: boolean,
	profile: ImmutableMap<Partial<MessageProfileValues>>,
) {
	return (state: RootState) => {
		const modell = selectModellContainer(standard)(state);
		if (!modell) return EMPTY_FIELDS;
		return selectFieldsFromModell(
			modell,
			activeNode,
			isParentProfiledDatatype,
			hasParentZeroCardinality,
			profile,
		);
	};
}

const ProfilingEditForm = (): JSX.Element => {
	const { activeNode: activeMessageNode, activePath: activeMessagePath } =
		useActiveNode();
	const { activeDataType } = useActiveDataType();
	const activeRestriction = useActiveRestriction();
	const {
		activeNode: activeRestrictionNode,
		activePath: activeRestrictionPath,
	} = useActiveRestrictionProfile(activeDataType?.id);

	const isRestrictionProfile = !!activeRestrictionNode.id;
	const activeNode = isRestrictionProfile
		? activeRestrictionNode
		: activeMessageNode;
	const activePath = isRestrictionProfile
		? activeRestrictionPath
		: activeMessagePath;
	const standard = useStateSelector(selectStandard());
	const activeQNamePath = useAppSelector(selectQNamePath(standard, activePath));
	const datatypeQName = useAppSelector(selectQName(standard, activeDataType));

	const {
		profile,
		deleteProfile,
		setValueForProfile,
		isParentProfiledDatatype,
		hasParentZeroCardinality,
	} = useSectionDependentProfile(activePath);
	const profilingFields = useAppSelector(
		selectFields(
			standard,
			activeNode,
			isParentProfiledDatatype,
			hasParentZeroCardinality,
			profile,
		),
	);

	const createIsDeleteVisibleSelector = (qnamePath: QNamePath) =>
		isRestrictionProfile
			? selectIsRestrictionDeleteVisible(
					AssertionError.asNotNullish(datatypeQName),
					RestrictionIdSchema.parse(activeRestriction?.get("id")),
					qnamePath,
			  )
			: selectIsProfileDeleteVisible(qnamePath);
	const isDeleteVisible = useStateSelector((state) =>
		activeQNamePath
			? createIsDeleteVisibleSelector(activeQNamePath)(state)
			: () => false,
	);

	const handleDelete = () => {
		deleteProfile();
	};

	const handleBlur = (name: string, value: string) => {
		setValueForProfile(
			name as keyof MessageProfileValues | keyof MessageProfileConfiguration,
			value as string,
		);
	};

	const handleFix = <TargetField extends ValidationTargetField>(
		fixedValue: AutoFixValue<TargetField>,
		fieldName: string,
	) => {
		handleBlur(fieldName, fixedValue as string);
	};

	return (
		<>
			{activeNode?.id && (
				<div
					data-testid="profiling-edit-view"
					data-ref-node-id={activeQNamePath}
				>
					<div className="profiling-edit-form__actions">
						<DatatypeSelect />
						{isDeleteVisible && <DeleteDialoag handleDelete={handleDelete} />}
					</div>
					<div>
						<FormFieldRenderer
							fields={profilingFields}
							profile={profile}
							onBlur={handleBlur}
							onFix={handleFix}
							activeNode={activeNode}
							activePath={activePath}
						/>
					</div>
				</div>
			)}
		</>
	);
};

export default ProfilingEditForm;
