import type { LiteId } from "../../AppActor/actors/modellierungModel/schemas";
import useActiveRestriction from "../../DatatypesView/useActiveRestriction";
import { EditorSection, useEditorSection } from "../../EditorPage";
import type { RestrictionId } from "../../EditorState/types";
import { useActiveNode } from "../../MessageProfilingView/useActiveNode";
import { useValidationResults } from "../../Validation/hooks";
import type {
	Locator,
	ValidationResultBase,
	ValidationTargetField,
} from "../../Validation/types";
import { ValidationResultGroup } from "../../Validation/types";

export default function useProfilingValidationResult<
	TargetField extends ValidationTargetField,
>({
	nodeId: nodeIdParam,
	restrictionId: restrictionIdParam,
	targetField,
}: {
	nodeId?: LiteId;
	restrictionId?: RestrictionId;
	targetField?: TargetField;
}): ValidationResultBase<TargetField>[] {
	const section = useEditorSection();
	const { activeNode } = useActiveNode();
	const activeRestriction = useActiveRestriction();
	const nodeId = nodeIdParam ?? activeNode.id;
	const restrictionId = restrictionIdParam ?? activeRestriction?.get("id");
	const resultGroup =
		section === EditorSection.MessageStructures
			? ValidationResultGroup.MessageProfiles
			: ValidationResultGroup.RestrictionProfiles;
	const locator:
		| Locator<ValidationResultGroup.MessageProfiles>
		| Locator<ValidationResultGroup.RestrictionProfiles> =
		section === EditorSection.MessageStructures
			? { nodeId }
			: { nodeId, restrictionId };
	const results = useValidationResults<ValidationResultGroup, TargetField>(
		resultGroup,
		{ ...locator, targetField },
	);
	return results;
}
