import useActiveDocTitle from "../useActiveDocTitle";
import NavBarBreadcrumbs from "../../EditorNavBar/NavBarBreadcrumbs/NavBarBreadcrumbs";
import EditorNavBar from "../../EditorNavBar";
import { useProjectBreadcrumb } from "../../EditorNavBar/NavBarBreadcrumbs/breadCrumbs";
import { filterFalsy } from "../../../util/lists";

const DocumentationNavBar = (): JSX.Element => {
	const [activeDocTitleKey] = useActiveDocTitle();
	const projectBreadcrumb = useProjectBreadcrumb();

	const breadcrumbs = filterFalsy([
		projectBreadcrumb,
		{
			key: "__DOCUMENTATION__",
			content: "Dokumentation",
			to: "/profilierung/dokumentation",
		},
		activeDocTitleKey && {
			key: "__ACTIVE_TITLE__",
			content: activeDocTitleKey,
			to: `/profilierung/dokumentation/${activeDocTitleKey}`,
		},
	]);

	return (
		<EditorNavBar data-testid="documentation-navbar">
			<NavBarBreadcrumbs items={breadcrumbs} />
		</EditorNavBar>
	);
};

export default DocumentationNavBar;
