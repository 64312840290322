import { useMemo } from "react";
import { useModellierungProjekt } from "../AppActor/actors/modellierungModel/hooks";
import type { BreadcrumbItem } from "../EditorNavBar/NavBarBreadcrumbs/NavBarBreadcrumbs";
import useProjectBreadcrumb from "../EditorNavBar/NavBarBreadcrumbs/breadCrumbsLite";
import NavBarBreadcrumbs from "../EditorNavBar/NavBarBreadcrumbs/NavBarBreadcrumbs";
import { useActiveStructureNodeHierarchy } from "../AppActor/actors/treeState/hooks";
import { createTreeUrl } from "../AppActor/actors/navigation/helpers";

export default function LiteModelBreadcrumbs(): JSX.Element {
	const activeProject = useModellierungProjekt();
	const projectBreadcrumb = useProjectBreadcrumb(activeProject);
	const activeNodeHierarchy = useActiveStructureNodeHierarchy();

	const breadcrumbs: BreadcrumbItem[] = useMemo(() => {
		const hierarchy = activeNodeHierarchy?.slice(1);

		if (!activeNodeHierarchy || !hierarchy || !activeProject) {
			return [projectBreadcrumb];
		}

		const innerBreadcrumbs = hierarchy?.map((node, index) => {
			const idPath = activeNodeHierarchy
				.slice(0, index + 1)
				.map((hierarchyNode) => hierarchyNode.id);
			const to = createTreeUrl(activeProject, [...idPath, node.id]);

			return { to, content: node.name, key: node.id };
		});

		return [projectBreadcrumb, ...innerBreadcrumbs];
	}, [activeNodeHierarchy, activeProject, projectBreadcrumb]);

	if (!activeProject) return <></>;

	return <NavBarBreadcrumbs items={breadcrumbs} />;
}
