import type { AnyActorSystem } from "xstate/dist/declarations/src/system";
import { getFromSystem } from "../../../util/machines";
import type { EventStoreActorRef, EventStoreLog } from "./eventStore.machine";
import type { StoreEvent } from "./StoreEvent";
import { useEventHandler } from "../../../hooks";
import { useSelectFromSystem } from "../hooks";

export function getEventLogFromSystem(system: AnyActorSystem): EventStoreLog {
	const eventStore: EventStoreActorRef = getFromSystem(system, "eventStore");
	return eventStore.getSnapshot().context.eventLog;
}

export function useEventStoreRef(): EventStoreActorRef {
	return useSelectFromSystem((system) => getFromSystem(system, "eventStore"));
}

export function useSendStoreEvent(): (event: StoreEvent) => void {
	const eventStore = useEventStoreRef();
	return useEventHandler((event: StoreEvent) =>
		eventStore.send({ type: "APPEND_EVENT", event }),
	);
}
