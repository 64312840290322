import type { Dispatch, SetStateAction } from "react";
import { useEffect } from "react";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	Stack,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { RequestStatus } from "../../Api";
import { Dropzone, useDropzone } from "../../ui";
import RequestErrorNotification from "../../RequestErrorNotification";
import ModalHeader from "../../ui/Modal/ModalHeader";
import { useUploadMessageMutation } from "../../../redux/messagesApiSlice";
import {
	toRequestStatus,
	toRequestError,
} from "../../../util/requestFormatters";
import { useAppSelector } from "../../../redux/hooks";
import { selectValidationBase } from "../../../redux/messagesSlice";

const UploadMessageDialog = ({
	isOpen,
	setIsOpen,
}: {
	isOpen: boolean;
	setIsOpen: Dispatch<SetStateAction<boolean>>;
}): JSX.Element => {
	const { getProps, selectedFiles, reset, hasSelectedFiles } = useDropzone({
		accept: ["text/xml", "application/xml"],
		multiple: true,
	});

	const [uploadMessage, { error, isError, isLoading, isSuccess }] =
		useUploadMessageMutation();

	const status = toRequestStatus({ isError, isLoading, isSuccess });
	const requestError = toRequestError(error);

	const validationBase = useAppSelector(selectValidationBase());

	useEffect(() => {
		if (status === RequestStatus.Success) {
			setIsOpen(false);
			reset();
		}
	}, [reset, setIsOpen, status]);

	const handleClick = () => {
		if (selectedFiles.length > 0) {
			const reqData = new FormData();
			for (const file of selectedFiles) {
				reqData.append("file", file);
			}
			uploadMessage({ data: reqData, validationBase });
		}
	};

	const handleClose = () => {
		if (isLoading) return;
		setIsOpen(false);
	};

	return (
		<>
			<RequestErrorNotification
				id="upload-message-request"
				status={status}
				error={requestError}
			>
				Hochladen der Nachricht fehlgeschlagen
			</RequestErrorNotification>
			<Dialog
				open={isOpen || isLoading}
				onClose={handleClose}
				data-testid="message-upload-modal"
			>
				<ModalHeader
					title="Nachrichtendateien anzeigen"
					handleClose={handleClose}
				/>
				<DialogContent>
					<Stack spacing={2}>
						<DialogContentText>
							Bitte wählen Sie nur Nachrichtendateien (im Format xml) aus.
						</DialogContentText>
						<Dropzone {...getProps()}>
							<FileUploadIcon />
							{hasSelectedFiles ? (
								<ul>
									{selectedFiles.map((file) => (
										<li key={file.name}>{file.name}</li>
									))}
								</ul>
							) : (
								"Drag & Drop oder Feld klicken, um Explorer zu öffnen"
							)}
						</Dropzone>
					</Stack>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} variant="outlined" disabled={isLoading}>
						Schließen
					</Button>
					<LoadingButton
						loading={isLoading}
						onClick={handleClick}
						variant="contained"
						data-testid="upload-button"
					>
						Hochladen
					</LoadingButton>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default UploadMessageDialog;
