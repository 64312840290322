import { Breadcrumbs } from "@mui/material";
import type { ReactNode } from "react";
import { NavLink } from "react-router-dom";
import "./NavBarBreadcrumbs.scss";

export interface BreadcrumbItem {
	key: string;
	to?: string | null;
	content: string | ReactNode;
}

function NavBarBreadcrumbs({
	items,
}: {
	items: BreadcrumbItem[];
}): JSX.Element {
	return (
		<Breadcrumbs
			separator="/"
			aria-label="Pfadnavigation"
			className="navbar-breadcrumbs"
			classes={{
				li: "navbar-breadcrumbs__item",
				separator: "navbar-breadcrumbs__separator",
				ol: "navbar-breadcrumbs__list",
			}}
			maxItems={6}
			itemsAfterCollapse={2}
			itemsBeforeCollapse={2}
		>
			{items.map(({ key, to, content }) =>
				to ? (
					<NavLink
						key={key}
						className="navbar-breadcrumbs__nav-link"
						to={to}
						data-testid="breadcrumb-link"
					>
						{content}
					</NavLink>
				) : (
					<span key={key}>{content}</span>
				),
			)}
		</Breadcrumbs>
	);
}

export default NavBarBreadcrumbs;
