import { useMatch } from "react-router-dom";
import useActiveDataType from "../useActiveDataType";
import { encodeXPath } from "../../../util/url";
import useActiveRestriction from "../useActiveRestriction";
import useActiveRestrictionProfile from "../useActiveRestrictionProfile";
import NavBarBreadcrumbs from "../../EditorNavBar/NavBarBreadcrumbs/NavBarBreadcrumbs";
import EditorNavBar from "../../EditorNavBar";
import { useProjectBreadcrumb } from "../../EditorNavBar/NavBarBreadcrumbs/breadCrumbs";
import { filterFalsy } from "../../../util/lists";
import { useAppSelector } from "../../../redux/hooks";
import {
	selectQName,
	selectRestrictionBreadcrumbs,
} from "../../../redux/treeSlice";
import { useStateSelector } from "../../EditorState";
import { selectStandard } from "../../EditorState/selectors";
import { useActiveStandardNode } from "../../MessageProfilingView/useActiveNode";

function DatatypesNavBar(): JSX.Element {
	const standardMatch = useMatch("/profilierung/datentypen/:nodeId/standard/*");
	const { activeDataType } = useActiveDataType();
	const activeRestriction = useActiveRestriction();
	const { activePath: activeRestrictionPath } =
		useActiveRestrictionProfile(activeDataType?.id) || {};
	const { activePath: activeStandardPath } =
		useActiveStandardNode(activeDataType?.id) || {};
	const standard = useStateSelector(selectStandard());
	const datatypeQName = useAppSelector(
		activeDataType ? selectQName(standard, activeDataType) : () => "",
	);
	const activePath = standardMatch ? activeStandardPath : activeRestrictionPath;

	const projectBreadcrumb = useProjectBreadcrumb();

	const restrictionBasePath = `${encodeXPath(
		datatypeQName || "",
	)}/profil/${activeRestriction?.get("id")}/`;
	const standardBasePath = `${encodeXPath(datatypeQName || "")}/standard/`;
	const basePath = standardMatch ? standardBasePath : restrictionBasePath;
	const links = useAppSelector(
		selectRestrictionBreadcrumbs(standard, activePath, basePath),
	);

	const breadcrumbs = filterFalsy([
		projectBreadcrumb,
		{
			key: "__DATATYPES__",
			content: "Datentypen",
			to: "/profilierung/datentypen",
		},
		activeDataType &&
			!activeRestriction && {
				key: "__ACTIVE_DATATYPE__",
				to: `/profilierung/datentypen/${encodeXPath(datatypeQName || "")}`,
				content: activeDataType.name,
			},
		standardMatch && {
			key: "__STANDARD_DATATYPE__",
			content: "Standard",
			to: `/profilierung/datentypen/${standardMatch.params.nodeId}/standard`,
		},
		activeRestriction &&
			activeDataType && {
				key: "__ACTIVE_RESTRICTION__",
				to: `/profilierung/datentypen/${encodeXPath(
					datatypeQName || "",
				)}/profil/${activeRestriction.get("id")}`,
				content: `${activeDataType.name}:${activeRestriction.get("name")}`,
			},
		...(links || []).map(({ link, name }) => ({
			key: link,
			to: link,
			content: name,
		})),
	]);

	return (
		<EditorNavBar className="datatypes-navbar" data-testid="datatypes-navbar">
			<NavBarBreadcrumbs items={breadcrumbs} />
		</EditorNavBar>
	);
}

export default DatatypesNavBar;
