import { IconButton, Tooltip } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import type { ImmutableMap } from "@xoev/immutable-map";
import { Link } from "react-router-dom";
import { encodeXPath } from "../../../../util/url";
import ValidationLabel from "../../../Validation/ValidationLabel";
import DatatypeNoInfo from "./DatatypeNoInfo";
import DatatypeTableCell from "./DatatypeTableCell";
import DeleteButton from "./DeleteButton";
import type { RestrictionProfileValues } from "../../../EditorState/types";
import useCheckedActiveNode from "./useCheckedActiveNode";
import { useGetRestrictionSeverity } from "../../../Profiling/ProfilingNodeValidationProvider";
import { useStateSelector } from "../../../EditorState";
import { selectStandard } from "../../../EditorState/selectors";
import { selectQName } from "../../../../redux/treeSlice";
import { useAppSelector } from "../../../../redux/hooks";
import "./DatatypeRestrictionDisplay.scss";

export default function DatatypeRestrictionDisplay({
	restriction,
	onDelete,
	onActivateEdit,
}: {
	restriction: ImmutableMap<RestrictionProfileValues>;
	onDelete: (id: string) => void;
	onActivateEdit: (id: string) => void;
}): JSX.Element {
	const activeNode = useCheckedActiveNode(DatatypeRestrictionDisplay.name);
	const standard = useStateSelector(selectStandard());
	const qname = useAppSelector(selectQName(standard, activeNode));
	const linkQName = encodeXPath(qname || "");
	const getRestrictionSeverity = useGetRestrictionSeverity();
	const id = restriction.get("id");

	return (
		<>
			<DatatypeTableCell variant="name">
				<div className="datatype-restriction-display__wrapper">
					<span
						className="datatype-restriction-display__name"
						data-testid="restriction-name"
					>
						<Link
							className="datatype-restriction-display__link"
							to={`/profilierung/datentypen/${linkQName}/profil/${id}`}
							data-testid="restriction-edit"
						>
							<ValidationLabel severity={getRestrictionSeverity(id)}>
								{restriction.get("name")}
							</ValidationLabel>
						</Link>
					</span>
				</div>
			</DatatypeTableCell>
			<DatatypeTableCell variant="description" data-testid="restriction-desc">
				{restriction.get("beschreibung") || (
					<DatatypeNoInfo data-testid="no-restriction-desc">
						Keine Beschreibung vorhanden
					</DatatypeNoInfo>
				)}
			</DatatypeTableCell>
			<DatatypeTableCell variant="actions">
				<div className="datatype-restriction-display__buttons">
					<DeleteButton
						restrictionId={id}
						restrictionName={restriction.get("name")}
						onDelete={onDelete}
					/>
					<Tooltip title="Einschränkung bearbeiten">
						<IconButton
							size="small"
							onClick={() => onActivateEdit(id)}
							data-testid="restriction-inline-edit"
						>
							<EditIcon />
						</IconButton>
					</Tooltip>
				</div>
			</DatatypeTableCell>
		</>
	);
}
