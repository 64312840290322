import UnknownLiteNodeTypeError from "./UnknownLiteNodeTypeError";
import type {
	LiteBaustein,
	LiteEigenschaft,
	LiteNode,
	LitePaket,
} from "./schemas";
import {
	LiteBausteinType,
	LitePaketType,
	isLiteBaustein,
	isLiteEigenschaft,
	isLiteModel,
	isLitePaket,
} from "./schemas";

export enum LiteKind {
	Model = "Model",
	Paket = "Paket",
	SchemaPaket = "SchemaPaket",
	CodeDatentyp = "CodeDatentyp",
	Nachricht = "Nachricht",
	Datentyp = "Datentyp",
	GlobaleEigenschaft = "GlobaleEigenschaft",
	UnknownBaustein = "UnknownBaustein",
	Attribut = "Attribut",
	Eigenschaft = "Eigenschaft",
}

function getPaketKind(node: LitePaket): LiteKind {
	if (node.typ === LitePaketType.Schema) return LiteKind.SchemaPaket;
	return LiteKind.Paket;
}

function getBausteinKind(node: LiteBaustein): LiteKind {
	if (node.typ === LiteBausteinType.CodeDatentyp) return LiteKind.CodeDatentyp;
	if (node.typ === LiteBausteinType.Nachricht) return LiteKind.Nachricht;
	if (node.typ === LiteBausteinType.Datentyp) return LiteKind.Datentyp;
	if (node.typ === LiteBausteinType.GlobaleEigenschaft)
		return LiteKind.GlobaleEigenschaft;
	return LiteKind.UnknownBaustein;
}

function getEigenschaftKind(node: LiteEigenschaft): LiteKind {
	if (node.attribut) return LiteKind.Attribut;
	return LiteKind.Eigenschaft;
}

export function getLiteNodeKind(node: LiteNode): LiteKind {
	if (isLiteModel(node)) {
		return LiteKind.Model;
	}
	if (isLitePaket(node)) {
		return getPaketKind(node);
	}
	if (isLiteBaustein(node)) {
		return getBausteinKind(node);
	}
	if (isLiteEigenschaft(node)) {
		return getEigenschaftKind(node);
	}
	throw new UnknownLiteNodeTypeError(node);
}
