import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import type { RequestCodelistType } from "./apiSlice";
import type { RootState } from "./store";
import type { Nullish } from "../util/types";

export enum CodelistResultStatus {
	Existing = "Existing",
	NonExisting = "NonExisting",
	Error = "Error",
}

export type BaseCodelistResult<ResultData> =
	| {
			data: ResultData;
			status: CodelistResultStatus.Existing;
	  }
	| {
			data: null;
			status: CodelistResultStatus.NonExisting;
	  }
	| {
			data: null;
			status: CodelistResultStatus.Error;
	  };

export type CodelistRequestResult = BaseCodelistResult<RequestCodelistType>;
export type CodelistStateType = {
	suggestedList: string[];
	otherLists: string[];
};
export type CodelistStateResult = BaseCodelistResult<CodelistStateType>;

export type CodelistState = {
	[kennung: string]: BaseCodelistResult<CodelistStateType>;
};

const createKey = (kennung: string, version?: string | null) =>
	version ? `${kennung}_${version}` : kennung;
const initialState: CodelistState = {};

const codelistSlice = createSlice({
	name: "codelist",
	initialState,
	reducers: {
		addCodelist(
			state,
			action: PayloadAction<{
				kennung: string;
				version?: string | null;
				result: CodelistStateResult;
			}>,
		) {
			const { kennung, version, result } = action.payload;
			const { status } = result;
			if (status === CodelistResultStatus.Error) {
				return state;
			}

			const key = createKey(kennung, version);
			return {
				...state,
				[key]: result,
			};
		},
	},
});

export const { addCodelist } = codelistSlice.actions;

export const selectAllCodelists = () => (state: RootState) => state.codelist;

export const selectSuggestedCodelist =
	(kennung?: string | null, version?: string | null) => (state: RootState) =>
		kennung
			? state.codelist[createKey(kennung, version)]?.data?.suggestedList
			: null;

export const selectOtherCodelists =
	(kennung?: string, version?: string | null) => (state: RootState) =>
		kennung
			? state.codelist[createKey(kennung, version)].data?.otherLists
			: null;

export const selectIsCodelistAvailabe =
	(kennung: Nullish<string>, version?: Nullish<string>) => (state: RootState) =>
		kennung ? !!state.codelist[createKey(kennung, version)] : false;

export const selectCodelist =
	(kennung: string, version?: string | null) => (state: RootState) =>
		state.codelist[createKey(kennung, version)];

export default codelistSlice.reducer;
