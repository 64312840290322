import useConst from "./useConst";
import { createHtmlId } from "../util/misc";

/**
 * Generate a unique id for an html element.
 *
 * @param [tag] An optional tag to be prependet to the generated id. This
 * allows users to more easily identify the generated id when inspecting
 * the dom.
 * @returns A unique string that can be used as the id of an html element.
 *
 * @example
 * ```jsx
 * const MyInput = ({ label, ...props }) => {
 *   const id = useHtmlId();
 *   return (
 *     <label htmlFor={id}>
 *       {label}
 *       <input {...props} id={id} type={text} />
 *     </label>
 *   );
 * }
 * ```
 */
function useHtmlId(tag?: string): string {
	const generatedId = useConst(createHtmlId);
	return tag ? `${tag}--${generatedId}` : generatedId;
}

export default useHtmlId;
