import { Tooltip } from "@mui/material";
import BorderTopIcon from "@mui/icons-material/BorderTop";
import NodeIcon from "../../NodeIcon";
import { VisuallyHidden } from "../../ui";

const CodeListIcon = ({ isCodeList }: { isCodeList: boolean }): JSX.Element => {
	return (
		<>
			{isCodeList && (
				<Tooltip title="Das Element ist eine Codeliste">
					<NodeIcon>
						<VisuallyHidden>
							Information: Das Element ist eine Codeliste
						</VisuallyHidden>
						<BorderTopIcon />
					</NodeIcon>
				</Tooltip>
			)}
		</>
	);
};

export default CodeListIcon;
