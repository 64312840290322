import InfoRendererDisplay from "../../../../../InfoNodeEditView/InfoNodeEditForm/FormFieldRenderer/renderers/InfoRenderer/InfoRendererDisplay";
import type { DisplayRendererProps } from "../../types";

export default function CommonMetadataDisplay({
	activeNode,
}: DisplayRendererProps): JSX.Element {
	return (
		<>
			<InfoRendererDisplay
				label="Name"
				value={activeNode.name}
				fallback="Kein Name vorhanden"
			/>
			<InfoRendererDisplay
				label="Beschreibung"
				value={activeNode.beschreibung}
				fallback="Keine Beschreibung vorhanden"
			/>
			<InfoRendererDisplay
				label="Umsetzungshinweis"
				value={activeNode.umsetzungshinweis}
				fallback="Kein Umsetzungshinweis vorhanden"
			/>
		</>
	);
}
