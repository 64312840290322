import type { ReactNode } from "react";
import { Link } from "react-router-dom";
import useDelayedValue from "../../../hooks/useDelayedValue";
import type { ExtendProps } from "../../../util/types";
import { RequestStatus } from "../../Api";
import { useEditorData } from "../../EditorData";
import { useStateSelector } from "../../EditorState";
import { selectHasActiveProject } from "../../EditorState/selectors";
import InactivePageGuard from "../../InactivePageGuard";
import type { InactivePageGuardProps } from "../../InactivePageGuard/InactivePageGuard";

const DISPLAY_GUARD_DELAY = 300;

type ProfilingPageGuardProps = ExtendProps<
	InactivePageGuardProps,
	{ children?: ReactNode }
>;

export default function ProfilingPageGuard({
	children,
	...props
}: ProfilingPageGuardProps): JSX.Element {
	const { initStatus } = useEditorData();
	const isLoadingOrIdle =
		initStatus === RequestStatus.Loading || initStatus === RequestStatus.Idle;
	// Delay the loading state slightly, so the ui does not flicker when the
	// state switches
	const delayedIsLoadingOrIdle = useDelayedValue(
		isLoadingOrIdle,
		DISPLAY_GUARD_DELAY,
	);
	const hasActiveProject = useStateSelector(selectHasActiveProject());
	const isLocked = !hasActiveProject && !delayedIsLoadingOrIdle;

	return (
		<InactivePageGuard
			{...props}
			isLocked={isLocked}
			heading="Aktuell ist kein Profilierungsprojekt aktiv"
			description={
				<>
					Wechseln Sie zur Startseite der Profilierung und legen Sie ein{" "}
					<Link to="/profilierung">neues&nbsp;Profilierungsprojekt</Link> an.
				</>
			}
		>
			{children}
		</InactivePageGuard>
	);
}
