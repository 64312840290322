import SubMenu from "../SubMenu";

function HomeSubMenu(): JSX.Element {
	return (
		<SubMenu label="Unternavigation der Startseite">
			<SubMenu.Item
				to="/"
				isActive={({ pathname }) => ["/", "/index"].includes(pathname)}
				data-testid="sub-menu-home"
			>
				Start
			</SubMenu.Item>
			<SubMenu.Item to="/impressum" data-testid="impressum-link">
				Impressum
			</SubMenu.Item>
			<SubMenu.Item to="/datenschutzerklaerung" data-testid="datenschutz-link">
				Datenschutzerklärung
			</SubMenu.Item>
			<SubMenu.Item to="/barrierefreiheit" data-testid="barrierefreiheit-link">
				Barrierefreiheit
			</SubMenu.Item>
		</SubMenu>
	);
}

export default HomeSubMenu;
