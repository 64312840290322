import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import type { ReactNode, RefAttributes } from "react";
import { forwardRef } from "react";
import type { LinkProps } from "react-router-dom";
import { Link } from "react-router-dom";
import type { ExtendProps } from "../../util/types";
import EditorMain from "../EditorMain";
import EditorPage, { EditorSection } from "../EditorPage";
import MetadataEditForm from "./MetadataEditForm";
import PropertiesTable from "./MetadataEditForm/AttributesTable";
import MetadataNavBar from "./MetadataNavBar";
import useActiveProjectSection, {
	ProjectSection,
} from "./useActiveProjectSection";
import "./MetadataView.scss";

export type RouterLinkProps = LinkProps & RefAttributes<HTMLAnchorElement>;

export const TabLink = forwardRef(function TabLink(
	{
		className,
		children,
		href,
		...props
	}: ExtendProps<
		RouterLinkProps,
		{
			className: string;
			children: ReactNode;
			href: string;
		}
	>,
	ref,
) {
	return (
		// eslint-disable-next-line react/jsx-props-no-spreading
		<Link {...props} ref={ref as never} className={className} to={href}>
			{children}
		</Link>
	);
});

const MetadataView = (): JSX.Element => {
	const activeTab = useActiveProjectSection();

	return (
		<EditorPage section={EditorSection.Metadata}>
			<MetadataNavBar />
			<EditorMain className="metadata-view" data-testid="metadata-main">
				<TabContext value={activeTab}>
					<Box component="div" className="metadata-view__tab-wrapper">
						<TabList
							orientation="horizontal"
							className="metadata-view__tab-list"
						>
							<Tab
								value={ProjectSection.Metadata}
								label="Metadaten"
								LinkComponent={TabLink}
								href="/profilierung/projekt/metadaten"
								data-testid="metadata-tab"
								data-tab={ProjectSection.Metadata}
							/>
							<Tab
								value={ProjectSection.Properties}
								label="Eigene Eigenschaften"
								LinkComponent={TabLink}
								href="/profilierung/projekt/eigenschaften"
								data-testid="metadata-tab"
								data-tab={ProjectSection.Properties}
							/>
						</TabList>
						<TabPanel
							value={ProjectSection.Metadata}
							className="metadata-view__tab-panel"
						>
							<MetadataEditForm />
						</TabPanel>
						<TabPanel
							value={ProjectSection.Properties}
							className="metadata-view__tab-panel"
						>
							<PropertiesTable />
						</TabPanel>
					</Box>
				</TabContext>
			</EditorMain>
		</EditorPage>
	);
};

export default MetadataView;
