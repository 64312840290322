import { useActiveProjectId } from "../../AppActor/actors/navigation/hooks";
import {
	useActiveStructureNode,
	useActiveTreePath,
	useIsActiveNodeReadOnly,
} from "../../AppActor/actors/treeState/hooks";
import EditorContent from "../../EditorContent";
import DetailsDebug from "./DetailsDebug";
import DetailsRenderer from "./renderers/DetailsRenderer";

export default function DetailsView(): JSX.Element {
	const projektId = useActiveProjectId();
	const activeNode = useActiveStructureNode();
	const activePath = useActiveTreePath();
	const isReadOnly = useIsActiveNodeReadOnly();

	return (
		<EditorContent padTop>
			{projektId && activeNode ? (
				<DetailsRenderer
					activeNode={activeNode}
					activePath={activePath}
					isReadOnly={isReadOnly}
					projektId={projektId}
				/>
			) : (
				<>Kein Knoten gefunden</>
			)}
			<DetailsDebug />
		</EditorContent>
	);
}
