import { useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useEventHandler, useStableNavigate, useSyncedRef } from "../../hooks";
import { getPreferedMotion } from "../../util/a11y";
import type { NodeEventArg } from "../Tree";
import type { useActiveNode } from "./useActiveNode";
import type {
	LiteId,
	LiteNode,
} from "../AppActor/actors/modellierungModel/schemas";

interface NavState {
	fromActivate?: boolean;
}

type TreeEvent = NodeEventArg<LiteNode, LiteId>;

export default function useUrlTreeState({
	getUrl,
	openPath,
	closePath,
	getDomPath,
	activeNodeState,
}: {
	getUrl: (event: TreeEvent) => string;
	closePath: (path: LiteId[]) => void;
	openPath: (path: LiteId[]) => void;
	getDomPath: (path: LiteId[]) => HTMLElement | null | undefined;
	activeNodeState: ReturnType<typeof useActiveNode>;
}): {
	handleOpen: (event: TreeEvent) => void;
	handleClose: (event: TreeEvent) => void;
	handleActivate: (event: TreeEvent) => void;
} {
	const getDomPathRef = useSyncedRef(getDomPath);
	const openPathRef = useSyncedRef(openPath);

	const navigate = useStableNavigate();
	const { activeNode, activePath } = activeNodeState;
	const location = useLocation();
	const locationRef = useSyncedRef(location);
	const shouldFocus = !location.search.includes("focus=false");
	const isFromActivate = !!(location.state as NavState)?.fromActivate;

	// eslint-disable-next-line consistent-return
	useEffect(() => {
		if (activePath && !isFromActivate) {
			openPathRef.current(activePath);
			const timeout = setTimeout(() => {
				const domPath = getDomPathRef.current(activePath);
				if (shouldFocus) {
					domPath?.focus();
				}
				domPath?.scrollIntoView({
					behavior: getPreferedMotion(),
					block: "center",
				});
			});
			return () => clearTimeout(timeout);
		}
	}, [
		activeNode,
		activePath,
		getDomPathRef,
		isFromActivate,
		openPathRef,
		shouldFocus,
	]);

	useEffect(() => {
		navigate(locationRef.current, {
			replace: true,
			state: { fromActivate: false },
		});
	}, [locationRef, navigate]);

	const handleOpen = useEventHandler((event: TreeEvent) => {
		if (event.path) {
			openPath(event.path);
		}
	});

	const handleClose = useEventHandler((event: TreeEvent) => {
		if (event.path) {
			closePath(event.path);
		}
	});

	const handleActivate = useEventHandler((event: TreeEvent) => {
		navigate(getUrl(event), {
			replace: true,
			state: { fromActivate: true },
		});
	});

	return useMemo(
		() => ({ handleOpen, handleClose, handleActivate }),
		[handleOpen, handleClose, handleActivate],
	);
}
