import { Tooltip } from "@mui/material";
import BorderAllIcon from "@mui/icons-material/BorderAll";
import NodeIcon from "../../NodeIcon";
import { VisuallyHidden } from "../../ui";
import { useIconInfos } from "../../IconInfoProvider";

const AllIcon = (): JSX.Element => {
	const iconInfos = useIconInfos();
	const { tooltip = "Icon All" } = iconInfos?.groupTypeIsAll ?? {};

	return (
		<Tooltip title={tooltip}>
			<NodeIcon data-testid="all-icon">
				<VisuallyHidden>{tooltip}</VisuallyHidden>
				<BorderAllIcon />
			</NodeIcon>
		</Tooltip>
	);
};

export default AllIcon;
