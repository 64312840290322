import classNames from "classnames";
import type { ReactNode } from "react";
import type { ExtendProps, PropsOf } from "../../../../util/types";
import "./DatatypeNoInfo.scss";

type DatatypeNoInfoProps = ExtendProps<
	PropsOf<"em">,
	{ children: ReactNode; className?: string }
>;

export default function DatatypeNoInfo({
	children,
	className,
	...props
}: DatatypeNoInfoProps): JSX.Element {
	return (
		<em className={classNames("datatype-no-info", className)} {...props}>
			{children}
		</em>
	);
}
