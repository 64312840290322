import { useMemo } from "react";
import { useAppSelector } from "../../../redux/hooks";
import { selectActiveStandard } from "../../../redux/standardsSlice";
import useProjectName from "../useProjectName";
import type { BreadcrumbItem } from "./NavBarBreadcrumbs";
import { createStandardPath } from "../../Standards/helpers";
import { selectNodes } from "../../../redux/treeSlice";
import { filterFalsy } from "../../../util/lists";
import type { TreeNodeType } from "../../Profiling/types";
import type { LiteId } from "../../AppActor/actors/modellierungModel/schemas";

const EMPTY_PARENT_IDS: LiteId[] = [];

export function useStandardHomeBreadcrumb(): BreadcrumbItem {
	const currentStandard = useAppSelector(selectActiveStandard());

	return useMemo(
		() => ({
			key: "__STANDARD__",
			content: currentStandard ? (
				`${currentStandard?.nameKurz} ${currentStandard.version}`
			) : (
				<em>Kein Standard ausgewählt</em>
			),
			to:
				currentStandard &&
				createStandardPath({ kennung: currentStandard.kennung }),
		}),
		[currentStandard],
	);
}

export function useStandardBreadcrumbs(
	activeNode: TreeNodeType,
	getLinkPath: (node: TreeNodeType) => string,
	getNodeParents: (node: TreeNodeType) => LiteId[] | null | undefined,
): BreadcrumbItem[] {
	const currentStandard = useAppSelector(selectActiveStandard());
	const parentIds = getNodeParents(activeNode) || EMPTY_PARENT_IDS;
	const parentNodes = useAppSelector(
		selectNodes(currentStandard?.kennung || "", parentIds),
	);

	const breadcrumbs: BreadcrumbItem[] = useMemo(() => {
		return (
			filterFalsy([activeNode, ...parentNodes])
				.reverse()
				// We'll add the root node through the `useStandardHomeBreadcrumb` call
				// below, so we remove it here
				.slice(1)
				.map((node) => ({
					key: node.id,
					content: node.name,
					to:
						currentStandard &&
						createStandardPath({
							kennung: currentStandard.kennung,
							path: getLinkPath(node),
						}),
				}))
		);
	}, [activeNode, currentStandard, getLinkPath, parentNodes]);

	const standardBreadcrumb = useStandardHomeBreadcrumb();

	return useMemo(
		() => [standardBreadcrumb, ...breadcrumbs],
		[breadcrumbs, standardBreadcrumb],
	);
}

export function useProjectBreadcrumb(): BreadcrumbItem {
	const projectName = useProjectName();
	return useMemo(
		() => ({
			key: "__PROJECT__",
			content: projectName ?? (
				<em data-testid="no-project-breadcrumb">Kein Projekt geöffnet</em>
			),
			to: projectName && "/profilierung",
		}),
		[projectName],
	);
}
