import type { SelectChangeEvent } from "@mui/material";
import { Select, MenuItem, IconButton } from "@mui/material";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import CancelIcon from "@mui/icons-material/Cancel";
import { useState } from "react";
import useActiveDataType from "../../../DatatypesView/useActiveDataType";
import useActiveRestriction from "../../../DatatypesView/useActiveRestriction";
import useActiveRestrictionProfile from "../../../DatatypesView/useActiveRestrictionProfile";
import {
	useApplyPatch,
	useEditorPatchCreators,
	useStateSelector,
} from "../../../EditorState";
import {
	selectRestrictionByName,
	selectRestrictionNames,
	selectStandard,
} from "../../../EditorState/selectors";
import KeyboardShortcut from "../../../KeyboardShortcut";
import { useActiveNode } from "../../../MessageProfilingView/useActiveNode";
import DatatypeLink from "../DatatypeLink";
import OverrideDialog from "./OverrideDialog";
import {
	subSelectCodelist,
	subSelectDatatypeReference,
} from "../../../EditorState/subSelectors";
import useSectionDependentProfile from "../../useSectionDependentProfile";
import { useSyncedState } from "../../../../hooks";
import { setDatatypeReferenceInProfile } from "../../../EditorState/reducerHelpers";
import {
	selectIsCodeliste,
	selectIsDataypeInStandard,
	selectQName,
	selectQNamePath,
	selectReferencedDatatype,
} from "../../../../redux/treeSlice";
import { useAppSelector } from "../../../../redux/hooks";
import DynamicInfo from "../../../ui/DynamicInfo";
import { AssertionError } from "../../../../util/error";
import "./DatatypeSelect.scss";

const DatatypeSelect = (): JSX.Element => {
	const { activeNode: activeMessageNode, activePath: activeMessagePath } =
		useActiveNode();
	const { activeDataType } = useActiveDataType();
	const activeRestriction = useActiveRestriction();
	const {
		activeNode: activeRestrictionNode,
		activePath: activeRestrictionPath,
		isFallback: isRestrictionFallback,
	} = useActiveRestrictionProfile(activeDataType?.id);
	const standard = useStateSelector(selectStandard());

	const { deletePartialProfile, deletePartialRestrictionProfile } =
		useEditorPatchCreators();
	const applyPatch = useApplyPatch();

	const [isEditing, setIsEditing] = useState(false);
	const [isOverrideDialogOpen, setIsOverrideDialogOpen] = useState(false);

	const isRestrictionProfile = !isRestrictionFallback;
	const activeNode = isRestrictionProfile
		? activeRestrictionNode
		: activeMessageNode;
	const activePath = isRestrictionProfile
		? activeRestrictionPath
		: activeMessagePath;
	const activeQNamePath = useAppSelector(selectQNamePath(standard, activePath));
	const standardDatatype = useAppSelector(
		selectReferencedDatatype(standard, activeNode),
	);
	const standardDatatypeQName = useAppSelector(
		selectQName(standard, standardDatatype),
	);
	const activeDatatypeQName = useAppSelector(
		selectQName(standard, activeDataType),
	);
	const isCodeliste = useAppSelector(selectIsCodeliste(standard, activeNode));

	const isDatatypeInStandard = useAppSelector(
		selectIsDataypeInStandard(standard, standardDatatype?.id),
	);

	const {
		isChildDatatypedOrProfiled,
		isParentProfiledDatatype,
		profile,
		setProfile,
	} = useSectionDependentProfile(activePath);

	const datatypeState = subSelectDatatypeReference(profile)?.get("name");
	const selectedDatatype = datatypeState ?? standardDatatypeQName ?? "";
	const [datatypeValue, setDatatypeValue] = useSyncedState(selectedDatatype);

	const restrictionNames = useStateSelector(
		selectRestrictionNames(standardDatatypeQName),
	);
	const selectedRestrictionId = useStateSelector(
		selectRestrictionByName(standardDatatypeQName, datatypeValue),
	)?.get("id");

	const handleDatatypeEditClick = () => {
		setIsEditing((prevState) => !prevState);
	};

	const handleSelect = (event: SelectChangeEvent) => {
		const option = event.target.value;
		setDatatypeValue(option);
		if (
			isChildDatatypedOrProfiled ||
			(isCodeliste && subSelectCodelist(profile))
		) {
			setIsOverrideDialogOpen(true);
		}
	};

	const writeChanges = () => {
		if (datatypeValue === standardDatatypeQName) {
			const patch = isRestrictionProfile
				? deletePartialRestrictionProfile({
						datatypeId: AssertionError.asNotNullish(
							activeDatatypeQName,
							"datatypeId",
						),
						restrictionId: AssertionError.asNotNullish(
							activeRestriction?.get("id"),
							"restrictionId",
						),
						restrictionProfileId: AssertionError.asNotNullish(
							activeQNamePath,
							"restrictionProfileId",
						),
						keys: ["datentypReferenz"],
				  })
				: deletePartialProfile({
						id: AssertionError.asNotNullish(activeQNamePath, "id"),
						keys: ["datentypReferenz"],
				  });
			applyPatch(patch);
		} else {
			setProfile(
				setDatatypeReferenceInProfile(
					profile,
					AssertionError.asNotNullish(
						standardDatatypeQName,
						"standardDatatypeQName",
					),
					datatypeValue,
				),
			);
		}
		setIsEditing(false);
	};

	const handleSelectBlur = () => {
		if (!isOverrideDialogOpen) {
			writeChanges();
		}
	};

	const handleOverrideDismiss = () => {
		setDatatypeValue(selectedDatatype);
		setIsOverrideDialogOpen(false);
	};

	const handleOverrideAccept = () => {
		writeChanges();
		setIsOverrideDialogOpen(false);
	};

	const isEditButtonVisible = !!(
		standardDatatypeQName &&
		!isParentProfiledDatatype &&
		!!restrictionNames.size
	);
	const isSelectVisible = isEditButtonVisible && isEditing;

	// TODO: The check is used to hide the select, do we need to hide other nodes as well?
	const isRootNode = !activeNode.parent;

	return (
		<div className="datatype-select">
			<OverrideDialog
				isOpen={isOverrideDialogOpen}
				onAccept={handleOverrideAccept}
				onDismiss={handleOverrideDismiss}
				isCodeListOverride={isCodeliste}
			/>
			{isRootNode ? (
				<h4 className="datatype-select__root-node">
					Wählen Sie eine Nachricht in der Struktur aus
				</h4>
			) : (
				<h3 className="datatype-select__header">
					{activeNode.name}
					{isEditButtonVisible ? " : " : " "}
					{!isSelectVisible && isDatatypeInStandard && (
						<span data-testid="datatype-placeholder">
							<DatatypeLink
								profileName={datatypeValue}
								profileId={selectedRestrictionId}
								standardType={standardDatatypeQName}
							/>
							{isChildDatatypedOrProfiled && (
								<DynamicInfo
									infoKey="childOrDatatypeProfiled"
									inline="end"
									data-testid="override-info"
								/>
							)}
						</span>
					)}
					{!isSelectVisible &&
						!isDatatypeInStandard &&
						!!standardDatatypeQName && (
							<span
								data-testid="datatype-placeholder"
								className="datatype-select__placeholder"
							>
								{standardDatatypeQName}
								<DynamicInfo
									infoKey="datatypeNotInStandard"
									inline="end"
									data-testid="override-info"
								/>
							</span>
						)}
				</h3>
			)}
			{isSelectVisible && (
				<Select
					className="datatype-select__select"
					data-testid="datatype-select"
					displayEmpty
					value={datatypeValue}
					onChange={handleSelect}
					onBlur={handleSelectBlur}
				>
					<MenuItem
						value={standardDatatypeQName}
						className="datatype-select__select-item"
						data-testid="datatype-select-item"
						data-item-name={standardDatatypeQName}
						data-default-item
					>
						{standardDatatypeQName}
					</MenuItem>
					{restrictionNames.sort().map((name) => (
						<MenuItem
							value={name}
							key={name}
							className="datatype-select__select-item"
							data-testid="datatype-select-item"
							data-item-name={name}
						>
							{name}
						</MenuItem>
					))}
				</Select>
			)}

			{isEditButtonVisible && (
				<>
					<IconButton
						className="datatype-select__datatype-button"
						data-testid="datatype-select-toggle"
						onClick={handleDatatypeEditClick}
					>
						{!isEditing ? <ModeEditIcon /> : <CancelIcon />}
					</IconButton>
					<KeyboardShortcut
						shortcut={{ key: "escape" }}
						onActivate={() => handleSelectBlur()}
						disabled={!isEditing}
					/>
				</>
			)}
		</div>
	);
};

export default DatatypeSelect;
