import SubMenu from "../SubMenu";
import { useProjektBaseSegment } from "../../AppActor/actors/modellierungModel/hooks";
import { forceEncode } from "../../../util/url";

const ProjectSubMenu = (): JSX.Element => {
	const baseSegment = useProjektBaseSegment();
	return (
		<SubMenu label="Unternavigation des Projekts">
			<SubMenu.Item
				to={`${baseSegment}/metadaten/standard`}
				data-testid="metadata"
				data-ready={!!baseSegment}
				isActive={({ pathname }) =>
					`${baseSegment}/metadaten` === forceEncode(pathname) ||
					forceEncode(pathname).startsWith(`${baseSegment}/metadaten/`)
				}
			>
				Metadaten
			</SubMenu.Item>
			<SubMenu.Item
				to={`${baseSegment}/konfiguration`}
				data-testid="configuration"
				data-ready={!!baseSegment}
				isActive={({ pathname }) =>
					`${baseSegment}/konfiguration` === forceEncode(pathname)
				}
				end={false}
			>
				Konfiguration
			</SubMenu.Item>
			<SubMenu.Item
				to={`${baseSegment}/modell`}
				data-testid="model"
				data-ready={!!baseSegment}
				isActive={({ pathname }) =>
					`${baseSegment}/modell` === forceEncode(pathname) ||
					forceEncode(pathname).startsWith(`${baseSegment}/modell/`)
				}
				end={false}
			>
				Modell
			</SubMenu.Item>
			<SubMenu.Item
				to={`${baseSegment}/dokumentation`}
				data-testid="documentation"
				data-ready={!!baseSegment}
				isActive={({ pathname }) =>
					`${baseSegment}/dokumentation` === forceEncode(pathname)
				}
				end={false}
			>
				Dokumentation
			</SubMenu.Item>
		</SubMenu>
	);
};

export default ProjectSubMenu;
