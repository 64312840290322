import { walkTreeEntries } from "../../../../Tree/treeHelpers";
import type {
	LiteBaustein,
	LiteEigenschaft,
	LiteId,
	LiteModel,
	LitePaket,
	RawLiteBaustein,
	RawLiteEigenschaft,
	RawLiteModel,
	RawLitePaket,
} from "../../modellierungModel/schemas";
import { LiteIdSchema, LiteNodeType } from "../../modellierungModel/schemas";
import {
	EMPTY_RAW_EIGENSCHAFTEN,
	getEigenschaftChildren,
	getModelChildren,
	getPaketChildren,
} from "./helpers";

export interface ModellNodeLists {
	modelList: LiteModel[];
	paketList: LitePaket[];
	bausteinList: LiteBaustein[];
	eigenschaftList: LiteEigenschaft[];
}

function transformModel(
	{ externeModelle, pakete, ...model }: RawLiteModel,
	parent: string | null,
): LiteModel {
	return {
		...model,
		id: LiteIdSchema.parse(model.id),
		parent: LiteIdSchema.nullable().parse(parent),
		liteType: LiteNodeType.Model,
		children: [
			...pakete.map((p) => LiteIdSchema.parse(p.id)),
			...externeModelle.map((b) => LiteIdSchema.parse(b.id)),
		],
	};
}

function transformPackage(
	{ bausteine, pakete, ...paket }: RawLitePaket,
	parent: string | null,
): LitePaket {
	return {
		...paket,
		id: LiteIdSchema.parse(paket.id),
		parent: LiteIdSchema.nullable().parse(parent),
		liteType: LiteNodeType.Paket,
		children: [
			...pakete.map((p) => LiteIdSchema.parse(p.id)),
			...bausteine.map((b) => LiteIdSchema.parse(b.id)),
		],
	};
}

function transformBaustein(
	{ eigenschaften, ...baustein }: RawLiteBaustein,
	parent: string | null,
): LiteBaustein {
	return {
		...baustein,
		id: LiteIdSchema.parse(baustein.id),
		parent: LiteIdSchema.nullable().parse(parent),
		liteType: LiteNodeType.Baustein,
		children: eigenschaften?.map((e) => LiteIdSchema.parse(e.id)) || [],
		basisDatentyp: LiteIdSchema.optional().parse(baustein.basisDatentyp),
	};
}

function transformEigenschaft(
	{ eigenschaften, ...eigenschaft }: RawLiteEigenschaft,
	parent: string | null,
): LiteEigenschaft {
	return {
		...eigenschaft,
		id: LiteIdSchema.parse(eigenschaft.id),
		parent: LiteIdSchema.nullable().parse(parent),
		datentypReferenz: LiteIdSchema.optional().parse(
			eigenschaft.datentypReferenz,
		),
		liteType: LiteNodeType.Eigenschaft,
		children: eigenschaften?.map((e) => LiteIdSchema.parse(e.id)) || [],
	};
}

const getParentFromPath = (path: string[], rootId: string | null) =>
	path.at(-2) || rootId;

const getRawNodeId = (
	node: RawLiteModel | RawLitePaket | RawLiteBaustein | RawLiteEigenschaft,
) => node.id;

function addPaketChildren(
	paket: RawLitePaket,
	bausteinMap: Map<LiteId, LiteBaustein>,
	eigenschaftMap: Map<LiteId, LiteEigenschaft>,
) {
	for (const baustein of paket.bausteine) {
		const transformedBaustein = transformBaustein(baustein, paket.id);
		bausteinMap.set(transformedBaustein.id, transformedBaustein);
		const rootEigenschaften = baustein.eigenschaften ?? EMPTY_RAW_EIGENSCHAFTEN;
		const bausteinEntries = walkTreeEntries(
			rootEigenschaften,
			getEigenschaftChildren,
			getRawNodeId,
		);
		for (const [path, eigenschaft] of bausteinEntries) {
			const transformedEigenschaft = transformEigenschaft(
				eigenschaft,
				getParentFromPath(path, baustein.id),
			);
			eigenschaftMap.set(transformedEigenschaft.id, transformedEigenschaft);
		}
	}
}

function addModelChildren(
	model: RawLiteModel,
	paketMap: Map<LiteId, LitePaket>,
	bausteinMap: Map<LiteId, LiteBaustein>,
	eigenschaftMap: Map<LiteId, LiteEigenschaft>,
) {
	const packageEntries = walkTreeEntries(
		model.pakete,
		getPaketChildren,
		getRawNodeId,
	);
	for (const [path, paket] of packageEntries) {
		addPaketChildren(paket, bausteinMap, eigenschaftMap);
		const transformed = transformPackage(
			paket,
			getParentFromPath(path, model.id),
		);
		paketMap.set(transformed.id, transformed);
	}
}

function createNodeMaps(rawModel: RawLiteModel) {
	const modelMap = new Map<LiteId, LiteModel>();
	const paketMap = new Map<LiteId, LitePaket>();
	const bausteinMap = new Map<LiteId, LiteBaustein>();
	const eigenschaftMap = new Map<LiteId, LiteEigenschaft>();

	const modelRoot = [rawModel];
	const modelEntries = walkTreeEntries(
		modelRoot,
		getModelChildren,
		getRawNodeId,
	);

	for (const [path, model] of modelEntries) {
		const parent = getParentFromPath(path, null);
		addModelChildren(model, paketMap, bausteinMap, eigenschaftMap);
		const transformed = transformModel(model, parent);
		modelMap.set(transformed.id, transformed);
	}

	return { modelMap, paketMap, bausteinMap, eigenschaftMap };
}

export default function createProjectModel(
	rawModel: RawLiteModel,
): ModellNodeLists {
	const { modelMap, paketMap, bausteinMap, eigenschaftMap } =
		createNodeMaps(rawModel);

	return {
		modelList: Array.from(modelMap.values()),
		paketList: Array.from(paketMap.values()),
		bausteinList: Array.from(bausteinMap.values()),
		eigenschaftList: Array.from(eigenschaftMap.values()),
	};
}
