import type { ActorRefFrom } from "xstate";
import { and, assertEvent, raise, setup } from "xstate";
import {
	sendToEventStore,
	translateStoreEvents,
} from "../../EventStore/helpers";
import type { ExtractStoreEventPayload } from "../../EventStore/StoreEvent";
import { joinLitePath } from "../modellierungModel/schemas";
import type { ProjektId, LiteId } from "../modellierungModel/schemas";
import { getModellierungProjektFromSystem } from "../modellierungModel/hooks";
import { assertHasProject, assertPatch } from "../modellierungModel/helpers";
import { getFullBausteinTreePath, getStructureTreePath } from "./helpers";
import { getPreferedMotion } from "../../../../util/a11y";
import type { ProjektMeta } from "../project/types";
import {
	createSelectDirectChildren,
	selectRootModell,
} from "../modellierungModel/selectors";

export type TreeStateCommandActorRef = ActorRefFrom<
	// eslint-disable-next-line no-use-before-define
	typeof treeStateCommandMachine
>;
export type TreeStateCommandEvent =
	| { type: "FOCUS_NAME_INPUT"; nodeId: LiteId }
	| ({
			type: "PATCH_MODELL";
	  } & ExtractStoreEventPayload<"MODELLIERUNG.MODELL.APPLY">)
	| ({
			type: "UPDATE_SCROLL_POSITION";
	  } & ExtractStoreEventPayload<"MODELLIERUNG_TREE.STATE.ACTIVATE">)
	| {
			type: "TOGGLE_TREE_NODE";
			mode: "open" | "close";
			projektId: ProjektId;
			fullPath: LiteId[];
	  };

const treeStateCommandMachine = setup({
	types: {
		events: {} as TreeStateCommandEvent,
		context: {} as ProjektMeta,
		input: {} as ProjektMeta,
	},
	actors: {
		translateEvents: translateStoreEvents<TreeStateCommandEvent>({
			"MODELLIERUNG.MODELL.APPLY": ({ payload }) => ({
				type: "PATCH_MODELL",
				...payload,
			}),
			"MODELLIERUNG_TREE.NODE.OPEN": ({ payload }) => ({
				type: "TOGGLE_TREE_NODE",
				mode: "open",
				...payload,
			}),
			"MODELLIERUNG_TREE.NODE.CLOSE": ({ payload }) => ({
				type: "TOGGLE_TREE_NODE",
				mode: "close",
				...payload,
			}),
			"MODELLIERUNG_TREE.STATE.ACTIVATE": ({ payload }) => ({
				type: "UPDATE_SCROLL_POSITION",
				...payload,
			}),
		}),
	},
	actions: {
		sendActivateAfterInsertNode: ({ event, system }) => {
			assertEvent(event, "PATCH_MODELL");
			assertPatch(event.patch, "addNode");
			const { projektId } = event;
			const { insertPath, newNodeId } = event.patch.payload;
			const fullPath = [...insertPath, newNodeId];
			const payload = { fullPath, projektId };
			sendToEventStore(system, {
				type: "MODELLIERUNG_TREE.NODE.OPEN",
				payload,
			});
			sendToEventStore(system, {
				type: "MODELLIERUNG_TREE.NODE.ACTIVATE",
				payload,
			});
		},
		raiseFocusInput: raise(
			({ event }) => {
				assertEvent(event, "PATCH_MODELL");
				assertPatch(event.patch, "addNode");
				const nodeId = event.patch.payload.newNodeId;
				return { type: "FOCUS_NAME_INPUT" as const, nodeId };
			},
			{ delay: 0 },
		),
		focusNameInput: ({ event }) => {
			assertEvent(event, "FOCUS_NAME_INPUT");
			const nameInput = document.querySelector<HTMLInputElement>(
				`[data-node-id="${event.nodeId}"] [data-field-name="name"]`,
			);
			nameInput?.focus?.();
			nameInput?.select?.();
		},
		activateParentNode: ({ event, system }) => {
			assertEvent(event, "PATCH_MODELL");
			assertPatch(event.patch, "deleteNode");
			const { projektId } = event;
			const { deletePath } = event.patch.payload;
			const pathToParent = deletePath.slice(0, -1);
			const projekt = getModellierungProjektFromSystem(system, projektId);
			if (!projekt) return;
			let fullPath = pathToParent;
			// If the pathToParent only contains the rootModelId, there is no
			// corresponding node in the tree we could focus. In that case, we focus
			// the first child node of the root instead
			if (pathToParent.length === 1) {
				const rootNode = selectRootModell(projekt);
				const firstChild = createSelectDirectChildren(projekt)(rootNode).at(0);
				if (firstChild) {
					fullPath = [projekt.modell.rootModelId, firstChild.id];
				}
			}
			sendToEventStore(system, {
				type: "MODELLIERUNG_TREE.NODE.ACTIVATE",
				payload: { fullPath, projektId },
			});
		},
		toggleTreeNode: ({ context, event, system }) => {
			assertEvent(event, "TOGGLE_TREE_NODE");
			const { fullPath, mode } = event;
			const projekt = getModellierungProjektFromSystem(
				system,
				context.projektId,
			);
			assertHasProject(projekt);
			const basePath = [projekt.modell.rootModelId];
			const structurePath = getStructureTreePath(projekt.modell, fullPath);
			const bausteinPath = getFullBausteinTreePath(
				basePath,
				fullPath,
				structurePath,
			);
			sendToEventStore(system, {
				type: "MODELLIERUNG_TREE.STATE.CHANGE",
				payload: {
					mode,
					projektId: context.projektId,
					bausteinPath,
					structurePath,
				},
			});
		},
		updateScrollPosition: ({ event }) => {
			assertEvent(event, "UPDATE_SCROLL_POSITION");
			setTimeout(() => {
				const bausteinPath = joinLitePath(event.fullBausteinPath);
				const structurePath = joinLitePath(event.fullPath);
				const bausteinNode = document.querySelector(
					`[data-node-target="baustein"] [data-node-path="${bausteinPath}"]`,
				);
				const structureNode = document.querySelector(
					`[data-node-target="structure"] [data-node-path="${structurePath}"]`,
				);
				const scrollOptions = {
					behavior: getPreferedMotion(),
					// Using "nearest" will only scroll, if the element is not already
					// visible and will scroll the minimally amount required to move the
					// element into the viewport
					block: "nearest",
				} as const;
				bausteinNode?.scrollIntoView(scrollOptions);
				structureNode?.scrollIntoView(scrollOptions);
			}, 0);
		},
	},
	guards: {
		isSelf: ({ event, context }) => {
			assertEvent(event, [
				"PATCH_MODELL",
				"TOGGLE_TREE_NODE",
				"UPDATE_SCROLL_POSITION",
			]);
			return event.projektId === context.projektId;
		},
		isRemoveEigenschaftPatch: ({ event }) => {
			assertEvent(event, "PATCH_MODELL");
			return event.patch.type === "deleteNode";
		},
		isAddNodePatch: ({ event }) => {
			assertEvent(event, "PATCH_MODELL");
			return event.patch.type === "addNode";
		},
	},
}).createMachine({
	id: "treeState:command",
	context: ({ input }) => ({ ...input }),
	invoke: { src: "translateEvents" },
	on: {
		FOCUS_NAME_INPUT: {
			actions: "focusNameInput",
		},
		PATCH_MODELL: [
			{
				guard: and(["isSelf", "isRemoveEigenschaftPatch"]),
				actions: "activateParentNode",
			},
			{
				guard: and(["isSelf", "isAddNodePatch"]),
				actions: ["sendActivateAfterInsertNode", "raiseFocusInput"],
			},
		],
		TOGGLE_TREE_NODE: {
			guard: "isSelf",
			actions: "toggleTreeNode",
		},
		UPDATE_SCROLL_POSITION: {
			guard: "isSelf",
			actions: "updateScrollPosition",
		},
	},
});

export default treeStateCommandMachine;
