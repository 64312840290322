import { Button } from "@mui/material";
import CreateIcon from "@mui/icons-material/CreateNewFolderOutlined";
import LoadIcon from "@mui/icons-material/UploadFileOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import InfoIcon from "@mui/icons-material/Info";
import { useActor } from "@xstate/react";
import OpenProjectModal from "./OpenProjectModal";
import SelectStandardModal from "./SelectStandardModal";
import WarnDialog from "./WarnModal";
import { useStateSelector } from "../../EditorState";
import {
	selectHasActiveProject,
	selectMetadata,
} from "../../EditorState/selectors";
import ProfilingHomeNavBar from "./ProfilingHomeNavBar";
import PartialAdocView from "../../PartialAdocView";
import { useEditorData } from "../../EditorData";
import profilingHomeMachine from "./ProfilingHome.machine";
import { RequestMethods, useResource } from "../../Api";
import { useIsProjectManuallySaved } from "../../EditorSavePoints/EditorSavePointContext";
import "./ProfilingHome.scss";

const ProfilingHome = (): JSX.Element => {
	const metadata = useStateSelector(selectMetadata());
	const hasActiveProject = useStateSelector(selectHasActiveProject());
	const { resetContainer } = useEditorData();
	const { request } = useResource<unknown, RequestMethods.Delete>();

	const isProfileSaved = useIsProjectManuallySaved();

	const [state, sendEvent] = useActor(profilingHomeMachine, {
		input: {
			shouldOpenWarning: () => hasActiveProject && !isProfileSaved,
			hasActiveProject: () => hasActiveProject,
		},
	});

	const handleNewProjectClick = () => {
		sendEvent({ type: "OPEN_NEW_PROJECT_DIALOG" });
	};

	const handleOpenProjectClick = () => {
		sendEvent({ type: "OPEN_LOAD_DIALOG" });
	};

	const handleDelete = () => {
		request(`/v1/projekt/${metadata.get("standard")}`, {
			method: RequestMethods.Delete,
		});
		resetContainer();
	};

	const handleDeleteOpen = () => {
		if (!isProfileSaved) {
			sendEvent({ type: "DELETE_PROJECT_DIALOG" });
		} else {
			handleDelete();
		}
	};

	const handleDeleteDismiss = () => {
		handleDelete();
		sendEvent({ type: "CLOSE_DIALOG" });
	};

	const handleClose = () => {
		sendEvent({ type: "CLOSE_DIALOG" });
	};

	const handleContinueWithoutSaving = () => {
		sendEvent({ type: "CONTINUE_WITHOUT_SAVING" });
	};

	return (
		<>
			<ProfilingHomeNavBar />
			<main id="main" className="profiling-home">
				<PartialAdocView doc="startseiten/profilierung" />
				<WarnDialog
					isOpen={state.matches("WarningOpen")}
					onDismiss={handleContinueWithoutSaving}
					onClose={handleClose}
				/>
				<WarnDialog
					isOpen={state.matches("DeleteDialogOpen")}
					onDismiss={handleDeleteDismiss}
					onClose={handleClose}
					buttonCaption="Entfernen ohne zu speichern"
					dialogContentText="Die aktuelle Profilierung wurde noch nicht gespeichert. Möchten Sie fortfahren ohne zu speichern?"
				/>
				<SelectStandardModal
					isOpen={state.matches("NewProjectDialogOpen")}
					onClose={handleClose}
				/>
				<OpenProjectModal
					isOpen={state.matches("LoadDialogOpen")}
					onClose={handleClose}
				/>
				<section className="profiling-home__section profiling-home__section--info">
					<h2 className="profiling-home__section-label">Aktuelles Projekt</h2>
					<div className="profiling-home__section-content">
						{hasActiveProject ? (
							<>
								<div className="profiling-home__info-wrapper">
									<h3 className="profiling-home__info-label">
										Name des Projekts
									</h3>
									<p className="profiling-home__info-field">
										{metadata.get("nameKurz")}
									</p>
								</div>
								<div className="profiling-home__info-wrapper">
									<h3 className="profiling-home__info-label">
										Kennung des Projekts
									</h3>
									<p className="profiling-home__info-field">
										{metadata.get("kennung")}
									</p>
								</div>
								<div className="profiling-home__info-wrapper">
									<h3 className="profiling-home__info-label">
										Kennung des Standards
									</h3>
									<p className="profiling-home__info-field">
										{metadata.get("standard")}
									</p>
								</div>
							</>
						) : (
							<p className="profiling-home__no-project">
								<InfoIcon />
								Es ist kein Profilierungsprojekt geöffnet
							</p>
						)}
					</div>
				</section>
				<section className="profiling-home__section profiling-home__section--project">
					<h2 className="profiling-home__section-label">Projekt öffnen</h2>
					<div className="profiling-home__section-content">
						<Button
							variant="outlined"
							data-testid="create-project"
							className="profiling-home__button"
							onClick={handleNewProjectClick}
						>
							<CreateIcon className="profiling-home__button-icon" />
							Neues Profil erstellen
						</Button>

						<Button
							variant="outlined"
							data-testid="open-project"
							className="profiling-home__button"
							onClick={handleOpenProjectClick}
						>
							<LoadIcon className="profiling-home__button-icon" />
							Profil laden
						</Button>
					</div>
					<div className="profiling-home__section-content-no-gap">
						<Button
							variant="outlined"
							data-testid="delete-project"
							className="profiling-home__button-no-gap"
							disabled={!hasActiveProject}
							onClick={handleDeleteOpen}
						>
							<DeleteOutlineOutlinedIcon className="profiling-home__button-icon" />
							Aktuelles Profil Entfernen
						</Button>
					</div>
				</section>
			</main>
		</>
	);
};

export default ProfilingHome;
