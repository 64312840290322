import type { FieldRendererDefinition } from "./FormFieldRenderer/types";

// eslint-disable-next-line import/prefer-default-export
export const fields: FieldRendererDefinition[] = [
	{
		name: "beschreibung",
		label: "Beschreibung der Einschränkung",
		type: "textarea",
	},
	{
		name: "umsetzungshinweis",
		label: "Umsetzungshinweis der Einschränkung",
		type: "textarea",
	},
	{
		name: "lowerBound",
		label: "Untergrenze",
		type: "input",
	},
	{
		name: "upperBound",
		label: "Obergrenze",
		type: "input",
	},
	{
		name: "istRekursionsStart",
		label: "Rekursion",
		type: "input",
	},
	{
		name: "defaultWert",
		label: "Default-Wert",
		type: "input",
	},
	{
		name: "fixedWert",
		label: "Fixed-Wert",
		type: "input",
	},
	{
		name: "beispielWert",
		label: "Beispiel-Wert",
		type: "input",
	},
	{
		name: "beispielWertNachricht",
		label: "Wert für Beispielnachrichten",
		type: "input",
	},
	{
		name: "nutzungsArt",
		label: "Nutzungsart",
	},
	{ name: "kennung", label: "Codelistenkennung" },
	{ name: "version", label: "Codelistenversion" },
	{ name: "codeliste", label: "Einschränkung der Codelistenwerte" },
	{ name: "eigenschaften", label: "" },
];
