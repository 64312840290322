import { Tooltip } from "@mui/material";
import NodeIcon from "../../NodeIcon";
import { VisuallyHidden } from "../../ui";
import "./ChoiceIcon.scss";

const ChoiceIcon = (): JSX.Element => {
	return (
		<Tooltip title="Das Element enthält Choices">
			<NodeIcon className="choice-icon" data-testid="choice-icon">
				<VisuallyHidden>
					Information: Das Element enthält Choices
				</VisuallyHidden>
				<svg
					className="choice-icon__usb"
					viewBox="0 0 158 227"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M158 76.2937C158 64.0539 148.343 54.125 136.439 54.125C124.534 54.125 114.877 64.0539 114.877 76.2937C114.877 85.4298 120.261 93.2762 127.945 96.6754V121.383C127.945 123.936 125.854 126.086 123.371 126.086H87.5004V42.5503C95.1842 39.1646 100.568 31.3182 100.568 22.1686C100.568 9.92886 90.9111 0 79.0065 0C67.102 0 57.445 9.92886 57.445 22.1686C57.445 31.3048 62.8289 39.1511 70.5126 42.5503V126.072H34.6291C32.1462 126.072 30.0554 123.923 30.0554 121.37V96.6619C37.7391 93.2762 43.123 85.4298 43.123 76.2802C43.123 64.0405 33.466 54.1116 21.5615 54.1116C9.65693 54.1116 0 64.0539 0 76.2937C0 85.4298 5.38384 93.2762 13.0676 96.6754V121.383C13.0676 133.61 22.7376 143.552 34.6291 143.552H70.4995V184.45C62.8158 187.835 57.432 195.682 57.432 204.831C57.432 217.071 67.0889 227 78.9935 227C90.898 227 100.555 217.071 100.555 204.831C100.555 195.695 95.1711 187.849 87.4874 184.45V143.552H123.358C135.249 143.552 144.919 133.61 144.919 121.383V96.6754C152.603 93.2896 157.987 85.4433 157.987 76.2937H158Z"
						fill="currentColor"
					/>
				</svg>
			</NodeIcon>
		</Tooltip>
	);
};

export default ChoiceIcon;
