import type { SelectChangeEvent } from "@mui/material";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useHtmlId } from "../../../../hooks";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import type { DatatypeFilter, UiFilterGroup } from "../../../../redux/uiSlice";
import { setFilter, selectFilterValue } from "../../../../redux/uiSlice";
import { NodeType } from "../../../Profiling/types";

const TypeFilter = ({
	filterGroup,
}: {
	filterGroup: UiFilterGroup.DatatypeList | UiFilterGroup.StandardDatatypeList;
}): JSX.Element => {
	const nodeTypeFilter = useAppSelector(
		selectFilterValue(filterGroup, "nodeType"),
	);
	const dispatch = useAppDispatch();
	const handleFilterChange = (event: SelectChangeEvent) => {
		dispatch(
			setFilter(filterGroup, {
				nodeType: event.target.value as DatatypeFilter,
			}),
		);
	};

	const labelId = useHtmlId();
	const label = "Anzeige einschränken";

	return (
		<FormControl fullWidth>
			<InputLabel id={labelId}>{label}</InputLabel>
			<Select
				value={nodeTypeFilter}
				onChange={handleFilterChange}
				data-testid="type-filter"
				style={{ flexShrink: 0 }}
				label={label}
				labelId={labelId}
			>
				<MenuItem value="NONE" data-testid="select-all">
					Alle anzeigen
				</MenuItem>
				<MenuItem value={NodeType.Complex} data-testid="select-complex">
					Komplexe Datentypen
				</MenuItem>
				<MenuItem value={NodeType.Simple} data-testid="select-simple">
					Simple Datentypen
				</MenuItem>
				<MenuItem value={NodeType.CodeList} data-testid="select-codelist">
					Codelisten Datentypen
				</MenuItem>
			</Select>
		</FormControl>
	);
};

export default TypeFilter;
