import { useMemo } from "react";
import { UiFilterGroup } from "../../../redux/uiSlice";
import useDataTypes from "../useDataTypes";
import useActiveDataType from "../useActiveDataType";
import { encodeXPath } from "../../../util/url";
import { useStateSelector } from "../../EditorState";
import { selectDatatypes, selectStandard } from "../../EditorState/selectors";
import EditorSideBar from "../../EditorSideBar";
import ListFilter from "./ListFilter";
import BaseDatatypesList from "../../StructureDisplay/BaseDatatypesList/BaseDatatypesList";
import type { PropsOf } from "../../../util/types";
import { useListFilters } from "../../StructureDisplay/BaseDatatypesList/hooks";
import {
	useNodeTypeFilter,
	useProfiledOnlyFilter,
	useRestrictionSearchFilter,
} from "../../StructureDisplay/BaseDatatypesList/filters";
import { useEditorData } from "../../EditorData";
import { useTreeStructure } from "../../TreeStructureProvider";
import { RequestStatus } from "../../Api";
import { subSelectIsProfiled } from "../../EditorState/subSelectors";
import { NotADatatypeError } from "../../Profiling/profilingHelpers";
import { useEventHandler } from "../../../hooks";
import { selectQNameFromModell } from "../../AppActor/actors/modellierungModel/selectors";
import { useAppSelector } from "../../../redux/hooks";
import { selectModellContainer } from "../../../redux/treeSlice";
import { AssertionError } from "../../../util/error";

const FilterGroup = UiFilterGroup.DatatypeList as const;

const DatatypesList = (): JSX.Element => {
	const standard = useStateSelector(selectStandard());
	const modell = useAppSelector(selectModellContainer(standard));
	const datatypesList = useDataTypes(standard);
	const { activeDataType } = useActiveDataType();
	const datatypes = useStateSelector(selectDatatypes());
	const { initStatus: projectStatus } = useEditorData();
	const { treeStatus } = useTreeStructure();
	const isLoading =
		projectStatus === RequestStatus.Loading ||
		treeStatus === RequestStatus.Loading;

	const searchFilter = useRestrictionSearchFilter(FilterGroup);
	const typeFilter = useNodeTypeFilter(FilterGroup);
	const profiledFilter = useProfiledOnlyFilter(FilterGroup);
	const filters = useMemo(
		() => [searchFilter, typeFilter, profiledFilter],
		[searchFilter, typeFilter, profiledFilter],
	);
	const filteredDatatypes = useListFilters(datatypesList, filters);

	const getUrl: PropsOf<typeof BaseDatatypesList>["getUrl"] = useEventHandler(
		(node) => {
			const qname = modell ? selectQNameFromModell(modell, node) : "";
			return `/profilierung/datentypen/${encodeXPath(qname)}`;
		},
	);

	return (
		<EditorSideBar data-testid="datatype-list">
			<EditorSideBar.Main>
				<BaseDatatypesList.Search filterGroup={FilterGroup} />
				<BaseDatatypesList
					data-testid="datatype-list__list"
					getNodeProps={(datatype) => {
						NotADatatypeError.assert(datatype);
						AssertionError.notNullish(modell);
						const qname = selectQNameFromModell(modell, datatype);
						const isProfiled = subSelectIsProfiled(datatypes, qname);
						return { isProfiled };
					}}
					activeDataType={activeDataType}
					datatypes={filteredDatatypes}
					getUrl={getUrl}
					isLoading={isLoading}
				/>
			</EditorSideBar.Main>
			<EditorSideBar.Controls>
				<ListFilter />
			</EditorSideBar.Controls>
		</EditorSideBar>
	);
};

export default DatatypesList;
