import classNames from "classnames";
import { useModellierungProjekt } from "../../AppActor/actors/modellierungModel/hooks";
import { LiteNodeType } from "../../AppActor/actors/modellierungModel/schemas";
import type { LiteNode } from "../../AppActor/actors/modellierungModel/schemas";
import {
	createSelectInfiniteChildren,
	createSelectNode,
} from "../../AppActor/actors/modellierungModel/selectors";
import ModelTree from "../ModelTree";
import {
	useActiveBasePath,
	useActiveBausteinExpansionState,
	useActiveFullBausteinPath,
} from "../../AppActor/actors/treeState/hooks";
import BausteinToolbar from "./BausteinToolbar";
import BausteinNodeMenuItems from "./BausteinToolbar/BausteinNodeMenuItems";
import EditorSideBar from "../../EditorSideBar";
import BausteinSearch from "./BausteinSearch";
import { useHtmlId } from "../../../hooks";
import BausteinSearchNav from "./BausteinSearch/BausteinSearchNav";
import { useIsProjektDisplayOnly } from "../../AppActor/actors/project/hooks";
import "./BausteinView.scss";
import { useTreeFilters } from "../../Tree/filterTreeNodes";

const NODE_TYPES = [LiteNodeType.Paket, LiteNodeType.Baustein];
const EMPTY_ROOT_NODES: LiteNode[] = [];

function isFilterMatch(node: LiteNode) {
	return NODE_TYPES.includes(node.liteType);
}

export default function BausteinView(): JSX.Element {
	const isDisplayOnly = useIsProjektDisplayOnly();
	const expansionState = useActiveBausteinExpansionState();
	const activePath = useActiveFullBausteinPath();
	const projekt = useModellierungProjekt();
	const projektId = projekt?.id;
	const basePath = useActiveBasePath();
	const searchNavId = useHtmlId("search-nav");
	const { activeFilters, toggleFilter } = useTreeFilters();

	if (!projekt || !projektId) {
		return (
			<>
				Es konnten keine Bausteindaten für <q>{projektId}</q> gefunden werden
			</>
		);
	}

	const selectNode = createSelectNode(projekt);
	const getChildren = createSelectInfiniteChildren(projekt);
	const rootModel = selectNode(projekt.modell.rootModelId);
	const rootNodes = rootModel ? getChildren(rootModel) : EMPTY_ROOT_NODES;

	return (
		<div
			className={classNames(
				"baustein-view",
				isDisplayOnly && "baustein-view--display-only",
			)}
			data-testid="baustein-tree"
			data-node-target="baustein"
		>
			<EditorSideBar>
				<BausteinSearch projektId={projekt.id} describedBy={searchNavId} />
				<EditorSideBar.Main className="baustein-view__main">
					<EditorSideBar.Scroller className="baustein-view__scroller">
						<BausteinSearchNav id={searchNavId} />
						<ModelTree
							NodeMenu={BausteinNodeMenuItems}
							activePath={activePath}
							isFilterMatch={isFilterMatch}
							project={projekt}
							rootNodes={rootNodes}
							rootPath={basePath}
							expansionState={expansionState}
							activeFilters={activeFilters}
						/>
					</EditorSideBar.Scroller>
				</EditorSideBar.Main>
				<EditorSideBar.Controls>
					<BausteinToolbar
						activeFilters={activeFilters}
						isDisplayOnly={isDisplayOnly}
						toggleFilter={toggleFilter}
					/>
				</EditorSideBar.Controls>
			</EditorSideBar>
		</div>
	);
}
