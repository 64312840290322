import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
} from "@mui/material";
import { useEditorData } from "../../../EditorData";
import ModalHeader from "../../../ui/Modal/ModalHeader";

const WarnDialog = ({
	isOpen,
	onDismiss,
	onClose,
	dialogContentText,
	buttonCaption,
}: {
	isOpen: boolean;
	onDismiss: () => void;
	onClose: () => void;
	dialogContentText?: string;
	buttonCaption?: string;
}): JSX.Element => {
	const { saveZip } = useEditorData();
	const closeDialogWithSave = () => {
		saveZip();
		onClose();
	};
	const text =
		dialogContentText ??
		"Die aktuelle Profilierung wurde noch nicht gespeichert. Möchten Sie fortfahren ohne zu speichern?";
	return (
		<Dialog
			data-testid="open-delete-project-modal"
			data-is-open={isOpen}
			open={isOpen}
		>
			<ModalHeader title="Projekt speichern?" handleClose={onClose} />
			<DialogContent>
				<DialogContentText>{text}</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button
					variant="outlined"
					data-testid="delete-project-button"
					onClick={onDismiss}
				>
					{buttonCaption ?? "Weiter ohne speichern"}
				</Button>
				<Button variant="contained" onClick={closeDialogWithSave} autoFocus>
					Speichern
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default WarnDialog;
