import { ValidationTargetField } from "./validators/types";

// eslint-disable-next-line import/prefer-default-export
export const targetFieldLabelsLite: { [K in ValidationTargetField]: string } = {
	[ValidationTargetField.Version]: "Version",
	[ValidationTargetField.VersionXoevHandbuch]: "Version XÖV Handbuch",
	[ValidationTargetField.VersionXGenerator]: "Version X Generator",
	[ValidationTargetField.VersionModellierungswerkzeug]:
		"Version Modellierungswerkzeug",
	[ValidationTargetField.Lizenz]: "Lizenz",
	[ValidationTargetField.AenderungZurVorversion]: "Änderung zur Vorversion",
	[ValidationTargetField.Bezugsort]: "Bezugsort",
	[ValidationTargetField.VersionBeschreibung]: "Beschreibung",
	[ValidationTargetField.NameLang]: "Name (lang)",
	[ValidationTargetField.NameKurz]: "Name (kurz)",
	[ValidationTargetField.NameTechnisch]: "Name (technisch)",
	[ValidationTargetField.Kennung]: "Kennung",
	[ValidationTargetField.Beschreibung]: "Beschreibung",
	[ValidationTargetField.HerausgebernameLang]: "Herausgebername (lang)",
	[ValidationTargetField.HerausgebernameKurz]: "Herausgebername (kurz)",
	[ValidationTargetField.ExterneWebsite]: "Externe Website",
	[ValidationTargetField.SchemaDateiname]: "Dateiname",
	[ValidationTargetField.SchemaUri]: "Namensraum",
	[ValidationTargetField.FassungVom]: "Fassung vom",
	[ValidationTargetField.Namespace]: "Namespace",
	[ValidationTargetField.Prefix]: "Präfix",
	[ValidationTargetField.SchemaPrefix]: "Präfix",
	[ValidationTargetField.SchemaLocationBase]: "Schema Location Base",
	[ValidationTargetField.StandardEigenschaften]: "Standard Eigenschaften",
	[ValidationTargetField.XSDNamedTypeNameSuffix]: "XSD Named Type Name Suffix",
	[ValidationTargetField.NodeName]: "Name",
	[ValidationTargetField.NodeBeschreibung]: "Beschreibung",
	[ValidationTargetField.NodeUmsetzungshinweis]: "Umsetzungshinweis",
};
