import { useCallback } from "react";
import type { AnyFn } from "../util/func";
import useSyncedRef from "./useSyncedRef";

/**
 * Creates an event handler function with a stable identity, that can be
 * invoked in effects without having to re-run them when dependencies of
 * the handeler change. See
 * [RFC for possible addition of `useEvent` into React](https://github.com/reactjs/rfcs/blob/useevent/text/0000-useevent.md)
 */
function useEventHandler<Fn extends AnyFn>(fn: Fn): Fn {
	const fnRef = useSyncedRef(fn);
	const stableFn = useCallback(
		(...args: Parameters<Fn>) => fnRef.current(...args) as ReturnType<Fn>,
		[fnRef],
	);
	return stableFn as Fn;
}

export default useEventHandler;
