import RecursionIcon from "../../Icons/RecursionIcon";
import CodeListIcon from "../../Icons/CodeListIcon";
import ChoiceIcon from "../../Icons/ChoiceIcon";
import Tree from "../../Tree";
import type { NodeElementProps } from "../../Tree";
import { getNodeByPath } from "../../Tree/treeHelpers";
import type { StateProfileMap } from "../../EditorState/types";
import ValidationLabel from "../../Validation/ValidationLabel";
import { useStateSelector } from "../../EditorState";
import { selectStandard } from "../../EditorState/selectors";
import Node from "../Node";
import type { NodeProps } from "../Node/Node";
import useIdsAffectedByProfile from "../useIdsAffectedByProfile";
import { useNodeSeverity } from "../ProfilingNodeValidationProvider";
import RestrictionIcon from "../../Icons/RestrictionIcon";
import type { ExtendProps } from "../../../util/types";
import {
	createSelectChildrenFromModell,
	selectNodeId,
	selectQNamePathFromModell,
} from "../../AppActor/actors/modellierungModel/selectors";
import type {
	LiteId,
	LiteNode,
} from "../../AppActor/actors/modellierungModel/schemas";
import type { LiteModellContainer } from "../../AppActor/actors/modellierungModel/types";
import { useAppSelector } from "../../../redux/hooks";
import {
	selectIdPathFromQNamePath,
	selectIsCodeliste,
	selectIsRekursionStart,
	selectModellContainer,
	selectQNamePath,
} from "../../../redux/treeSlice";
import {
	isChoice,
	isGroupTypeAll,
} from "../../AppActor/actors/modellierungModel/helpers";
import AllIcon from "../../Icons/AllIcon";

export type ProfilingNodeProps = Omit<
	ExtendProps<
		NodeProps,
		{
			root: LiteNode[];
			node: LiteNode;
			path: LiteId[];
			nodeProps: NodeElementProps;
			profiles: StateProfileMap;
			isActive: boolean;
			isExpanded: boolean;
			isMarked?: boolean;
			renderNodeName?: (node: LiteNode) => string | JSX.Element;
		}
	>,
	"ref"
>;

function getUsedRestriction(
	_node: LiteNode,
	path: LiteId[],
	modell: LiteModellContainer,
	profiles: StateProfileMap,
) {
	const qnamePath = selectQNamePathFromModell(modell, path);
	return profiles.getIn([qnamePath, "datentypReferenz", "name"]) || null;
}

function getZeroCardinalityNode(
	node: LiteNode,
	path: LiteId[],
	modell: LiteModellContainer,
	profiles: StateProfileMap,
) {
	const qnamePath = selectQNamePathFromModell(modell, path);
	const lowerBound = profiles.getIn([qnamePath, "lowerBound"]);
	const upperBound = profiles.getIn([qnamePath, "upperBound"]);
	return lowerBound === "0" && upperBound === "0" ? node.id : null;
}

function getClosestAncestor(
	modell: LiteModellContainer | null,
	root: LiteNode[],
	path: LiteId[],
	profiles: StateProfileMap,
	ancestorFunc: (
		node: LiteNode,
		path: LiteId[],
		modell: LiteModellContainer,
		profiles: StateProfileMap,
	) => string | null,
): string | null {
	if (!modell) return null;
	const selectChildren = createSelectChildrenFromModell(modell);
	const [...currentPath] = path;
	// Walk the tree from the node upwards, ancestor by ancestor until we find
	// on with an active restriction
	while (currentPath.length) {
		currentPath.pop();
		const ancestor = getNodeByPath(
			root,
			currentPath,
			selectChildren,
			selectNodeId,
		);
		if (ancestor) {
			const ancestorResult = ancestorFunc(
				ancestor,
				currentPath,
				modell,
				profiles,
			);
			if (ancestorResult !== null) return ancestorResult;
		}
	}
	return null;
}

function isNodeUsingRestriction(
	_node: LiteNode,
	path: LiteId[],
	modell: LiteModellContainer,
	profiles: StateProfileMap,
) {
	const qnamePath = selectQNamePathFromModell(modell, path);
	return !!profiles.getIn([qnamePath, "datentypReferenz"]);
}

function hasNodeZeroCardinality(
	node: LiteNode,
	path: LiteId[],
	modell: LiteModellContainer,
	profiles: StateProfileMap,
) {
	return getZeroCardinalityNode(node, path, modell, profiles) !== null;
}

function ProfilingNode({
	root,
	node,
	path,
	nodeProps,
	profiles,
	isActive,
	isExpanded,
	isMarked,
	renderNodeName,
	...props
}: ProfilingNodeProps): JSX.Element {
	const { id, name } = node;
	const displayName = renderNodeName ? renderNodeName(node) : name;
	const standard = useStateSelector(selectStandard());
	const isCodeliste = useAppSelector(selectIsCodeliste(standard, node));
	const istRekursionsStart = useAppSelector(
		selectIsRekursionStart(standard, node),
	);
	const qnamePath = useAppSelector(selectQNamePath(standard, path));
	const absolutePath = useAppSelector(
		selectIdPathFromQNamePath(standard, qnamePath),
	);
	const severity = useNodeSeverity(absolutePath);
	const modell = useAppSelector(selectModellContainer(standard));
	const affectedIds = useIdsAffectedByProfile(profiles);
	const isSelfOrChildProfiled = affectedIds.has(id);
	if (!modell) return <></>;
	// Mark the node with a lock if it itself is using a restriction
	const isUsingRestriction = isNodeUsingRestriction(
		node,
		path,
		modell,
		profiles,
	);
	// Mark the node with a lock if it itself is using a restriction
	const hasZeroCardinality = hasNodeZeroCardinality(
		node,
		path,
		modell,
		profiles,
	);
	// Mark the node as disabled if one of its ancestors is using a restriction
	const parentRestriction = getClosestAncestor(
		modell,
		root,
		path,
		profiles,
		getUsedRestriction,
	);

	const parentWithZeroCardinality = getClosestAncestor(
		modell,
		root,
		path,
		profiles,
		getZeroCardinalityNode,
	);

	const title = (
		<Node
			data-node-id={id}
			{...nodeProps}
			data-node-qname={qnamePath}
			data-testid="profiling-node"
			data-is-expanded={isExpanded}
			data-is-marked={isMarked}
			data-is-profiled={isSelfOrChildProfiled}
			isActive={isActive}
			isDisabled={!!parentRestriction || !!parentWithZeroCardinality}
			isProfiled={isSelfOrChildProfiled}
			{...props}
		>
			<Tree.NodeToggle />
			<ValidationLabel severity={severity}>
				{isMarked ? <mark>{displayName}</mark> : displayName}
			</ValidationLabel>
			<RecursionIcon isRecursive={istRekursionsStart} />
			{isChoice(node) && <ChoiceIcon />}
			{isGroupTypeAll(node) && <AllIcon />}

			<RestrictionIcon
				hasRestriction={isUsingRestriction}
				hasZeroCardinality={hasZeroCardinality}
			/>
			<CodeListIcon isCodeList={isCodeliste} />
		</Node>
	);
	return parentRestriction || parentWithZeroCardinality ? (
		<div data-testid="profiling-node-tooltip">{title}</div>
	) : (
		title
	);
}

export default ProfilingNode;
