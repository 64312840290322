import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import type { LinkProps } from "react-router-dom";
import { Link } from "react-router-dom";
import type { RefAttributes, ReactNode } from "react";
import { forwardRef } from "react";
import EditorMain from "../EditorMain";
import { useActiveProjectId } from "../AppActor/actors/navigation/hooks";
import useActiveMetadataSection, {
	MetadataSection,
} from "./useActiveMetadataSection";
import MetadataNavBarLite from "./MetadataNavBarLite";
import type { ExtendProps } from "../../util/types";
import MetadataStandardEditFormLite from "./MetadataEditFormLite/MetadataStandardEditFormLite";
import MetadataVersionEditFormLite from "./MetadataEditFormLite/MetadataVersionEditFormLite";
import { useProjektBaseSegment } from "../AppActor/actors/modellierungModel/hooks";
import "./MetadataViewLite.scss";

export type RouterLinkProps = LinkProps & RefAttributes<HTMLAnchorElement>;

export const TabLink = forwardRef(function TabLink(
	{
		className,
		children,
		href,
		...props
	}: ExtendProps<
		RouterLinkProps,
		{
			className: string;
			children: ReactNode;
			href: string;
		}
	>,
	ref,
) {
	return (
		// eslint-disable-next-line react/jsx-props-no-spreading
		<Link {...props} ref={ref as never} className={className} to={href}>
			{children}
		</Link>
	);
});

const MetadataViewLite = (): JSX.Element => {
	const activeTab = useActiveMetadataSection();
	const baseSegment = useProjektBaseSegment();
	const projektId = useActiveProjectId();
	if (!projektId) {
		return (
			<>
				Das Project mit der ID <q>{projektId}</q> konnte nicht gefunden werden
			</>
		);
	}
	return (
		<>
			<MetadataNavBarLite />
			<EditorMain className="metadata-view-lite" testId="metadata-main">
				<TabContext value={activeTab}>
					<Box component="div" className="metadata-view-lite__tab-wrapper">
						<TabList
							orientation="horizontal"
							className="metadata-view-lite__tab-list"
						>
							<Tab
								value={MetadataSection.Standard}
								label="Standard"
								LinkComponent={TabLink}
								href={`${baseSegment}/metadaten/standard`}
								data-testid="metadata-tab"
								data-tab={MetadataSection.Standard}
							/>
							<Tab
								value={MetadataSection.Version}
								label="Version"
								LinkComponent={TabLink}
								href={`${baseSegment}/metadaten/version`}
								data-testid="metadata-tab"
								data-tab={MetadataSection.Version}
							/>
						</TabList>
						<TabPanel
							value={MetadataSection.Standard}
							className="metadata-view-lite__tab-panel"
						>
							<MetadataStandardEditFormLite />
						</TabPanel>
						<TabPanel
							value={MetadataSection.Version}
							className="metadata-view-lite__tab-panel"
						>
							<MetadataVersionEditFormLite />
						</TabPanel>
					</Box>
				</TabContext>
			</EditorMain>
		</>
	);
};

export default MetadataViewLite;
