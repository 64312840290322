import type { ImmutableMap } from "@xoev/immutable-map";
import { z } from "zod";
import type {
	LiteBaustein,
	LiteEigenschaft,
	LiteId,
	LiteModel,
	LiteNodeType,
	LitePaket,
	LitePath,
	MetadatenStandard,
	MetadatenVersion,
	ModelKonfiguration,
	SchemaPaketKonfiguration,
} from "./schemas";
import type { LiteKind } from "./LiteKind";

export type ImmutableRecord<K extends PropertyKey, V> = ImmutableMap<{
	[Key in K]?: V;
}>;
export type LiteModelMap = ImmutableRecord<LiteId, LiteModel>;
export type LitePaketMap = ImmutableRecord<LiteId, LitePaket>;
export type LiteBausteinMap = ImmutableRecord<LiteId, LiteBaustein>;
export type LiteEigenschaftMap = ImmutableRecord<LiteId, LiteEigenschaft>;
export type LiteProjectNodes = ImmutableMap<{
	[LiteNodeType.Model]: LiteModelMap;
	[LiteNodeType.Paket]: LitePaketMap;
	[LiteNodeType.Baustein]: LiteBausteinMap;
	[LiteNodeType.Eigenschaft]: LiteEigenschaftMap;
}>;

export interface LiteModellContainer {
	rootModelId: LiteId;
	nodes: LiteProjectNodes;
}

export enum LiteDatatypeKind {
	Complex = "Complex",
	Simple = "Simple",
	CodeList = "CodeList",
}

export type NodeTarget = "baustein" | "structure";

export type NodeInsertPosition = "child" | "above" | "below";
export type NodeInsertInstruction = {
	kind: LiteKind;
	position: NodeInsertPosition;
	target: NodeTarget;
};

export type NodeDeleteInstruction = {
	target: NodeTarget;
};

export type LiteTreeNode = {
	id: LiteId;
	ref: LiteId | null;
	occurrences: LitePath[];
};

export const EditableFieldNameSchema = z.union([
	z.literal("name"),
	z.literal("beschreibung"),
	z.literal("umsetzungshinweis"),
	z.literal("multiplizitaet"),
	z.literal("gruppeArt"),
]);
export type EditableFieldName = z.infer<typeof EditableFieldNameSchema>;

type ConfigChange<K extends keyof SchemaPaketKonfiguration> = {
	name: K;
	value: SchemaPaketKonfiguration[K];
};

export type ModellierungPatch =
	| {
			type: "addNode";
			payload: {
				insertPath: LiteId[];
				newNodeId: LiteId;
				kind: LiteKind;
				position: NodeInsertPosition;
				target: NodeTarget;
			};
	  }
	| {
			type: "deleteNode";
			payload: { deletePath: LiteId[]; target: NodeTarget };
	  }
	| {
			type: "changeMetadataStandardValue";
			payload: { name: keyof MetadatenStandard; value: string };
	  }
	| {
			type: "changeMetadataVersionValue";
			payload: { name: keyof MetadatenVersion; value: string };
	  }
	| {
			type: "changeConfigurationValue";
			payload: { name: keyof ModelKonfiguration; value: string };
	  }
	| {
			type: "changeDetailsValue";
			payload: { nodeId: LiteId; name: EditableFieldName; value: string };
	  }
	| {
			type: "changeDetailsRef";
			payload: { nodeId: LiteId; ref: LiteId | null };
	  }
	| {
			type: "changePaketConfig";
			payload: { nodeId: LiteId } & ConfigChange<
				keyof SchemaPaketKonfiguration
			>;
	  };

export type ModellierungModelPatchType = ModellierungPatch["type"];
