import { memoize } from "@xoev/memo";
import type { LiteDatatypeEntry } from "../../../../../AppActor/actors/modellierungModel/selectors";
import { useModellierungDatatypes } from "../../../../../AppActor/actors/modellierungModel/hooks";
import type { LiteEigenschaft } from "../../../../../AppActor/actors/modellierungModel/schemas";

const createDatatypeCountMap = memoize((datatypes: LiteDatatypeEntry[]) => {
	const countMap = new Map<string, number>();
	for (const dt of datatypes) {
		countMap.set(dt.name, (countMap.get(dt.name) || 0) + 1);
	}
	return countMap;
});

export function getDuplicateLabel(
	datatypes: LiteDatatypeEntry[],
	option: LiteDatatypeEntry,
) {
	const countMap = createDatatypeCountMap(datatypes);
	const isDuplicate = (countMap.get(option.name) || 0) > 1;
	return isDuplicate ? ` (${option.parentPath})` : "";
}

export function getOptionLabel(
	datatypes: LiteDatatypeEntry[],
	option: LiteDatatypeEntry,
) {
	return `${option.name}${getDuplicateLabel(datatypes, option)}`;
}

export function useDatatypeRefEntry(node: LiteEigenschaft) {
	const datatypes = useModellierungDatatypes();
	return datatypes.find((dt) => dt.id === node.datentypReferenz) ?? null;
}
