import type { SimpleGenerator } from "../../../util/generator";
import type { QName } from "../../AppActor/actors/modellierungModel/schemas";
import type { DatatypeMap, RestrictionsMap } from "../../EditorState/types";

// eslint-disable-next-line import/prefer-default-export
export function* generateRestrictionEntries(
	datatypes: DatatypeMap,
): SimpleGenerator<[datatypeId: QName, restrictions: RestrictionsMap]> {
	for (const [datatypeQName, datatype] of datatypes) {
		const restrictions = datatype.get("restrictions");
		if (restrictions && !restrictions.isEmpty()) {
			yield [datatypeQName, restrictions];
		}
	}
}
