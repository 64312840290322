import { IconButton, TextField } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import { useGridApiContext } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import type { MessageType } from "../types";
import type { PropsOf } from "../../../../util/types";
import { useEventHandler } from "../../../../hooks";
import OutsideClick from "../../../ui/OutsideClick/OutsideClick";
import {
	CacheKeys,
	useUpdateMessageMutation,
} from "../../../../redux/messagesApiSlice";
import "./RenameCell.scss";

type TextFieldProps = PropsOf<typeof TextField>;

export default function RenameCell({
	message,
}: {
	message: MessageType;
}): JSX.Element {
	const apiRef = useGridApiContext();
	const [inputRef, setInputRef] = useState<HTMLInputElement | null>(null);
	const [updateMessage] = useUpdateMessageMutation({
		fixedCacheKey: CacheKeys.MessageUpdate,
	});
	const [value, setValue] = useState(message.name);

	const closeEdit = useEventHandler(() => {
		apiRef.current.setCellMode(message.id, "filename", "view");
	});

	// Focus the input after we open it, so we can immediately start typing
	useEffect(() => {
		if (inputRef) {
			try {
				inputRef.focus();
				inputRef.select();
			} catch {
				// When focusing or selecting does not work, we'll just ignore it...
			}
		}
	}, [inputRef]);

	const handleUpdateMessage = () => {
		if (value !== message.name) {
			const newMessage = { ...message, name: value };
			updateMessage({ message: newMessage });
		}
		closeEdit();
	};

	const handleKeyDown: TextFieldProps["onKeyDown"] = (e) => {
		if (e.key === "Enter") {
			e.preventDefault();
			e.stopPropagation();
			handleUpdateMessage();
		}
	};
	const handleChange: TextFieldProps["onChange"] = (e) => {
		setValue(e.target.value);
	};

	return (
		<OutsideClick onClickOutside={closeEdit}>
			<TextField
				value={value}
				inputRef={setInputRef}
				className="rename-cell__edit-field"
				data-testid="update-message-input"
				onKeyDown={handleKeyDown}
				onChange={handleChange}
			/>
			<IconButton
				data-testid="update-message-button"
				onClick={handleUpdateMessage}
			>
				<SaveIcon />
			</IconButton>
		</OutsideClick>
	);
}
