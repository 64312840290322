import { useMemo } from "react";
import { createPath, useLocation } from "react-router-dom";
import { createPatchCreators } from "@xoev/state-container";
import { useSyncedRef } from "../../hooks";
import { objectEntries } from "../../util/object";
import { stateReducerMap } from "./editorStateReducer";
import type {
	EditorMeta,
	EditorPatchCreatorMap,
	EditorPatchCreators,
} from "./types";

const editorPatchCreators: EditorPatchCreatorMap =
	createPatchCreators(stateReducerMap);

/**
 * Use patch creators, that automatically fill in the correct url in the meta
 * object of the patch
 * @returns The patch creators
 */
export function useCreateEditorPatchCreators(): EditorPatchCreators {
	const location = useLocation();
	const locationRef = useSyncedRef(location);
	return useMemo(() => {
		const entries = objectEntries(editorPatchCreators).map(
			([key, patchCreator]) => {
				const createMeta = (): EditorMeta => ({
					url: createPath(locationRef.current),
				});
				return [
					key,
					(payload: Parameters<typeof patchCreator>[0]) =>
						patchCreator(payload as never, createMeta()),
				];
			},
		);
		return Object.fromEntries(entries) as EditorPatchCreators;
	}, [locationRef]);
}

export default editorPatchCreators;
