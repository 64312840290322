import { useSendStoreEvent } from "../../../../../AppActor/EventStore/hooks";
import { DEFAULT_GROUPTYPE } from "../../../../../AppActor/actors/modellierungModel/helpers";
import {
	LiteGruppeType,
	type LiteEigenschaft,
} from "../../../../../AppActor/actors/modellierungModel/schemas";
import EditFormFieldLite from "../../../../../EditFormFieldLite";
import type { EditRendererProps } from "../../types";
import tooltips from "../../../../../../textConstants/tooltips.json";

const getToolTipText = (type: "choice" | "sequence" | "all"): string => {
	return tooltips?.groupTypeSelect[type].tooltip ?? "Grouppenart Tooltip";
};
const groupTypeOptions = [
	{
		value: LiteGruppeType.Sequence,
		label: LiteGruppeType.Sequence,
		tooltip: getToolTipText("sequence"),
	},
	{
		value: LiteGruppeType.Choice,
		label: LiteGruppeType.Choice,
		tooltip: getToolTipText("choice"),
	},
	{
		value: LiteGruppeType.All,
		label: LiteGruppeType.All,
		tooltip: getToolTipText("all"),
	},
];
export default function GruppierungsartEdit({
	activeNode,
	isReadOnly,
	projektId,
}: EditRendererProps<LiteEigenschaft>): JSX.Element {
	const sendStoreEvent = useSendStoreEvent();

	const handleSelectChange = (e: {
		target: { name?: string; value: string };
	}) => {
		const nodeId = activeNode.id;
		sendStoreEvent({
			type: "MODELLIERUNG.MODELL.APPLY",
			payload: {
				projektId,
				patch: {
					type: "changeDetailsValue",
					payload: { name: "gruppeArt", value: e.target.value, nodeId },
				},
			},
		});
	};

	if (!activeNode.children.length) {
		return <></>;
	}
	return (
		<EditFormFieldLite
			definition={{
				readOnly: isReadOnly,
				name: "gruppeArt",
				label: "Gruppierungsart",
				type: "select",
				options: groupTypeOptions,
			}}
			value={activeNode.gruppeArt || DEFAULT_GROUPTYPE}
			onChange={handleSelectChange}
			inputProps={{
				"data-testid": "details-field",
				"data-field-name": "gruppe-art",
			}}
		/>
	);
}
