import { useCallback } from "react";
import { useAppSelector } from "../../../../redux/hooks";
import type {
	DatatypeFilter,
	ExtractFilterGroup,
} from "../../../../redux/uiSlice";
import { selectFilterValue } from "../../../../redux/uiSlice";
import { useStateSelector } from "../../../EditorState";
import {
	selectDatatypes,
	selectStandard,
} from "../../../EditorState/selectors";
import { subSelectIsProfiled } from "../../../EditorState/subSelectors";
import type { DatatypeMap } from "../../../EditorState/types";
import type { DatatypeFilterPredicate } from "../types";
import { getLiteDatatypeKind } from "../../../AppActor/actors/modellierungModel/helpers";
import { NotADatatypeError } from "../../../Profiling/profilingHelpers";
import { selectModellContainer } from "../../../../redux/treeSlice";
import { AssertionError } from "../../../../util/error";
import { selectQNameFromModell } from "../../../AppActor/actors/modellierungModel/selectors";
import type { LiteModellContainer } from "../../../AppActor/actors/modellierungModel/types";

export function createSearchMatchFilter(
	searchTerm: string,
): DatatypeFilterPredicate {
	return (datatype) =>
		datatype.name.toLowerCase().includes(searchTerm.toLowerCase());
}

function createRestrictionSearchMatchFilter(
	modell: LiteModellContainer,
	searchTerm: string,
	datatypes: DatatypeMap,
): DatatypeFilterPredicate {
	const defaultFilter = createSearchMatchFilter(searchTerm);
	return (datatype) => {
		NotADatatypeError.assert(datatype);
		return !!(
			defaultFilter(datatype) ||
			datatypes
				.get(selectQNameFromModell(modell, datatype))
				?.get("restrictions")
				?.valueSeq()
				.some((restriction) =>
					restriction
						.get("name")
						.toLowerCase()
						.includes(searchTerm.toLowerCase()),
				)
		);
	};
}

export function useListSearchFilter<
	FilterGroup extends ExtractFilterGroup<{ query: string }>,
>(filterGroup: FilterGroup): DatatypeFilterPredicate {
	const searchTerm = useAppSelector(selectFilterValue(filterGroup, "query"));
	return useCallback(
		(datatype) => createSearchMatchFilter(searchTerm)(datatype),
		[searchTerm],
	);
}

export function useRestrictionSearchFilter<
	FilterGroup extends ExtractFilterGroup<{ query: string }>,
>(filterGroup: FilterGroup): DatatypeFilterPredicate {
	const searchTerm = useAppSelector(selectFilterValue(filterGroup, "query"));
	const datatypes = useStateSelector(selectDatatypes());
	const standard = useStateSelector(selectStandard());
	const modell = useAppSelector(selectModellContainer(standard));
	return useCallback(
		(datatype) =>
			createRestrictionSearchMatchFilter(
				AssertionError.asNotNullish(modell),
				searchTerm,
				datatypes,
			)(datatype),
		[datatypes, modell, searchTerm],
	);
}

export function useProfiledOnlyFilter<
	FilterGroup extends ExtractFilterGroup<{ showProfiledOnly: boolean }>,
>(filterGroup: FilterGroup): DatatypeFilterPredicate {
	const showProfiledOnly = useAppSelector(
		selectFilterValue(filterGroup, "showProfiledOnly"),
	);
	const datatypes = useStateSelector(selectDatatypes());
	const standard = useStateSelector(selectStandard());
	const modell = useAppSelector(selectModellContainer(standard));
	// If the profile filter is active, we need to remove any non profiled
	// datatypes as well
	return useCallback(
		(datatype) => {
			NotADatatypeError.assert(datatype);
			AssertionError.notNullish(modell);
			return (
				!showProfiledOnly ||
				subSelectIsProfiled(datatypes, selectQNameFromModell(modell, datatype))
			);
		},
		[datatypes, modell, showProfiledOnly],
	);
}

export function useNodeTypeFilter<
	FilterGroup extends ExtractFilterGroup<{ nodeType: DatatypeFilter }>,
>(filterGroup: FilterGroup): DatatypeFilterPredicate {
	const nodeTypeFilter = useAppSelector(
		selectFilterValue(filterGroup, "nodeType"),
	);
	return useCallback(
		(datatype) =>
			nodeTypeFilter === "NONE" ||
			getLiteDatatypeKind(datatype) === nodeTypeFilter,
		[nodeTypeFilter],
	);
}
