import { useParams } from "react-router-dom";
import createImmutableMap from "@xoev/immutable-map";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import {
	selectFilterValue,
	setFilter,
	UiFilterGroup,
} from "../../../../../redux/uiSlice";
import type { TreeNodeType } from "../../../../Profiling/types";
import { useIsDescendantProfiled } from "../../../../Profiling/profilingHelpers";
import useActiveRestriction from "../../../useActiveRestriction";
import { encodeXPath } from "../../../../../util/url";
import useActiveDataType from "../../../useActiveDataType";
import useActiveRestrictionProfile from "../../../useActiveRestrictionProfile";
import EditorSideBar from "../../../../EditorSideBar";
import ProfilingTree from "../../../../Profiling/ProfilingTree";
import FilterMenu from "../../../../Profiling/Filter/FilterMenu";
import ProfiledOnlyFilter from "../../../../Profiling/Filter/ProfiledOnlyFilter";
import { useStateSelector } from "../../../../EditorState";
import { selectStandard } from "../../../../EditorState/selectors";
import type {
	LiteId,
	LiteNode,
} from "../../../../AppActor/actors/modellierungModel/schemas";
import { QNameSchema } from "../../../../AppActor/actors/modellierungModel/schemas";
import type { NodeEventArg } from "../../../../Tree";
import {
	selectBausteinIdByQName,
	selectModellContainer,
} from "../../../../../redux/treeSlice";
import { selectQNamePathFromModell } from "../../../../AppActor/actors/modellierungModel/selectors";
import { useEventHandler } from "../../../../../hooks";
import "./RestrictionList.scss";

const FilterGroup = UiFilterGroup.RestrictionTree as const;

function RestrictionList(): JSX.Element {
	const { nodeId: parentQNameParam } = useParams();
	const standard = useStateSelector(selectStandard());
	const modell = useAppSelector(selectModellContainer(standard));
	const parentNodeId = useAppSelector(
		selectBausteinIdByQName(standard, QNameSchema.parse(parentQNameParam)),
	);
	if (!parentNodeId) {
		throw new Error(
			"Expected to find a parent node id in the url, but none was found " +
				"while rendering <RestrictionList />",
		);
	}

	const dispatch = useAppDispatch();

	// We check for the existance of activeDataType and activeRestriction
	// in the parent component
	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	const activeRestriction = useActiveRestriction()!;
	const showProfiledOnly = useAppSelector(
		selectFilterValue(FilterGroup, "showProfiledOnly"),
	);
	const { activeDataType: optionalActiveDataType } = useActiveDataType();
	// We've checked in rest`RestrictionView`, that these exist
	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	const activeDataType = optionalActiveDataType!;
	const profiles = activeRestriction?.get("profile") || createImmutableMap();

	const activeNodeState = useActiveRestrictionProfile(activeDataType.id);

	const getUrl = useEventHandler((event: NodeEventArg<LiteNode, LiteId>) => {
		// We *know* that the parent id must be set, as the route would not have
		// been rendered otherwise
		const parent = encodeXPath(parentQNameParam || "");
		const restriction = activeRestriction.get("id");
		const qnamePath = modell
			? selectQNamePathFromModell(modell, event.path)
			: "";
		const self = encodeXPath(qnamePath);
		return `/profilierung/datentypen/${parent}/profil/${restriction}/${self}`;
	});

	const isDescendantProfiled = useIsDescendantProfiled(profiles);

	const handleFilterModeChange = () => {
		dispatch(setFilter(FilterGroup, { showProfiledOnly: !showProfiledOnly }));
	};

	const renderNodeName = (node: TreeNodeType) =>
		parentNodeId === node.id ? (
			<>
				{activeRestriction.get("name")}{" "}
				<span className="restriction-list__parent-datatype">({node.name})</span>
			</>
		) : (
			node.name
		);

	return (
		<EditorSideBar>
			<EditorSideBar.Main>
				<ProfilingTree
					activeNodeState={activeNodeState}
					getUrl={getUrl}
					profiles={profiles}
					rootId={parentNodeId}
					isFilterMatch={(node) =>
						!showProfiledOnly || isDescendantProfiled(node.id)
					}
					filterGroup={FilterGroup}
					renderNodeName={renderNodeName}
					standard={standard}
				/>
			</EditorSideBar.Main>
			<EditorSideBar.Controls>
				<FilterMenu filterGroup={FilterGroup}>
					<ProfiledOnlyFilter
						checked={showProfiledOnly}
						onChange={handleFilterModeChange}
					/>
				</FilterMenu>
			</EditorSideBar.Controls>
		</EditorSideBar>
	);
}

export default RestrictionList;
