import { TextField, IconButton } from "@mui/material";
import type { DropResult } from "react-beautiful-dnd";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import AddIcon from "@mui/icons-material/Add";
import type { ChangeEvent, KeyboardEvent } from "react";
import { useState } from "react";
import {
	canBeRenamed,
	filterTitlesBySearchterm,
	generateListItemText,
} from "../helpers";
import type { MainListProps } from "../types";
import { useApplyPatch, useEditorPatchCreators } from "../../../EditorState";
import useActiveDocTitle from "../../useActiveDocTitle";
import ListNode from "../../../Profiling/ListNode";
import KeyboardShortcut from "../../../KeyboardShortcut";
import "./DraggableChaptersList.scss";

const DraggableChaptersList = ({
	docs,
	searchTerm,
	isAddFieldVisible,
	setIsAddFieldVisible,
	onActivate,
	onRename,
}: MainListProps): JSX.Element => {
	const applyPatch = useApplyPatch();
	const { reorderDocuments, addVisibleDocument } = useEditorPatchCreators();

	const [activeDocTitleKey] = useActiveDocTitle();
	const docTitles = filterTitlesBySearchterm(docs, searchTerm);

	const [newChapter, setNewChapter] = useState("");
	const [renamingChapter, setRenamingChapter] = useState<string | null>(null);
	const [renamingValue, setRenamingValue] = useState("");

	const handleDragEnd = (result: DropResult) => {
		if (!result.destination) return;
		applyPatch(
			reorderDocuments({
				fromIndex: result.source.index,
				toIndex: result.destination.index,
			}),
		);
	};

	const handleNewChapterTitleChange = (
		e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
	) => {
		setNewChapter(e.target.value);
	};

	const addChapter = () => {
		if (newChapter.length) {
			applyPatch(addVisibleDocument({ docTitle: newChapter }));
			setNewChapter("");
			setIsAddFieldVisible(false);
		}
	};

	const handleAddKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === "Enter") {
			addChapter();
		}

		if (event.key === "Escape") {
			setNewChapter("");
			setIsAddFieldVisible(false);
		}
	};

	const handleRenameStart = (docTitle: string) => {
		if (canBeRenamed(docs, docTitle)) {
			setRenamingChapter(docTitle);
			setRenamingValue(docTitle);
		}
	};
	const handleRenameCancel = () => {
		setRenamingValue("");
		setRenamingChapter(null);
	};
	const handleRenameConfirm = () => {
		if (
			!renamingChapter ||
			!renamingValue ||
			(renamingValue && docs.has(renamingValue))
		) {
			handleRenameCancel();
			return;
		}
		onRename(renamingChapter, renamingValue);
		handleRenameCancel();
	};

	const createHandleActivate = (docTitle: string) => () => {
		if (renamingChapter && docTitle !== renamingChapter) {
			handleRenameConfirm();
		}
		onActivate(docTitle);
	};

	const createHandleRenameStart = (docTitle: string) => () => {
		handleRenameStart(docTitle);
	};

	const handleRenameKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
		e.stopPropagation();
		if (e.key === "Enter") {
			handleRenameConfirm();
		}
		if (e.key === "Escape") {
			handleRenameCancel();
		}
	};

	const handleRenameChange = (e: ChangeEvent<HTMLInputElement>) => {
		setRenamingValue(e.target.value);
	};

	const createHandleItemKeyDown =
		(docTitle: string) => (e: KeyboardEvent<HTMLInputElement>) => {
			if (e.key === " " || e.key === "Enter") {
				onActivate(docTitle);
			}
		};

	return (
		<>
			<DragDropContext onDragEnd={handleDragEnd}>
				<Droppable droppableId="draggable-chapters-list" direction="vertical">
					{(providedDroppable) => (
						<ul
							ref={providedDroppable.innerRef}
							{...providedDroppable.droppableProps}
							data-testid="draggable-chapters-list__list"
							className="draggable-chapters-list"
						>
							{docTitles.map((docTitle) => (
								<Draggable
									key={docTitle}
									draggableId={docTitle as string}
									index={docs.get(docTitle)?.get("index") as number}
								>
									{(providedDraggable) => (
										<ListNode
											as="li"
											ref={providedDraggable.innerRef}
											{...providedDraggable.draggableProps}
											{...providedDraggable.dragHandleProps}
											data-testid="draggable-chapters-list__item"
											data-node-id={docTitle}
											key={docTitle}
											onClick={createHandleActivate(docTitle)}
											onDoubleClick={createHandleRenameStart(docTitle)}
											tabIndex={0}
											role="menuitem"
											onKeyDown={createHandleItemKeyDown(docTitle)}
											isActive={activeDocTitleKey === docTitle}
										>
											{!canBeRenamed(docs, docTitle) ||
											renamingChapter !== docTitle ? (
												generateListItemText(docs, docTitle)
											) : (
												<TextField
													fullWidth
													variant="outlined"
													size="small"
													onBlur={handleRenameConfirm}
													onKeyDown={handleRenameKeyDown}
													placeholder={docTitle}
													value={renamingValue}
													onChange={handleRenameChange}
													className="draggable-chapters-list__edit-item"
													autoFocus
												/>
											)}
										</ListNode>
									)}
								</Draggable>
							))}
							{providedDroppable.placeholder}
							{isAddFieldVisible && (
								<ListNode as="li">
									<TextField
										fullWidth
										variant="outlined"
										size="small"
										className="draggable-chapters-list__edit-item"
										data-testid="draggable-chapters-list__add-item"
										value={newChapter}
										onChange={handleNewChapterTitleChange}
										onKeyDown={handleAddKeyDown}
										autoFocus
									/>
									<IconButton
										data-testid="draggable-chapters-list__add-item-button"
										onClick={addChapter}
										disabled={!newChapter.length}
									>
										<AddIcon />
									</IconButton>
								</ListNode>
							)}
						</ul>
					)}
				</Droppable>
			</DragDropContext>
			<KeyboardShortcut
				shortcut={{ key: "F2" }}
				onActivate={createHandleRenameStart(activeDocTitleKey)}
				disabled={!activeDocTitleKey}
			/>
		</>
	);
};

export default DraggableChaptersList;
