import type { SvgIconComponent } from "@mui/icons-material";
import PaketIcon from "@mui/icons-material/Inventory2Outlined";
import SchemaPaketIcon from "@mui/icons-material/SchemaOutlined";
import ModelIcon from "@mui/icons-material/DeviceHub";
import BausteinIcon from "@mui/icons-material/WidgetsOutlined";
import CodelisteIcon from "@mui/icons-material/BorderTop";
import NachrichtIcon from "@mui/icons-material/MailOutline";
import DatatypeIcon from "@mui/icons-material/DataObject";
import GlobaleEigenschaftIcon from "@mui/icons-material/Language";
import EigenschaftIcon from "@mui/icons-material/Code";
import AttributeIcon from "@mui/icons-material/AlternateEmail";
import { LiteKind } from "./LiteKind";

export const kindIconMap: { [K in LiteKind]: SvgIconComponent } = {
	[LiteKind.Model]: ModelIcon,
	[LiteKind.Paket]: PaketIcon,
	[LiteKind.SchemaPaket]: SchemaPaketIcon,
	[LiteKind.CodeDatentyp]: CodelisteIcon,
	[LiteKind.Nachricht]: NachrichtIcon,
	[LiteKind.Datentyp]: DatatypeIcon,
	[LiteKind.GlobaleEigenschaft]: GlobaleEigenschaftIcon,
	[LiteKind.UnknownBaustein]: BausteinIcon,
	[LiteKind.Attribut]: AttributeIcon,
	[LiteKind.Eigenschaft]: EigenschaftIcon,
};

export {
	ModelIcon,
	PaketIcon,
	SchemaPaketIcon,
	CodelisteIcon,
	NachrichtIcon,
	DatatypeIcon,
	GlobaleEigenschaftIcon,
	BausteinIcon,
	AttributeIcon,
	EigenschaftIcon,
};
