import { useState } from "react";
import { Button, Typography, Checkbox } from "@mui/material";
import { DataGrid, GridRow, deDE } from "@mui/x-data-grid";
import type {
	GridColDef,
	GridRenderCellParams,
	GridRowProps,
} from "@mui/x-data-grid";
import type { Equals, NotIsAny } from "@xoev/type-test";
import { assertType } from "@xoev/type-test";
import type { MessageType } from "./types";
import { TableContainer } from "../../ui";
import MessageOverviewFooter from "./MessageOverviewFooter";
import { useGetStandardsQuery } from "../../../redux/apiSlice";
import ReportButton from "./ReportButton";
import { RequestStatus } from "../../Api";
import { useStateSelector } from "../../EditorState";
import { selectHasActiveProject } from "../../EditorState/selectors";
import RenameCell from "./RenameCell";
import {
	CacheKeys,
	useGetMessagesQuery,
	useRefreshViewerMutation,
	useUpdateMessageMutation,
} from "../../../redux/messagesApiSlice";
import { toRequestStatus } from "../../../util/requestFormatters";
import DynamicInfo from "../../ui/DynamicInfo";
import "./MessageOverview.scss";

type CellParams = GridRenderCellParams<string, MessageType>;

// The MuiDataGrid changes its api frequently, so we make sure not to introduce
// typein issues here...
assertType<NotIsAny<CellParams["row"]>>();
assertType<Equals<CellParams["row"], MessageType>>();
assertType<Equals<CellParams["value"], string | undefined>>();

function CustomRow(props: GridRowProps) {
	return (
		<GridRow
			// eslint-disable-next-line react/destructuring-assignment
			data-message-name={props.row?.name}
			data-testid="message-table-row"
			{...props}
		/>
	);
}

function renderHeader() {
	return (
		<>
			<strong>Teil der Profilierung</strong>
			<DynamicInfo infoKey="messageOverviewHeader" inline="end" />
		</>
	);
}

function renderEdit(params: CellParams) {
	const message = params.row;
	return <RenameCell message={message} />;
}

function MessageOverview({
	onMsgClick,
}: {
	onMsgClick: (msg: MessageType) => void;
}): JSX.Element {
	const [pageSize, setPageSize] = useState(25);

	const { data: messages, ...getFlags } = useGetMessagesQuery();
	const getMessagesStatus = toRequestStatus(getFlags);

	const [
		,
		{
			isError: isRefreshError,
			isLoading: isRefreshLoading,
			isSuccess: isRefreshSuccess,
		},
	] = useRefreshViewerMutation({
		fixedCacheKey: CacheKeys.Refresh,
	});
	const refreshStatus = toRequestStatus({
		isError: isRefreshError,
		isLoading: isRefreshLoading,
		isSuccess: isRefreshSuccess,
	});
	const { data: standards } = useGetStandardsQuery();
	const [
		updateMessage,
		{
			isError: isUpdateError,
			isLoading: isUpdateLoading,
			isSuccess: isUpdateSuccess,
		},
	] = useUpdateMessageMutation({
		fixedCacheKey: CacheKeys.MessageUpdate,
	});
	const updateStatus = toRequestStatus({
		isError: isUpdateError,
		isLoading: isUpdateLoading,
		isSuccess: isUpdateSuccess,
	});

	const [enabelEdit, setEnableEdit] = useState<string>("");
	const hasActiveProject = useStateSelector(selectHasActiveProject());

	const renderButton = (params: CellParams) => {
		const { name } = params.row;
		if (params.row.standard) {
			return (
				<Button
					variant="text"
					onClick={() => onMsgClick(params.row)}
					data-testid="open-message-button"
					sx={{
						padding: "0",
						"&:hover": {
							backgroundColor: "transparent",
						},
					}}
				>
					{name}
				</Button>
			);
		}
		return <Typography variant="body1">{name}</Typography>;
	};

	const renderReportButtons = (params: CellParams) => {
		const message = params.row;
		return (
			<ReportButton
				standard={params.row.standard}
				message={message}
				enableEdit={enabelEdit}
				setEnableEdit={setEnableEdit}
			/>
		);
	};

	const renderStandard = (params: CellParams) => {
		const { standard: kennung } = params.row;
		const standardData = standards?.find(
			(standard) => standard.kennung === kennung,
		);
		if (standardData) {
			return (
				<Typography variant="body1">
					{standardData.nameKurz} {standardData.version}
				</Typography>
			);
		}
		return (
			<Typography variant="body1" color="error">
				<em>
					<strong>Keine gültige Nachricht</strong>
				</em>
			</Typography>
		);
	};

	const createHandleCheckboxClick = (messageId: string) => () => {
		const message = messages?.find(({ id }) => id === messageId);
		if (message) {
			const newMessage = {
				...message,
				istBeispielnachricht: !message.istBeispielnachricht,
			};
			updateMessage({ message: newMessage });
		}
	};
	const renderIsProfiled = (params: CellParams) => {
		const messageId = params.row.id as string;
		const checked = messages?.find(
			({ id }) => id === messageId,
		)?.istBeispielnachricht;
		const handleChange = createHandleCheckboxClick(messageId);

		return (
			<Checkbox
				checked={checked}
				onChange={handleChange}
				disabled={updateStatus === RequestStatus.Loading}
			/>
		);
	};
	const columns: GridColDef[] = [
		{
			field: "filename",
			headerName: "Dateiname",
			flex: 3,
			sortable: false,
			filterable: false,
			editable: true,
			renderCell: renderButton,
			renderEditCell: renderEdit,
			cellClassName: "message-overview__table-cell--filename",
		},
		{
			field: "standard",
			headerName: "Standard",
			flex: 2,
			sortable: false,
			filterable: false,
			renderCell: renderStandard,
		},
		{
			field: "validationReport",
			headerName: "Validierungsbericht",
			flex: 1,
			sortable: false,
			filterable: false,
			renderCell: renderReportButtons,
		},
	];

	if (hasActiveProject) {
		const profiledColumn: GridColDef = {
			field: "profiled",
			headerName: "Teil der Profilierung",
			renderHeader,
			flex: 1,
			minWidth: 200,
			sortable: false,
			filterable: false,
			renderCell: renderIsProfiled,
			align: "center",
			headerAlign: "center",
		};
		columns.splice(2, 0, profiledColumn);
	}

	const handlePageSizeChange = (nextPageSize: number) =>
		setPageSize(nextPageSize);

	const isLoading =
		refreshStatus === RequestStatus.Loading ||
		getMessagesStatus === RequestStatus.Loading;

	return (
		<TableContainer
			className="message-overview__table"
			headerSurface="emphasized"
		>
			<DataGrid
				loading={isLoading}
				className="message-overview__data-grid"
				rows={messages || []}
				columns={columns}
				rowsPerPageOptions={[5, 10, 25, 50, 100]}
				pagination
				pageSize={pageSize}
				onPageSizeChange={handlePageSizeChange}
				components={{ Row: CustomRow, Footer: MessageOverviewFooter }}
				localeText={{
					noRowsLabel: "Keine Nachrichten verfügbar",
					...deDE.components.MuiDataGrid.defaultProps.localeText,
				}}
				disableSelectionOnClick
				disableColumnMenu
				disableColumnFilter
				disableColumnSelector
			/>
		</TableContainer>
	);
}

export default MessageOverview;
