import { SerializableError } from "../../util/error";
import { createLabelledCounter } from "../../util/misc";
import { createDeferred } from "../../util/promise";
import type {
	AnyActionMap,
	DeferredResponse,
	HandlerMap,
	RequestAction,
	RequestPayload,
	ResponseAction,
	ResponsePayloadData,
} from "./types";

// use, while reducing the work a single thread has to do
const DEFAULT_POOL_SIZE = 1;

const createRequestId = createLabelledCounter("request");

class WorkerBridge<ActionMap extends AnyActionMap> {
	private isTerminated = false;

	private isInitialized = false;

	private poolSize: number;

	private createWorker: () => Worker;

	private workers: Worker[];

	private cursor = 0;

	private currentRequests = new Map<
		string,
		DeferredResponse<ActionMap, keyof ActionMap>
	>();

	constructor(createWorker: () => Worker, poolSize = DEFAULT_POOL_SIZE) {
		this.workers = [];
		this.poolSize = poolSize;
		this.createWorker = createWorker;
	}

	private assertIsNotTerminated() {
		if (this.isTerminated) {
			throw new Error(`Cannot interact with terminated worker`);
		}
	}

	private initialize() {
		this.assertIsNotTerminated();
		// Spin up multiple workers, so we can balance the calculation load between
		// them
		for (let i = 0; i < this.poolSize; i += 1) {
			const worker = this.createWorker();
			worker.addEventListener("message", this.handleMessage.bind(this));
			this.workers.push(worker);
		}
		this.isInitialized = true;
	}

	private getWorker() {
		this.assertIsNotTerminated();
		if (!this.isInitialized) {
			this.initialize();
		}
		// Rotate through the workers, so we distribute the work between them evenly
		const worker = this.workers[this.cursor];
		this.cursor = (this.cursor + this.workers.length + 1) % this.workers.length;
		return worker;
	}

	private handleMessage(
		event: MessageEvent<ResponseAction<ActionMap, keyof ActionMap>>,
	) {
		const { payload, requestId, type } = event.data;
		// The request has finished, so we can resolve the deferred request object.
		// Get the stored request by its id...
		const request = this.currentRequests.get(requestId);
		if (!request) {
			throw new Error(
				`No request for type "${String(
					type,
				)}" with id "${requestId}" could be found.`,
			);
		}
		// Depending on the outcome of the request resolve the promise or throw the
		// returned error
		if (payload.status === "success") {
			request.resolve(payload.data);
		} else {
			request.reject(payload.error);
		}
	}

	enqueue<Type extends keyof ActionMap>(
		type: Type,
		payload: RequestPayload<ActionMap, Type>,
	): Promise<ResponsePayloadData<ActionMap, Type>> {
		this.assertIsNotTerminated();
		// Queue a unit of work and reference it by a unique id
		const requestId = createRequestId();
		const action: RequestAction<ActionMap, Type> = { type, payload, requestId };
		// Create a deferred result so we can now return a promise and resolve it
		// later when the work is done. We can do this, because we'll find the
		// response by the unique id we created before
		const deferredResult = createDeferred<
			ResponsePayloadData<ActionMap, Type>,
			string
		>();
		// Store the result so we can resolve it later
		this.currentRequests.set(requestId, deferredResult);
		// Start the work
		this.getWorker().postMessage(action);
		return deferredResult.promise;
	}

	terminate() {
		this.workers.forEach((worker) => worker.terminate());
		this.isTerminated = true;
	}

	static connectWorker<ActionMap extends AnyActionMap>(
		handlers: HandlerMap<ActionMap>,
	) {
		const isWorker = "DedicatedWorkerGlobalScope" in globalThis;
		if (!isWorker) {
			throw new Error(
				"WorkerBridge.connectWorker can only be called in a worker, but it " +
					"was called on the main thread.",
			);
		}

		const messageHandler = async (
			msg: MessageEvent<RequestAction<ActionMap, keyof ActionMap>>,
		) => {
			const { type, payload, requestId } = msg.data;

			const handler = handlers[type];
			if (!handler && typeof handler !== "function") {
				const supportedTypes = Object.keys(handlers)
					.map((t) => `"${t}"`)
					.join(", ");
				throw new Error(
					`Unknown action found. No handler is registered for actions of type ` +
						`"${String(type)}". Supported types are ${supportedTypes}.`,
				);
			}

			try {
				const result = await handler(payload);
				const response = { status: "success", data: result, error: null };
				// eslint-disable-next-line no-restricted-globals
				self.postMessage({ type, requestId, payload: response });
			} catch (error) {
				const response = {
					status: "failure",
					data: null,
					error: SerializableError.from(error).serialize(),
				};
				// eslint-disable-next-line no-restricted-globals
				self.postMessage({ type, requestId, payload: response });
			}
		};

		// eslint-disable-next-line no-restricted-globals
		self.onmessage = messageHandler;
	}
}

export default WorkerBridge;
