import type { Context as ReactContext, ReactNode } from "react";
import {
	useCallback,
	useContext,
	useLayoutEffect,
	useMemo,
	useState,
} from "react";
import { createHtmlId } from "../../../../util/misc";
import { createNumberSorter, Direction } from "../../../../util/lists";
import { useConst } from "../../../../hooks";
import type {
	DescendantInfo,
	DescendantItem,
	DescendantList,
	DescendantsContextType,
	RegisterDescendant,
} from "./types";

export function useDescendant<
	Data = undefined,
	ElementType extends HTMLElement = HTMLElement,
>(
	Context: ReactContext<DescendantsContextType<Data, ElementType>>,
	options?: {
		id?: string;
		data?: Data;
		index?: number;
	},
): DescendantInfo<ElementType> {
	const { id: elemId = null, data, index } = options || {};
	const { registerChild, unregisterChild } = useContext(Context);
	const [ref, setRef] = useState<ElementType | null>(null);
	const htmlId = useConst(() => createHtmlId());
	const id = elemId || htmlId;

	// Use LayoutEffect, so that the order in which `registerChild` is
	// called matches renderering order.
	useLayoutEffect(() => {
		registerChild({ id, ref, data, index });
		return () => unregisterChild(id);
	}, [data, id, index, ref, registerChild, unregisterChild]);

	return { id, ref, setRef };
}

const indexSorter = createNumberSorter<"index">("index", Direction.Asc);

export function useDescendantList<
	Data = undefined,
	ElementType extends HTMLElement = HTMLElement,
>(
	Context: ReactContext<DescendantsContextType<Data, ElementType>>,
): DescendantList<Data, ElementType> {
	const [items, setItems] = useState<DescendantItem<Data, ElementType>[]>([]);

	const registerChild: RegisterDescendant<Data, ElementType> = useCallback(
		({ id, ref, data, index }) => {
			setItems((prevItems) => {
				const nextItems = [...prevItems, { id, ref, data, index }];
				if (index !== undefined) {
					// If an index is provided manually, use it to keep the order
					// of the items consistent. Note that sort mutates the source array
					(nextItems as { index: number }[]).sort(indexSorter);
				}
				return nextItems;
			});
		},
		[],
	);

	const unregisterChild = useCallback((id) => {
		setItems((prevItems) => prevItems.filter((item) => item.id !== id));
	}, []);

	const Provider = useMemo(() => {
		const ctx = { registerChild, unregisterChild };
		const DescendantListProvider = ({ children }: { children: ReactNode }) => (
			<Context.Provider value={ctx}>{children}</Context.Provider>
		);
		return DescendantListProvider;
	}, [Context, registerChild, unregisterChild]);

	return { items, Provider };
}

export function getNextDescendantIndex<
	Data = undefined,
	ElementType extends HTMLElement = HTMLElement,
>(
	items: DescendantItem<Data, ElementType>[],
	activeId: string | null,
	dir: Direction,
): number {
	const itemIndex = items.findIndex((item) => item.id === activeId);
	const activeIndex = dir === -1 && itemIndex === -1 ? 0 : itemIndex;
	return (activeIndex + items.length + dir * 1) % items.length;
}
