import { useParams } from "react-router-dom";
import createImmutableMap from "@xoev/immutable-map";
import { UiFilterGroup } from "../../../../../redux/uiSlice";
import { encodeXPath } from "../../../../../util/url";
import EditorSideBar from "../../../../EditorSideBar";
import { useStateSelector } from "../../../../EditorState";
import { selectStandard } from "../../../../EditorState/selectors";
import type { StateProfileMap } from "../../../../EditorState/types";
import { useActiveStandardNode } from "../../../../MessageProfilingView/useActiveNode";
import ProfilingTree from "../../../../Profiling/ProfilingTree";
import type {
	LiteId,
	LiteNode,
} from "../../../../AppActor/actors/modellierungModel/schemas";
import { QNameSchema } from "../../../../AppActor/actors/modellierungModel/schemas";
import type { NodeEventArg } from "../../../../Tree";
import { selectQNamePathFromModell } from "../../../../AppActor/actors/modellierungModel/selectors";
import { useAppSelector } from "../../../../../redux/hooks";
import {
	selectBausteinIdByQName,
	selectModellContainer,
} from "../../../../../redux/treeSlice";
import { useEventHandler } from "../../../../../hooks";

const EMPTY_PROFILE_MAP: StateProfileMap = createImmutableMap();

const StandardTypeList = (): JSX.Element => {
	const { nodeId: parentQNameParam } = useParams();
	const standard = useStateSelector(selectStandard());
	const modell = useAppSelector(selectModellContainer(standard));
	const parentNodeId = useAppSelector(
		selectBausteinIdByQName(standard, QNameSchema.parse(parentQNameParam)),
	);
	if (!parentNodeId) {
		throw new Error(
			"Expected to find a parent node id in the url, but none was found " +
				"while rendering <StandardTypeList />",
		);
	}

	const activeNodeState = useActiveStandardNode(parentNodeId);

	const getUrl = useEventHandler((event: NodeEventArg<LiteNode, LiteId>) => {
		// We *know* that the parent id must be set, as the route would not have
		// been rendered otherwise
		const parent = encodeXPath(parentQNameParam || "");
		const qnamePath = modell
			? selectQNamePathFromModell(modell, event.path)
			: "";
		const self = encodeXPath(qnamePath);
		return `/profilierung/datentypen/${parent}/standard/${self}`;
	});

	return (
		<EditorSideBar>
			<ProfilingTree
				activeNodeState={activeNodeState}
				getUrl={getUrl}
				profiles={EMPTY_PROFILE_MAP}
				rootId={parentNodeId}
				nodeProps={{ "data-testid": "standardtype-list-node" }}
				filterGroup={UiFilterGroup.StandardTree}
				standard={standard}
			/>
		</EditorSideBar>
	);
};

export default StandardTypeList;
