/* eslint-disable import/prefer-default-export */
import { matchPath } from "react-router-dom";
import { encodeXPath } from "../../../../util/url";
import { LiteIdSchema, ProjektType } from "../modellierungModel/schemas";
import type { ProjektId, LiteId, LiteNode } from "../modellierungModel/schemas";
import { segmentizeId } from "../../../../util/xoev";
import type { StandardProjekt } from "../project/types";
import {
	createSelectInfiniteChildrenFromModell,
	createSelectNodesByPathFromModell,
	selectRootModellFromModell,
} from "../modellierungModel/selectors";
import type { Nullish } from "../../../../util/types";
import { SerializableError } from "../../../../util/error";
import type { EventStoreLog } from "../../EventStore/eventStore.machine";
import { getNodeHierachy } from "../../../Tree/treeHelpers";

export enum LiteBasePath {
	Modellierung = "modellierung",
	Standard = "standard",
}

export function createUrlSegments(
	projekt: StandardProjekt,
	fullPath: LiteId[],
) {
	return {
		[ProjektType.Modellierung]: () => fullPath.slice(1),
		[ProjektType.Standard]: () => {
			const nodes = createSelectNodesByPathFromModell(projekt.modell)(fullPath);
			return nodes.slice(1).map((node) => node.name);
		},
	}[projekt.type]();
}

export function encodeUrlSegments(segments: string[]) {
	return segments.map((segment) => encodeXPath(segment)).join("/");
}

export function createNodeTargetHref(
	projekt: StandardProjekt,
	fullPath: LiteId[],
) {
	const path = createUrlSegments(projekt, fullPath);
	const encodedPath = encodeUrlSegments(path);
	const tab = {
		[ProjektType.Modellierung]: projekt.id,
		[ProjektType.Standard]: projekt.kennung,
	}[projekt.type];
	const encodedTab = encodeXPath(tab);
	const base = {
		[ProjektType.Modellierung]: LiteBasePath.Modellierung,
		[ProjektType.Standard]: LiteBasePath.Standard,
	}[projekt.type];
	const encodedBase = encodeXPath(base);
	return `/${encodedBase}/${encodedTab}/modell/${encodedPath}`;
}

export function createProjektTypeSegment(type: StandardProjekt["type"]) {
	return {
		[ProjektType.Modellierung]: LiteBasePath.Modellierung,
		[ProjektType.Standard]: LiteBasePath.Standard,
	}[type];
}

export function createProjektIdSegment(
	projekt: Pick<StandardProjekt, "id" | "kennung" | "type">,
) {
	return {
		[ProjektType.Modellierung]: encodeXPath(projekt.id),
		[ProjektType.Standard]: encodeXPath(projekt.kennung),
	}[projekt.type];
}

export function createProjektBaseSegment(
	projekt: Pick<StandardProjekt, "id" | "kennung" | "type">,
) {
	const typeSegment = createProjektTypeSegment(projekt.type);
	const projektIdSegment = createProjektIdSegment(projekt);
	return `/${typeSegment}/${projektIdSegment}`;
}

export function createTreeUrl(projekt: StandardProjekt, idPath: LiteId[]) {
	const baseSegment = createProjektBaseSegment(projekt);
	const segments = createUrlSegments(projekt, idPath);
	const path = encodeUrlSegments(segments);
	return `${baseSegment}/modell/${path}`;
}

export function parseProjektLocation(
	projekt: Nullish<StandardProjekt>,
	location: string,
) {
	if (!projekt) return null;
	const base = createProjektTypeSegment(projekt.type);
	const match = matchPath(`/${base}/:projektId/modell/*`, location);
	const path = segmentizeId(match?.params?.["*"] || "");
	const idPath = {
		[ProjektType.Modellierung]: () =>
			path.map((segment) => LiteIdSchema.parse(segment)),
		[ProjektType.Standard]: () => {
			const namePath = path;
			const root = selectRootModellFromModell(projekt.modell);
			const hierachy = getNodeHierachy(
				[root as LiteNode],
				[root.name, ...namePath],
				createSelectInfiniteChildrenFromModell(projekt.modell),
				(node) => node.name,
			);
			return hierachy ? hierachy.map((node) => node.id).slice(1) : [];
		},
	}[projekt.type]();
	return (idPath?.length && idPath) || null;
}

export function parseIdSegmentFromLocation(location: string) {
	const modellierungMatch = matchPath(
		`/${LiteBasePath.Modellierung}/:projektId/*`,
		location,
	);
	const standardMatch = matchPath(
		`/${LiteBasePath.Standard}/:projektId/*`,
		location,
	);
	const match = modellierungMatch || standardMatch;
	return match?.params?.projektId ?? null;
}

class NavigationNotImplementedError extends SerializableError {
	constructor(projektType: ProjektType) {
		super({
			name: "NavigationNotImplementedError",
			message: `Navigation is not implemented for type: "${projektType}" yet.`,
		});
	}

	static assert(
		projektType: ProjektType,
	): asserts projektType is ProjektType.Modellierung | ProjektType.Standard {
		if (
			projektType !== ProjektType.Modellierung &&
			projektType !== ProjektType.Standard
		) {
			throw new NavigationNotImplementedError(projektType);
		}
	}
}

export function createDefaultTabHref({
	kennung,
	projektId,
	projektType,
}: {
	projektId: ProjektId;
	projektType: ProjektType;
	kennung: string;
}) {
	NavigationNotImplementedError.assert(projektType);
	const partialProjekt = { kennung, id: projektId, type: projektType };
	return `${createProjektBaseSegment(partialProjekt)}/metadaten/standard`;
}

export function createTabHref({
	kennung,
	projektId,
	projektType,
	eventLog,
}: {
	projektId: ProjektId;
	projektType: ProjektType;
	kennung: string;
	eventLog: EventStoreLog;
}) {
	NavigationNotImplementedError.assert(projektType);
	const segment = {
		[ProjektType.Modellierung]: projektId,
		[ProjektType.Standard]: kennung,
	}[projektType];
	const lastNavigation = eventLog.findLast((entry) => {
		if (entry.event.type !== "NAVIGATE") return false;
		const idSegment = parseIdSegmentFromLocation(entry.event.payload.location);
		return idSegment === segment;
	});
	return lastNavigation && lastNavigation.event.type === "NAVIGATE"
		? lastNavigation.event.payload.location
		: null;
}
